import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import formatNumberEuro from '../../../util/FormatNumberEuro';
import { useExplore } from '../useExplore';
import dayjs from 'dayjs';

// DataItem for withdrawals
interface DataItem {
  _id: string;
  title: string;
  description: string;
  amount: number;
  timestamp: string;
  category: string;
  subcategory: string;
  receiptUrl: string;
  blockchainUrl: string;
}

// New types for investments and donations
interface InvestmentTransaction {
  _id: string;
  fiat: number;
  createdAt: string;
}

interface DonationTransaction {
  _id: string;
  amount: number;
  createdAt: string;
}

interface BalanceSheetProps {
  withdrawals: DataItem[];
  setCurrentView: Dispatch<SetStateAction<string>>;
  setIndex: Dispatch<SetStateAction<number>>;
  projectId:String
}

const BalanceSheet = ({ withdrawals, setCurrentView, setIndex , projectId }: BalanceSheetProps) => {
  const [investments, setInvestments] = useState<InvestmentTransaction[]>([]);
  const [donations, setDonations] = useState<DonationTransaction[]>([]);
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});
  const [categoryColors, setCategoryColors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {fetchBalanceSheetDetails} = useExplore()


   const formatDate = (timestamp: string): string => {
      const date = new Date(parseInt(timestamp) * 1000);
      return dayjs(date).format("YYYY-MM-DD HH:mm");
    };

  // Dummy data for investments and donations
  const dummyInvestments: InvestmentTransaction[] = [
    {
      _id: 'inv-1',
      fiat: 5000,
      createdAt: '2024-01-15T10:30:00Z'
    },
    {
      _id: 'inv-2',
      fiat: 10000,
      createdAt: '2024-02-20T14:45:00Z'
    },
    {
      _id: 'inv-3',
      fiat: 2500,
      createdAt: '2024-03-10T09:15:00Z'
    }
  ];

  const dummyDonations: DonationTransaction[] = [
    {
      _id: 'don-1',
      amount: 1000,
      createdAt: '2024-01-25T16:20:00Z'
    },
    {
      _id: 'don-2',
      amount: 500,
      createdAt: '2024-02-28T11:10:00Z'
    }
  ];

  // Fetch investments and donations from API
  useEffect(()=>{
    async function fetchBalanceSheet(id:String){
    const data = await fetchBalanceSheetDetails(id)

    if(data){
    console.log( data.investments)
    setInvestments(data.investments.response)
    console.log( data.donations)
    setDonations(data.donations.response)
    }
    }

   if(projectId)
    fetchBalanceSheet(projectId)


  } ,[projectId])

  // Extract all unique categories from withdrawals and initialize expanded state
  useEffect(() => {
    const uniqueCategories = Array.from(new Set(withdrawals.map(item => item.category || 'Other')));
    
    // Add "Investments" and "Donations" as special categories
    const allCategories = [...uniqueCategories, 'Investments', 'Donations'];
    
    const initialExpandedState = allCategories.reduce((acc, category) => {
      acc[category] = false; // Default all categories to collapsed
      return acc;
    }, {} as Record<string, boolean>);

    // Generate colors for each category
    const colors = allCategories.reduce((acc, category) => {
      const hue = Math.floor(Math.random() * 360);
      acc[category] = `hsl(${hue}, 70%, 35%)`;
      return acc;
    }, {} as Record<string, string>);
    
    setExpandedSections(initialExpandedState);
    setCategoryColors(colors);
  }, [withdrawals]);

  const toggleSection = (category: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  // Group withdrawals by category
  const groupedItems = React.useMemo(() => {
    // Group withdrawals by category
    const withdrawalCategories = withdrawals.reduce((acc, item) => {
      const category = item.category || 'Other';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {} as Record<string, DataItem[]>);
    
    return { 
      withdrawalCategories: Object.keys(withdrawalCategories),
      withdrawals: withdrawalCategories
    };
  }, [withdrawals]);

  // Function to handle click on a transaction row
  const handleClick = (timestamp: string) => {
    // Find the index of the item in the withdrawals array
    const index = withdrawals.findIndex(item => item.timestamp === timestamp);
    console.log("time" ,timestamp)
    if (index !== -1) {
      setCurrentView('tableview'); 
      setIndex(index); 
    }
  };

  // Component for collapsible section header
  const SectionHeader = ({ 
    title, 
    expanded, 
    onToggle,
    totalAmount
  }: { 
    title: string, 
    expanded: boolean, 
    onToggle: () => void,
    totalAmount: number
  }) => (
    <div
      className="flex items-center py-3 px-2 cursor-pointer border-b border-gray-200 sticky top-0 bg-white z-10"
      onClick={onToggle}
    >
      <div className="flex items-center w-full">
        {expanded ? 
          <ChevronDown className="mr-2 h-5 w-5" style={{ color: categoryColors[title] }} /> : 
          <ChevronRight className="mr-2 h-5 w-5" style={{ color: categoryColors[title] }} />
        }
        <span className="font-medium" style={{ color: categoryColors[title] }}>{title}</span>
      </div>
      <span className="font-medium ml-auto whitespace-nowrap opacity-75" style={{ color: categoryColors[title] }}>
        {formatNumberEuro(totalAmount)}
      </span>
    </div>
  );

  // Component for withdrawal item row
  const WithdrawalRow = ({ 
    item, 
    onClick
  }: { 
    item: DataItem, 
    onClick?: () => void
  }) => {
    // Format timestamp to date only
    const formattedDate = formatDate(item.timestamp)
    
    return (
      <div 
        className="grid grid-cols-12 py-3 border-b border-gray-100 hover:bg-gray-50 cursor-pointer"
        onClick={onClick}
      >
        <div className="col-span-3 px-2">{formattedDate}</div>
        <div className="col-span-6 px-2">{item.description || item.title}</div>
        <div className="col-span-3 px-2 text-right">{formatNumberEuro(item.amount)}</div>
      </div>
    );
  };

  // Component for investment item row
  const InvestmentRow = ({ 
    item 
  }: { 
    item: InvestmentTransaction
  }) => {
    // Format timestamp to date only
    const formattedDate = new Date(item.createdAt).toLocaleDateString();
    
    return (
      <div className="grid grid-cols-12 py-3 border-b border-gray-100">
        <div className="col-span-3 px-2">{formattedDate}</div>
        <div className="col-span-6 px-2">Investment</div>
        <div className="col-span-3 px-2 text-right">{formatNumberEuro(item.fiat)}</div>
      </div>
    );
  };

  // Component for donation item row
  const DonationRow = ({ 
    item 
  }: { 
    item: DonationTransaction
  }) => {
    // Format timestamp to date only
    const formattedDate = new Date(item.createdAt).toLocaleDateString();
    
    return (
      <div className="grid grid-cols-12 py-3 border-b border-gray-100">
        <div className="col-span-3 px-2">{formattedDate}</div>
        <div className="col-span-6 px-2">Donation</div>
        <div className="col-span-3 px-2 text-right">{formatNumberEuro(item.amount)}</div>
      </div>
    );
  };

  // Calculate total investments
  const totalInvestments = investments.reduce((sum, item) => sum + item.fiat, 0);
  
  // Calculate total donations
  const totalDonations = donations.reduce((sum, item) => sum + item.amount, 0);

  // Calculate total withdrawals
  const totalWithdrawals = withdrawals.reduce((sum, item) => sum + item.amount, 0);

  return (
    <div className="w-full max-w-6xl mx-auto bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 mt-5">
      <div className="flex border-b border-gray-200">
        <div className="w-6/12 px-6 py-4 font-semibold text-lg border-r border-gray-200">Invested</div>
        <div className="w-6/12 px-6 py-4 font-semibold text-lg">Withdrawals</div>
      </div>
      
      <div className="flex h-96">
        {/* Left side - Investments and Donations */}
        <div className="w-6/12 border-r border-gray-200 overflow-y-auto relative">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <p>Loading investments...</p>
            </div>
          ) : (
            <>
              {/* Investments section */}
              <div className="relative">
                <SectionHeader 
                  title="Investments" 
                  expanded={expandedSections['Investments'] || false} 
                  onToggle={() => toggleSection('Investments')}
                  totalAmount={totalInvestments}
                />
                
                {expandedSections['Investments'] && investments.map((item) => (
                  <InvestmentRow key={item._id} item={item} />
                ))}
              </div>
              
              {/* Donations section */}
              <div className="relative">
                <SectionHeader 
                  title="Donations" 
                  expanded={expandedSections['Donations'] || false} 
                  onToggle={() => toggleSection('Donations')}
                  totalAmount={totalDonations}
                />
                
                {expandedSections['Donations'] && donations.map((item) => (
                  <DonationRow key={item._id} item={item} />
                ))}
              </div>
            </>
          )}
        </div>
        
        {/* Right side - Withdrawals */}
        <div className="w-6/12 overflow-y-auto relative">
          {groupedItems.withdrawalCategories.map(category => (
            <div key={`withdrawn-${category}`} className="relative">
              <SectionHeader 
                title={category} 
                expanded={expandedSections[category] || false} 
                onToggle={() => toggleSection(category)}
                totalAmount={groupedItems.withdrawals[category]?.reduce((sum, item) => sum + item.amount, 0) || 0}
              />
              
              {expandedSections[category] && groupedItems.withdrawals[category]?.map((item) => (
                <WithdrawalRow 
                  key={item._id} 
                  item={item} 
                  onClick={() => handleClick(item.timestamp)}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Summary Footer */}
      <div className="border-t border-gray-200 bg-gray-50 px-6 py-3 flex justify-between">
        <div className="px-5 py-1 text-sm bg-teal-50 rounded-full">
          Total invested: <span className="font-semibold text-teal-700">
            {formatNumberEuro(totalInvestments + totalDonations)}
          </span>
        </div>
        <div className="px-5 py-1 text-sm bg-teal-50 rounded-full">
          Total withdrawn: <span className="font-semibold text-teal-700">
            {formatNumberEuro(totalWithdrawals)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheet;