import React, { useState, useEffect } from "react";
import axios from "axios";
// import { apiPaths } from "../../service/apiPaths";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { apiPaths } from "../../../service/apiPaths";
import RightIcon from "../../../images/Settings/right.png";
import { Info, PlusIcon } from "lucide-react";
import ApiService from "../../../service/ApiService";

interface FileUploadResponse {
  files: any[];
  message: string;
}

interface FormData {
  projectId: string;
  title: string;
  category: string;
  subCategory: string;
  description: string;
  receipt: string;
  amount: number;
  url: string;
  date: string;
}

interface CategoryResponse {
  _id:string;
  name:string;
  description:string;
  subCategories:[{
    name:string;
    description:string;
  }];
  isDefault:boolean;
}

interface ProjectResponse {
  _id: string;
  name: string;
}

const Withdrawals: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    projectId: "",
    title: "",
    category:"",
    subCategory:"",
    description: "",
    receipt: "",
    amount: 0,
    url: "",
    date: "",
  });


  type RequiredField = {
    key: keyof FormData;
    message: string;
  };

  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUploadLoader, setImageUploadLoader] = useState(false);
  const user = useSelector((store: any) => store.user.user);

  const [categories, setCategories] = useState<CategoryResponse[]>([]);

  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showCreateSubCategoryModal, setShowCreateSubCategoryModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryDescription, setSubCategoryDescription] = useState("");
  const selectedProject = projects.filter((project) => project._id === formData.projectId)[0];
  let selectedCategory = categories.filter((category) => category.name === formData.category)[0];
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  useEffect(() => {
    async function fetchProjects() {
      console.log("USER ID IN Project overview : ", user);
      if (user) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/${apiPaths.getProjectsName}?purpose=withdrawal`,
          {
            withCredentials: true,
          },
        );

        const res = response.data.response;

        if (res && Array.isArray(res)) { 
          setProjects(res);
        } else {
          // If no projects are fetched, fall back to dummy data
          // setAllProjects(dummyData);
          console.error(
            "Error fetching project details or no projects available.",
          );
        }
      }
      setIsLoading(false);
    }
  
    fetchProjects();
  }, []);

  useEffect(() => {
    async function fetchCategories() {
      if (formData.projectId) {
        try{
          setIsLoadingCategories(true);
          const response = await ApiService({
            method: "GET",
            endpoint: apiPaths.getWithdrawalCategories + `/${formData.projectId}`,
          });

          console.log(response.response)

          if (response?.response) {
            setCategories(response.response);
          } else {
            console.error("Error fetching categories");
            toast.error("Error fetching categories. Please try again.");
          }
        }catch(err){
          console.log("Error fetching categories:", err);
          toast.error("Error fetching categories. Please try again.");
      }finally{
        setIsLoadingCategories(false);
      }
    }
  }

    fetchCategories();
  },[formData.projectId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    // console.log(n)
    setFormData((prev) => ({
      ...prev,
      [name]: name === "amount" ? Number(value) : value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    }
  };

  const uploadFile = async () => {
    if (!file) return;

    setImageUploadLoader(true);
    let formDataInstance = new FormData();
    formDataInstance.append("files", file);

    try {
      const response = await axios.post<FileUploadResponse>(
        `${process.env.REACT_APP_API_BASE_URL}/${apiPaths.imageUpload}`,
        formDataInstance,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return response.data.files[0];
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
      console.error("Error uploading file:", error);
      throw error;
    } finally {
      setImageUploadLoader(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const receipt = await uploadFile();

      const updatedFormData = { 
        ...formData, 
        receipt 
      } as FormData;
  
      const requiredFields: RequiredField[] = [
        { key: 'projectId', message: 'Project ID is required' },
        { key: 'title', message: 'Title is required' },
        { key: 'category', message: 'Category is required' },
        { key: 'subCategory', message: 'Subcategory is required' },
        { key: 'description', message: 'Description is required' },
        { key: 'amount', message: 'Amount is required' },
        { key: 'date', message: 'Date is required' }
      ];
  
      // Find the first missing required field
      const missingField = requiredFields.find(
        field => {
          const value = updatedFormData[field.key];
          return !value || 
            (typeof value === 'string' && value.trim() === '') ||
            (typeof value === 'number' && value === 0);
        }
      );
  
      // If a required field is missing, show error and return
      if (missingField) {
        toast.error(missingField.message);
        setIsLoading(false);
        return;
      }

const filteredFormData = Object.entries(updatedFormData).reduce<Record<string, any>>((acc, [key, value]) => {
  if (value !== "" && value !== null && value !== undefined) {
    acc[key] = value;
  }
  return acc; 
}, {});

console.log(filteredFormData)


      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.submitWithdrawal,
        filteredFormData,
        {
          withCredentials: true,
        },
      );

      if (response.data.success) {
        toast.success("Withdrawal request submitted successfully");
        setFormData({
          projectId: "",
          title: "",
          category:"",
          subCategory:"",
          description: "",
          receipt: "",
          amount: 0,
          url: "",
          date: "",
        })
      }
    } catch (error) {
      // console.log("Error submitting form:", error);
      if ((error as any)?.response?.data?.response) {
        toast.error((error as any)?.response.data.response);
      } else {
        toast.error("Error submitting form. Please try again.");
      }
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCategory = async () => {
    if (!categoryName) {
      toast.error("Category name is required!");
      return;
    }
    // Handle category creation
    console.log("Creating category:", categoryName, categoryDescription);

    try{
      setIsLoading(true);
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.createWithdrawalCategories,
        data: {
          projectId:formData.projectId,
          name: categoryName,
          description: categoryDescription ? categoryDescription : "No description provided for this category",
        },
      })

      toast.success("Category created successfully")
      console.log("Response after creating category : ",response)
      setCategories([...categories, response.response]);
      setShowCreateCategoryModal(false);
      setCategoryName("");
      setCategoryDescription("");
    }catch(err){
      console.log("Error creating category:", err);
      toast.error("Error creating category. Please try again.");
    }finally{
      setIsLoading(false);
    }
  };

  const handleCreateSubCategory = async () => {
    if (!subCategoryName) {
      toast.error("Sub-category name is required!");
      return;
    }
    console.log("Creating sub-category:", selectedCategory, subCategoryName, subCategoryDescription);

    let data ;
    if(selectedCategory.isDefault){
      data={
        projectId:formData.projectId ,
        categoryName:selectedCategory.name ,
        name:subCategoryName ,
        description:subCategoryDescription ? subCategoryDescription : "No description provided for this sub-category",
        isDefault:selectedCategory.isDefault
      }
    }else{
      data={
        categoryId:selectedCategory._id ,
        name:subCategoryName ,
        description:subCategoryDescription ? subCategoryDescription : "No description provided for this sub-category",
        isDefault:selectedCategory.isDefault

        

      }

    }

    try{
      setIsLoading(true)
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.createWithdrawalSubCategories,
        data: data
      })
      console.log("Response after creating sub-category : ",response)
      toast.success("Sub-category created successfully")
      
      selectedCategory.subCategories.push({name:subCategoryName,description:subCategoryDescription});
      setShowCreateSubCategoryModal(false);
      setSubCategoryName("");
      setSubCategoryDescription("");
    }catch(err){
      console.log("Error creating sub-category:", err);
      toast.error("Error creating sub-category. Please try again.");
    }finally{
      setIsLoading(false);
    }

  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src='loading_icon.gif' alt='logo' />
      </div>
    );
  }


  const Tooltip: React.FC<{ content: string }> = ({ content }) => (
      <div className="group relative inline-flex items-center ml-2">
        <Info className="w-4 h-4 text-black  transition-colors cursor-help" />
        <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 absolute z-50 w-64 p-3 text-sm bg-black text-gray-100 rounded-lg shadow-lg -left-20 top-8">
          <div className="absolute w-3 h-3 bg-gray-900 transform rotate-45 -top-1.5 left-1/2 -ml-1.5"></div>
          <div className="relative">{content}</div>
        </div>
      </div>
    );

    const Label: React.FC<{ text: string; tooltip: string }> = ({ text, tooltip }) => (
      <p className="mb-2 flex items-center">
        {text}
        <Tooltip content={tooltip} />
      </p>
    );

    const tooltips ={
      receipt: "The receipt you upload should contain redacted data , any sensitive information should be removed.",
     

    }

  return (
    <div className="bg-[#fff] rounded-[16px] p-6 min-h-full">
      <div className="flex items-center gap-2 font-semibold h-full">
        <Link to="/settings">
          <h1 className="mb-0 text-[20px]">Settings</h1>
        </Link>
        <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />

        <h1 className="mb-0 text-[20px] text-[#52bbab]">Withdrawals</h1>
      </div>
      <p className="text-[14px] text-[#9e9b9b] mb-10">
        Submit your withdrawal request by filling in the required details and
        uploading the receipt.
      </p>

      <div className="grid grid-cols-12 gap-x-12 gap-y-4">
        <div className="col-span-6">
          <p className="mb-2">Project</p>
          <select
            name="projectId"
            value={formData.projectId}
            onChange={handleChange}
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          >
            <option value="">Select a project</option>
            {projects.map((project, index) => (
              <option key={index} value={project._id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-span-6">
          <p className="mb-2">Withdrawal Title</p>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter Withdrawal Title"
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          />
        </div>

        {/* Select/Create a category */}
        <div className="col-span-6">
          <p className="mb-2">Category</p>
          <select
            name="category"
            value={formData.category}
            disabled={isLoadingCategories || categories.length===0 || !formData.projectId}
            onChange={handleChange}
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          >
            <option value="">{isLoadingCategories ? "Fetching Available Categories..." 
            :
            categories.length===0 || !formData.projectId ? "No categories available. Create One." 
            :
            "Select a category" }
            </option>
            {!isLoadingCategories && formData.projectId && categories.map((category, index) => (
              <option key={index} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
          <button
            type="button"
            className="flex text-sm items-center mt-2 text-[#52bbab] hover:text-[#469f92] hover:bg-teal-50 px-2 py-1 rounded-lg "
            onClick={() =>{
              if(formData.projectId){
                setShowCreateCategoryModal(true);
              }
              else{
                toast.error("Please select a project first.");
              }
            }}
          >
            <PlusIcon className="h-5 w-5 mr-1" />
            Create a New Category
          </button>
        </div>

        {/* Select/Create a sub-category */}
        <div className="col-span-6">
          <p className="mb-2">Sub-Category</p>
          <select
            name="subCategory"
            value={formData.subCategory}
            disabled={!selectedCategory || isLoadingCategories || categories?.length===0 || !formData.projectId || !selectedCategory?.subCategories?.length}
            onChange={handleChange}
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          >
            <option value="">{
            !selectedCategory || categories.length===0 || !formData.projectId ? "Select a Category first"
            :
            !selectedCategory?.subCategories.length ? "No sub-category found. Create One."
            :
            "Select a Sub-Category"
            }
            </option>
            {selectedCategory?.subCategories.map((category, index) => (
              <option key={index} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
          <button
            type="button"
            className="flex text-sm items-center mt-2 text-[#52bbab] hover:text-[#469f92] hover:bg-teal-50 px-2 py-1 rounded-lg "
            onClick={() =>{
              if(formData.category){
                setShowCreateSubCategoryModal(true);
              }
              else{
                toast.error("Please select a category first.");
              }
            }}
          >
            <PlusIcon className="h-5 w-5 mr-1" />
            Create a New Sub-Category
          </button>
        </div>

        <div className="col-span-6">
          <p className="mb-2">Withdrawal Description</p>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Enter Withdrawal Description"
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          />
        </div>

        <div className="col-span-6">
          <Label text="Receipt" tooltip={tooltips.receipt}/> 
          <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
            <input type="file" className="hidden" onChange={handleFileChange} />
            <div className="flex justify-between items-center">
              <p className="text-gray-500 text-[14px]">
                {file ? file.name : "Upload your receipt"}  
              </p>
            </div>
          </label>
        </div>

        <div className="col-span-6">
          <p className="mb-2">Cost(€)</p>
          <input
            type="number"
            name="amount"
            value={formData.amount || ''}
            onChange={handleChange}
            placeholder="Enter Amount"
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          />
        </div>

        <div className="col-span-6">
      <p className="mb-2">URL (Optional)</p>
      <input
        type="url"
        name="url"
        value={formData.url}
        onChange={handleChange}
        placeholder="Enter URL"
        className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
      />
    </div>

    <div className="col-span-6">
      <p className="mb-2">Date</p>
      <input
        type="date"
        name="date"
        value={formData.date}
        onChange={handleChange}
        className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
      />
    </div>

        <div className="col-span-12 mt-6 flex justify-center">
          <button
            onClick={handleSubmit}
            disabled={isLoading || imageUploadLoader}
            className={`px-6 py-2 rounded-[12px] bg-[#52bbab] text-white font-medium ${isLoading ? "opacity-50" : ""}`}
          >
            {imageUploadLoader || isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>

      {/* Create Category Modal */}
      {showCreateCategoryModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-[16px] p-6 w-[400px]">
            <h2 className="text-[20px] font-semibold mb-4">
              Create category for {selectedProject?.name}
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-[14px] mb-1">Category Name</label>
                <input
                  type="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  placeholder="Enter category name"
                  className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
                  required
                />
              </div>
              <div>
                <label className="block text-[14px] mb-1">Description (Optional)</label>
                <input
                  type="text"
                  value={categoryDescription}
                  onChange={(e) => setCategoryDescription(e.target.value)}
                  placeholder="Enter description"
                  className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
                />
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setShowCreateCategoryModal(false)}
                className="px-6 py-2 rounded-[12px] bg-gray-300 text-black font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateCategory}
                className="px-6 py-2 rounded-[12px] bg-[#52bbab] text-white font-medium"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Create SubCategory Modal */}
      {showCreateSubCategoryModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-[16px] p-6 w-[400px]">
            <h2 className="text-[20px] font-semibold mb-4">
              Create Sub-category for {selectedCategory?.name}
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-[14px] mb-1">Sub-category Name</label>
                <input
                  type="text"
                  value={subCategoryName}
                  onChange={(e) => setSubCategoryName(e.target.value)}
                  placeholder="Enter sub-category name"
                  className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
                  required
                />
              </div>
              <div>
                <label className="block text-[14px] mb-1">Description (Optional)</label>
                <input
                  type="text"
                  value={subCategoryDescription}
                  onChange={(e) => setSubCategoryDescription(e.target.value)}
                  placeholder="Enter description"
                  className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
                />
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setShowCreateSubCategoryModal(false)}
                className="px-6 py-2 rounded-[12px] bg-gray-300 text-black font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateSubCategory}
                className="px-6 py-2 rounded-[12px] bg-[#52bbab] text-white font-medium"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Withdrawals;
