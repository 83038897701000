function formatNumberEuro(number:number|string|undefined): string {
    // Split the number into integer and decimal parts
    if(!number){
        return "0 €"
    }
    const [integerPart, decimalPart] = number.toString().split('.');
  
    // Format the integer part with commas as thousand separators
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Combine the formatted integer and decimal parts (if any)
    const formattedNumber = decimalPart
      ? `${formattedInteger}.${decimalPart}` // Use a dot for decimal separation
      : formattedInteger;
  
    // Add the Euro symbol before the formatted number
    return `${formattedNumber} €`;
  }

  export default formatNumberEuro;