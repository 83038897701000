import React, { useEffect, useState } from "react";
import {
  FileText,
  Mail,
  Briefcase,
  Loader2,
  Globe,
  DollarSign,
} from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useGetWithdrawals from "./useGetWithdrawals";
import { Application, ProfileResponse } from "./Withdrawal.props";
import { FaMoneyBill } from "react-icons/fa";

interface WithDrawApplication {
  _id: string;
  userId: ProfileResponse;
  title: string;
  description: string;
  projectId: Application & {
    website?: string;
  };
  receipt: string;
  status: string;
  amount: number;
}

const WithdrawalDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState<WithDrawApplication>();
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [reason, setReason] = useState("");
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const { getWithdrawalDetailsById } = useGetWithdrawals();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await getWithdrawalDetailsById(String(id));
        console.log("Withdrawal information : ", response);
        setFormData(response);
      } catch (error) {
        console.log(
          "Some Error occurred While Fetching Withdrawal detail by ID",
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        <img src='loading_icon.gif' alt='logo' className="" />
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        No Data Found, Please check the Project ID again.
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6 bg-white rounded-lg shadow">
      <header className="border-b pb-4">
        <h1 className="text-2xl font-bold text-emerald-600">
          Withdrawal Details
        </h1>
        <p className="text-gray-500">
          You can view the Withdrawal detail here .
        </p>
      </header>

      <section className="space-y-6">
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <Briefcase className="w-5 h-5 mr-2 text-emerald-600" />
            Withdrawal Information
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Title</label>
                <p className="font-medium">{formData.title}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Owner Name</label>
                <p className="font-medium">{formData.userId.fullName}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Project Name</label>
                <p className="font-medium">{formData.projectId.name}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <FaMoneyBill className="w-4 h-4 mr-1" />
                  Amount
                </label>
                <p className="font-medium">
                  {formData.amount?.toLocaleString() || 0}
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Mail className="w-4 h-4 mr-1" />
                  Email Address
                </label>
                <p className="font-medium">{formData.userId.email}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Globe className="w-4 h-4 mr-1" />
                  Website
                </label>
                {formData.projectId.website ? (
                  <a
                    href={formData.projectId.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-emerald-600 hover:underline"
                  >
                    {formData.projectId.website}
                  </a>
                ) : (
                  <p className="text-gray-500">Not provided</p>
                )}
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <FileText className="w-4 h-4 mr-1" />
                  Receipt
                </label>
                <a
                  href={formData.receipt}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-emerald-600 hover:underline"
                >
                  View Receipt
                </a>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <label className="text-gray-500 text-sm">Description</label>
            <div className="mt-2 ">
              <div
                className={`prose max-w-none ${!isDescriptionExpanded ? "line-clamp-3" : ""}`}
              >
                {formData.description}
              </div>
              {formData.description.length > 150 && (
                <button
                  onClick={() =>
                    setIsDescriptionExpanded(!isDescriptionExpanded)
                  }
                  className="text-emerald-600 hover:underline mt-2 text-sm"
                >
                  {isDescriptionExpanded ? "Show less" : "Read more"}
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WithdrawalDetails;
