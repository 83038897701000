import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import downImage from "../../../images/icons/down.png";
import DashboardIcon from "../../../images/icons/dashboard.png";
import Explore1 from "../../../images/Explore/image1.png";
import TrandingButton from "../../../images/Explore/trading.png";
import forwordButton from "../../../images/Explore/forword.png";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import ProgressBar from "./ProgressBar";
import { useDashboard } from "../../Dashboard/useDashboard";
import { useSelector } from "react-redux";
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import { dummyData } from "../../Dashboard/Components/dummyData";
import { RootState } from "../../../redux/appStore";
import formatNumberEuro from "../../../util/FormatNumberEuro";

const Overview = () => {
  const { getProjectsByUser } = useDashboard();
  const [allProjects, setAllProjects] = useState<ProjectResponse[]>([]);
  const user = useSelector((store: RootState) => store?.user?.user);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(4);
  const [search, setSearch] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [docLengthList, setDocLengthList] = useState([1]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isGridView, setIsGridView] = useState(true);


  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("All");

  const dropdownRef = useRef<HTMLDivElement>(null);

  function projectPerforManceCalculator(investmentRaised: any, requiredInvestment: any): number {
    const raised = Number(investmentRaised);
    const required = Number(requiredInvestment);

    console.log(raised, required);

    if (!required || isNaN(raised) || isNaN(required)) {
      return 0;
    }

    const result = (raised / required) * 100;
    return isNaN(result) ? 0 : result > 100 ? 100 : result;
  }

  useEffect(() => {
    async function fetchProjects() {
      setIsLoading(true);
      console.log("USER ID IN Project overview : ", user);
      if (user) {
        const response = await getProjectsByUser(
          page,
          limit,
          search,
          "creator",
          selected.toLowerCase()
        );
        if (response && Array.isArray(response.docs)) {
          setAllProjects(response.docs);
          setTotalPages(response.totalPages);
        } else {
          // If no projects are fetched, fall back to dummy data
          // setAllProjects(dummyData);
          console.error(
            "Error fetching project details or no projects available.",
          );
        }
      }
      setIsLoading(false);
    }

    fetchProjects();
  }, [page, search, user, , selected]);

  const getApprovalColor = (status: string | undefined) => {
    switch (status) {
      case "approved":
        return "text-teal-800 bg-teal-100";
      case "pending":
        return "text-orange-800 bg-orange-100";
      case "rejected":
        return "text-red-800 bg-red-100";
      case "deployed":
        return "text-green-800 bg-green-100"
      default:
        return "text-red-500";
    }
  };

  // if (isLoading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       {/* <img src='fulllogo.png' alt='logo' className="" /> */}
  //       Loading...
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="rounded-[20px] bg-white h-full">
        <div className="flex flex-col h-full">
          <div className="p-6 border-b-[1px]">
            {/* Top Section: Heading and Actions */}
            <div className="flex flex-wrap items-center justify-between gap-4 mb-4">
              {/* Left Section: Explore Heading */}
              <h1 className="text-[24px] font-semibold">Project Overview</h1>

              {/* Right Section: Search Field, Filter, and Dashboard Icon */}
              <div className="flex items-center gap-4">
                {/* Search Field */}
                <div className="flex items-center border border-[#56bbac] rounded-full overflow-hidden">
                  <input
                    type="text"
                    placeholder="Search Projects"
                    value={searchInput} // Bind local state to input
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="px-4 py-2 text-[14px] text-[#4a4a4a] outline-none flex-grow"
                  />
                  <button
                    onClick={() => setSearch(searchInput)}
                    className="px-4 py-2 bg-[#56bbac] text-white text-[14px] font-semibold hover:bg-[#479d94]"
                  >
                    Search
                  </button>
                </div>
                <Link
                  to="/listproject"
                  className="bg-[#56BBAC] text-[#fff] rounded-[20px] px-5 py-2"
                >
                  List a project
                </Link>

                <div className="relative" ref={dropdownRef}>
                  <div
                    className="h-[40px] min-w-28 px-4 rounded-[100px] gap-1 bg-[#fafafa] border flex items-center justify-center cursor-pointer hover:bg-[#f0f0f0]"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {selected}
                    <img className="w-[8px] h-[4px] ml-2" src={downImage} alt="Dropdown" />
                  </div>

                  {isOpen && (
                    <div className="absolute top-full left-0 mt-2 w-40 bg-white border rounded-lg shadow-md">
                      {["All", "Investable", "Documented Project"].map((option) => (
                        <div
                          key={option}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setSelected(option);
                            setIsOpen(false);
                          }}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>


                {/* <button
                onClick={() => setIsGridView(!isGridView)}
                className={`h-[40px] w-[40px] rounded-[100px] gap-1 border flex items-center justify-center cursor-pointer transition-all duration-200 ${
                  isGridView ? 'bg-[#6bd7c7]' : 'bg-[#fafafa] hover:bg-[#f0f0f0]'
                }`}
              >
                <img
                  className={`h-[18px] w-[18px] transition-all duration-200 ${isGridView ? 'brightness-200' : ''}`}
                  src={DashboardIcon}
                  alt="Toggle View"
                />
              </button> */}
              </div>
            </div>

            {/* Bottom Section: Description */}
            <p className="text-[14px] text-[#a4a4a4]">
              Take control of your green initiatives. Explore detailed project insights, manage deployment strategies, track key metrics, monitor investment cutoff status, and view progress updates—all within a single, powerful platform designed to help you succeed.
            </p>
          </div>

          {isLoading ?
            <div className="flex items-center justify-center h-full">
              Loading...
            </div>
            :
            <div className={`${isGridView ? 'grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1' : 'flex flex-col'} px-3 py-6`}>
              {/* Check if allProjects exists and has elements */}
              {allProjects && allProjects.length > 0 ? (
                allProjects.map((project) => (
                  <div className="px-3 mt-4">
                    <div className="flex flex-col explore-card min-h-[400px] rounded-[20px] border bg-[#fafafa] hover:shadow-md transition-shadow">
                      {/* Left: Image Section */}
                      <div className="flex-shrink-0 w-full">
                        <img
                          className="w-full h-[175px] rounded-md object-cover"
                          src={project.logo || 'no-img'}
                          alt={project.name}
                        />
                      </div>

                      {/* Right: Content Section */}
                      <div className="flex flex-col flex-grow min-w-0 p-6 pt-7 relative">
                      <div className="absolute top-[-20px] rounded-lg bg-[#56BBAC] right-[-10px] py-2 px-3 z-10">
                      <p className="text-white truncate max-w-[300px]">{project?.category.name || "Energy Efficiency"}</p>
                        </div>
                        <div className="flex justify-between items-start mb-2">
                          <h2 className="text-[18px] font-semibold truncate pr-2">
                            {project.name}
                          </h2>

                          <div className={`px-3 py-1 text-sm rounded-full ${getApprovalColor(project?.approvalStatus)}`}>
                            {project?.approvalStatus && project?.approvalStatus?.charAt(0).toUpperCase() + project?.approvalStatus?.slice(1)}
                          </div>
                          {/* <img
            className="h-[27px] w-[80px] flex-shrink-0"
            src={TrandingButton}
            alt="Trending Button"
          /> */}
                        </div>

                        {/* Description Section */}
                        <div className="w-full">
                          <p className="text-[12px] text-[#a4a4a4] line-clamp-2">
                            {project.description.length > 90
                              ? project.description.slice(0, 50) + "..."
                              : project.description}
                          </p>
                        </div>

                        {/* Investment Details */}
                        {project.isProfitable && (
                          <div className="mt-auto space-y-2 pt-3">
                            <div className="flex items-center justify-between">
                              <p className="text-[12px] text-[#a4a4a4]">
                                Raised: {formatNumberEuro(project.investmentRaised)}
                              </p>
                              <p className="text-[12px] text-[#a4a4a4]">
                                {projectPerforManceCalculator(
                                  project.investmentRaised,
                                  project.requiredInvestment)}%
                              </p>
                            </div>
                            <ProgressBar
                              progress={projectPerforManceCalculator(
                                project.investmentRaised,
                                project.requiredInvestment)}
                              height={3}
                              color="#56BBAC"
                            />
                            <div className="flex items-center justify-between">
                              <h2 className="text-[14px] text-[#56BBAC] font-semibold">
                                Goal: {formatNumberEuro(project.requiredInvestment)}
                              </h2>
                              <Link
                                to={`/projectdetails/${project._id}`}
                                className="button bg-[#56BBAC] flex items-center gap-2 w-[90px] h-[32px] justify-center rounded-[100px] hover:bg-[#4ca799] transition-colors"
                              >
                                <span className="text-[12px] text-white">Explore</span>
                                <img
                                  className="h-[20px] w-[20px]"
                                  src={forwordButton}
                                  alt="Forward Button"
                                />
                              </Link>
                            </div>
                          </div>
                        )}

                        {/* Explore Button for non-profitable projects */}
                        {!project.isProfitable && (
                          <div className="mt-auto flex justify-end pt-3">
                            <Link
                              to={`/projectdetails/${project._id}`}
                              className="button bg-[#56BBAC] flex items-center gap-2 w-[90px] h-[32px] justify-center rounded-[100px] hover:bg-[#4ca799] transition-colors"
                            >
                              <span className="text-[12px] text-white">Explore</span>
                              <img
                                className="h-[20px] w-[20px]"
                                src={forwordButton}
                                alt="Forward Button"
                              />
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-12 flex items-center justify-center h-[200px]">
                  <p className="text-center text-[18px] text-gray-500 font-semibold">
                    {search
                      ? "No Projects Found For The Given Search Parameter ! "
                      : "No Projects Found !!"}
                  </p>
                </div>
              )}
            </div>
          }

          {/* Pagination (optional) */}
          <div className="w-full flex text-center items-center justify-center mt-10 mx-auto">
            <div className="flex gap-2 mb-7 justify-center">
              {/* Previous Button */}
              <button
                disabled={page === 1}
                onClick={() => page > 1 && setPage(page - 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img
                  className={
                    "h-[10px] w-[7px]" + (page === 1 ? " opacity-40 " : "")
                  }
                  src={prevButton}
                  alt="Previous"
                />
              </button>

              {/* Page Buttons */}
              {(() => {
                // Calculate start and end of page numbers to display
                const maxVisibleButtons = 3;
                let startPage = Math.max(
                  1,
                  page - Math.floor(maxVisibleButtons / 2),
                );
                let endPage = Math.min(
                  Number(totalPages),
                  startPage + maxVisibleButtons - 1,
                );

                // Adjust startPage if there are fewer pages at the end
                if (endPage - startPage + 1 < maxVisibleButtons) {
                  startPage = Math.max(1, endPage - maxVisibleButtons + 1);
                }

                return Array.from(
                  { length: endPage - startPage + 1 },
                  (_, index) => startPage + index,
                ).map((pageNum) => (
                  <button
                    key={pageNum}
                    onClick={() => setPage(pageNum)}
                    className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${page === pageNum ? "bg-[#56BBAC] text-white" : ""
                      }`}
                  >
                    {pageNum}
                  </button>
                ));
              })()}

              {/* Next Button */}
              <button
                onClick={() => page < Number(totalPages) && setPage(page + 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img
                  className={
                    "h-[10px] w-[7px]" +
                    (page === Number(totalPages) ? " opacity-40 " : "")
                  }
                  src={nextButton}
                  alt="Next"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
