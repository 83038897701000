import { useState } from "react";
import { ProjectResponse } from "../Dashboard/Dashboard.props";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export const useExplore = () => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector((store: any) => store?.user?.isLoggedIn);

  const getProjectDetailsById = async (
    id: string,
  ): Promise<ProjectResponse | null> => {
    setLoading(true);

    const endpoint = isLoggedIn ? apiPaths.ListProject : apiPaths.getValidProjectById
    try {
      const response = await ApiService({
        method: "GET",
        endpoint: endpoint + "/" + id,
      });

      if (response) {
        console.log(response);
        return response.response;
      } else {
        toast("Failed to fetch project");
        return null;
      }
    } catch (error: any) {
      const errorMessage = error.message || "Error while fetching projects";
      console.error("Project error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };


  const getProjects = async (search: string, page: number, limit: number , mode:String) => {
    setLoading(true);
    var queryString = "?page=" + page + "&limit=" + limit +"&mode="+mode;

    queryString += search ? "&search=" + search : "";

    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.allProjects + queryString,
      });

      if (response) {
        return response.response;
      } else {
        toast("Failed to fetch all projects");
        return null;
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error("Error fetching Projects.");
      } else {
        toast.error('Error Occurred');
      }
     
      return null;
    } finally {
      setLoading(false);
    }
  };




  const fetchWithdrawalDocumentation = async (contractAddress:string) => {
        setLoading(true);
        try {
          const response = await ApiService({
            method: "POST",
            endpoint: apiPaths.getWithdrawalDocumentation,
            data: {
              projectManagerAddress: contractAddress,
            },
            ledgerApi: true,
          });
          
          // Assign categories if they don't exist
          // const processedData = assignCategoriesAndSubcategories(response.response);
          // setRawWithdrawals(processedData);
          
          // // Extract unique categories and subcategories
          // const extractedCategories = extractCategories(processedData);
          // setCategories(extractedCategories);
          
          // setWithdrawals(processedData);
          // setFilteredWithdrawals(processedData);

          return response
        } catch (error) {
          console.error("Error fetching withdrawal documentation:", error);
        } finally {
          setLoading(false);
        }
      };





  const fetchBalanceSheetDetails = async(id:String)=>{
    try{

      const [investments , donations] = await Promise.all([
        ApiService({
          method:'GET' ,
          endpoint:`${apiPaths.getProjectInvestments}?projectId=${id}` ,
        }) ,

        ApiService({
          method:'GET' ,
          endpoint:`${apiPaths.getProjectDonations}?projectId=${id}` ,
        })
      ])

      return {investments , donations}

    }catch(err){
      return null
      

    }
  }








  
  return { getProjectDetailsById, getProjects, loading ,fetchWithdrawalDocumentation , fetchBalanceSheetDetails };
};
 