import React, { useEffect, useMemo, useState } from "react";
import ProfileImage from "../../../images/icons/profileimg.png";
import EditIcon from "../../../images/icons/edit.png";
import { useProfile } from "../useProfile";
import { ProfileFormData, ProfileResponse } from "../Profile.props";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/userSlice";
import { RootState } from "../../../redux/appStore";
import toast from "react-hot-toast";
import { parsePhoneNumber, isValidPhoneNumber, CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { Country, State, City } from 'country-state-city';
import {validate} from 'postal-codes-js'


interface CountryOption {
  code: CountryCode;
  label: string;
  dialCode: string;
}


interface LocationOption {
  value: string;
  label: string;
}

const Profile = () => {
  const { getProfile, updateProfile, updateProfilePic, changePassword } =
    useProfile();
  const dispatch = useDispatch();
  const user = useSelector((store: RootState) => store?.user?.user);

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [profileData, setProfileData] = useState({
    profilePic: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  const [selectedImage, setSelectedImage] = useState<string | null>(
    user ? user.profilePic : null,
  );


  const [availableStates, setAvailableStates] = useState<LocationOption[]>([]);
  const [availableCities, setAvailableCities] = useState<LocationOption[]>([]);


  // Create memoized country list
  const countries: LocationOption[] = useMemo(() => {
    return Country.getAllCountries().map(country => ({
      value: country.isoCode,
      label: country.name
    }));
  }, []);

  // Update states when country changes
  useEffect(() => {
    if (profileData.country) {
      const statesList = State.getStatesOfCountry(profileData.country).map(state => ({
        value: state.isoCode,
        label: state.name
      }));
      setAvailableStates(statesList);
      
      // Sync country code when country changes
      syncCountryCodeWithSelectedCountry(profileData.country);
    } else {
      setAvailableStates([]);
    }
  }, [profileData.country]);

  // Update cities when state changes
  useEffect(() => {
    if (profileData.country && profileData.state) {
      const citiesList = City.getCitiesOfState(
        profileData.country,
        profileData.state
      ).map(city => ({
        value: city.name,
        label: city.name
      }));
      setAvailableCities(citiesList);
    } else {
      setAvailableCities([]);
    }
  }, [profileData.country, profileData.state]);
  const [imageFile, setImageFile] = useState<File | null>(null); // Store the actual file
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);

  // Get all country codes and sort them alphabetically
  const getCountryOptions = (): CountryOption[] => {
    const countries = getCountries();
    return countries.map(country => ({
      code: country as CountryCode,
      dialCode: `+${getCountryCallingCode(country as CountryCode)}`,
      label: new Intl.DisplayNames(['en'], { type: 'region' }).of(country) || country
    })).sort((a, b) => a.label.localeCompare(b.label));
  };
  const countryOptions = getCountryOptions();

  const getCountryCodeFromDialCode = (dialCode: string): CountryCode  => {
    const countries = getCountries();
    for (const country of countries) {
      if (`+${getCountryCallingCode(country as CountryCode)}` === dialCode) {
        return country as CountryCode;
      }
    }
    return "DE";
  };

  const [countryCode, setCountryCode] = useState<CountryCode>(getCountryCodeFromDialCode(user?.phoneNumber?.split(" ")[0] || "+49"));

  // Function to sync country code with selected country
  const syncCountryCodeWithSelectedCountry = (countryIsoCode: string) => {
    // Find the country code that matches the selected country
    const matchingCountry = countryOptions.find(
      option => option.code === countryIsoCode as CountryCode
    );
    
    if (matchingCountry) {
      setCountryCode(matchingCountry.code);
    }
  };

  // Function to sync selected country with country code
  const syncSelectedCountryWithCountryCode = (selectedCountryCode: CountryCode) => {
    // Only update if currently selected country doesn't match the phone country code
    if (profileData.country !== selectedCountryCode) {
      setProfileData(prev => ({
        ...prev,
        country: selectedCountryCode,
        // Reset dependent fields
        state: "",
        city: "",
        postalCode: ""
      }));
    }
  };

  const validatePhoneNumber = (phone: string, country: CountryCode): boolean => {
    try {
      const phoneWithCode = phone.startsWith('+') ? phone : `+${getCountryCallingCode(country)}${phone}`;
      const valid =  isValidPhoneNumber(phoneWithCode, country);
      console.log(valid)
      return valid
    } catch (error) {
      return false;
    }
  };

  const formatPhoneNumber = (phone: string, country: CountryCode): string => {
    try {
      const phoneWithCode = phone.startsWith('+') ? phone : `+${getCountryCallingCode(country)}${phone}`;
      const phoneNumber = parsePhoneNumber(phoneWithCode, country);
      return phoneNumber ? phoneNumber.formatInternational() : phone;
    } catch (error) {
      return phone;
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Remove any non-digit characters except plus sign
    const sanitizedValue = value.replace(/[^\d+]/g, '');
    setProfileData((prev) => ({ ...prev, phoneNumber: sanitizedValue }));
    // setErrors((prev) => prev.filter((error) => error.field !== 'phoneNumber'));
  };

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCode = e.target.value as CountryCode;
    setCountryCode(selectedCode);
    
    // Sync the main country selection when country code changes
    syncSelectedCountryWithCountryCode(selectedCode);
  };

  const setProfile = (response: ProfileResponse) => {
    setProfileData({
      profilePic: response.profilePic,
      fullName: response.fullName,
      email: response.email,
      phoneNumber: response.phoneNumber.split(" ").slice(1).join(" "),
      dateOfBirth: response.dateOfBirth,
      city: response.city,
      state: response.state,
      country: response.country,
      postalCode: response.postalCode,
    });
  };

  if (user && profileData.fullName === "") {
    setProfile(user);
  }

  const handleProfilePictureChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the image
      setSelectedImage(imageUrl); // Store the image in the state
      dispatch(setUser({ ...user, profilePic: imageUrl }));
      setImageFile(file); // Store the actual file
    }
  };

  const saveProfile = async () => {
    // Validate postal code
    if(profileData.country && profileData.postalCode) {
      const isValidPostalCode = validatePostalCodeByCountryCode(profileData.country, profileData.postalCode);
      if(!isValidPostalCode) {
        toast.error('Invalid Postal Code');
        return;
      }
    }

    // Validate phone number
    if(!validatePhoneNumber(profileData.phoneNumber.trim(), countryCode)){
      toast.error("Invalid Phone Number!");
      return;
    }
    
    let returnedImage = "";

    setIsProfileUpdating(true);

    // Check if a new profile picture was selected
    if (imageFile) {
      const formData = new FormData();
      formData.append("files", imageFile);

      const uploadResponse = await updateProfilePic(formData);

      if (uploadResponse && uploadResponse.files?.length) {
        returnedImage = uploadResponse.files[0]; // Get the first file string from the response
      } else {
        toast.error("Error uploading profile picture");
      }
    }

    const updatedProfileData = {
      ...profileData,
      phoneNumber: formatPhoneNumber(profileData.phoneNumber.trim(), countryCode),
      profilePic: returnedImage || profileData.profilePic,
    };
    
    const response = await updateProfile(updatedProfileData);

    if (response) {
      setSelectedImage(response.profilePic);
      setProfile(response);
      dispatch(setUser(response));
      toast.success("Profile updated successfully");
    } else {
      toast.error("Error updating profile");
    }
    
    setIsProfileUpdating(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    if(name === 'postalCode'){
      if(!profileData.country){
        toast.error("Please select a country first");
        return;
      }

      if(!profileData.state){
        toast.error("Please select a state first");
        return;
      }

      if(!profileData.city){
        toast.error("Please select a city first");
        return;
      }
    }
    
    setProfileData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangePassword = async () => {
    // Validate passwords
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      toast.error("New passwords do not match");
      return;
    }

    if (passwordData.newPassword.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    setIsPasswordChanging(true);
    try {
      const response = await changePassword(
        passwordData.oldPassword,
        passwordData.newPassword,
      );

      if (response) {
        toast.success("Password changed successfully");
        // Reset password fields
        setPasswordData({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        setIsPasswordModalOpen(false);
      } else {
        toast.error("Failed to change password");
      }
    } catch (error) {
      toast.error("An error occurred while changing password");
    } finally {
      setIsPasswordChanging(false);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen">Loading...</div>
    );
  }

  function validatePostalCodeByCountryCode(countryIsoCode: string, postalCode: string) {
    if (!countryIsoCode || !postalCode) {
      return false;
    }
    
    // Fix: return true when validate() returns an empty string (valid)
    // return false when validate() returns an error message (invalid)
    const validationResult = validate(countryIsoCode, postalCode);
    return validationResult === true || validationResult === "";
  }

  return (
    <>
      {isPasswordModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-[16px] w-[400px] relative">
            <button
              onClick={() => setIsPasswordModalOpen(false)}
              className="absolute top-4 right-4 text-[20px] text-gray-500 hover:text-gray-700"
            >
              ×
            </button>
            <h2 className="text-[20px] font-semibold mb-4">Change Password</h2>
            <div className="space-y-4">
              <div>
                <p className="mb-2">Old Password</p>
                <input
                  type="password"
                  name="oldPassword"
                  value={passwordData.oldPassword}
                  onChange={handlePasswordInputChange}
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px]"
                  placeholder="Enter old password"
                />
              </div>
              <div>
                <p className="mb-2">New Password</p>
                <input
                  type="password"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordInputChange}
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px]"
                  placeholder="Enter new password"
                />
              </div>
              <div>
                <p className="mb-2">Confirm New Password</p>
                <input
                  type="password"
                  name="confirmNewPassword"
                  value={passwordData.confirmNewPassword}
                  onChange={handlePasswordInputChange}
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px]"
                  placeholder="Confirm new password"
                />
              </div>
              <button
                onClick={handleChangePassword}
                disabled={isPasswordChanging}
                className="bg-[#52bbab] px-12 rounded-[14px] text-[#fff] py-3 w-full flex items-center justify-center"
              >
                {isPasswordChanging ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Changing...
                  </div>
                ) : (
                  "Change Password"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-[#fff] p-6 rounded-[16px]">
        <h1 className="text-[20px] font-semibold">Profile</h1>
        <p className="text-[14px] text-[#9e9b9b] mt-2">
          View and edit your profile information. Manage your personal details,
          profile picture, and account settings to ensure your information is
          up-to-date and tailored to your preferences
        </p>
        <div className="p-4 flex items-center justify-between bg-[#fafafa] border rounded-[14px] mt-6">
          <div className="flex items-center gap-4">
            <img
              className="h-[75px] w-[75px] rounded-full"
              src={user.profilePic || selectedImage || ProfileImage} // Show selected image or default
              alt="Profile"
            />
            <div>
              <h1 className="text-[20px] font-semibold">
                {profileData.fullName || "Leslie Alexander"}
              </h1>
              <p className="text-[#a4a4a4]">
                {profileData.email || "lesliealexander@gmail.com"}
              </p>
            </div>
          </div>
          <div className="flex gap-4">
            <label className="flex items-center gap-3 bg-[#e9e9e9] py-2 px-3 rounded-[25px] cursor-pointer">
              <img src={EditIcon} alt="Profile" className="h-[12px] w-[12px]" />
              <p className="text-[12px]">Change Profile Picture</p>
              <input
                type="file"
                accept="image/*"
                onChange={handleProfilePictureChange}
                className="hidden"
              />
            </label>
            <button
              onClick={() => setIsPasswordModalOpen(true)}
              className="flex items-center gap-3 bg-[#e9e9e9] py-2 px-3 rounded-[25px] cursor-pointer"
            >
              <img
                src={EditIcon}
                alt="Password"
                className="h-[12px] w-[12px]"
              />
              <p className="text-[12px]">Change Password</p>
            </button>
          </div>
        </div>
        <div className="p-4">
          <h1 className="text-[20px] font-semibold mb-6">
            Personal Information
          </h1>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Full Name</p>
                <input
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="text"
                  name="fullName"
                  value={profileData.fullName}
                  onChange={handleInputChange}
                  placeholder="Enter Your Full Name"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Email Address</p>
                <input
                  disabled
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="text"
                  name="email"
                  value={profileData.email}
                  onChange={handleInputChange}
                  placeholder="Enter Your Email Address"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Phone Number</p>
                <div className="flex gap-2">
                  <select
                    className="bg-[#eeeeee] w-[10rem] h-[51px] px-2 rounded-[14px] mt-3"
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                  >
                    {countryOptions.map(option => (
                        <option key={option.code} value={option.code}>
                          {option.label} ({option.dialCode})
                        </option>
                      ))}
                  </select>
                  <input
                    className="bg-[#eeeeee] w-[10rem] h-[51px] px-4 rounded-[14px] mt-3"
                    type="text"
                    name="phoneNumber"
                    value={profileData.phoneNumber}
                    onChange={handlePhoneChange}
                    placeholder="Enter Phone Number"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Date of birth</p>
                <input
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="date"
                  name="dateOfBirth"
                  value={profileData.dateOfBirth}
                  onChange={handleInputChange}
                  placeholder="Enter Your Date of birth"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Country</p>
                <select
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  name="country"
                  value={profileData.country}
                  onChange={handleInputChange}
                >
                  <option value="">Select Country</option>
                  {countries.map(country => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            {/* Update the state selection */}
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>State</p>
                <select
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  name="state"
                  value={profileData.state}
                  onChange={handleInputChange}
                  disabled={!profileData.country}
                >
                  <option value="">Select State</option>
                  {availableStates.map(state => (
                    <option key={state.value} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            {/* Update the city selection */}
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>City</p>
                <select
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  name="city"
                  value={profileData.city}
                  onChange={handleInputChange}
                  disabled={!profileData.state}
                >
                  <option value="">Select City</option>
                  {availableCities.map(city => (
                    <option key={city.value} value={city.value}>
                      {city.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Postal Code</p>
                <input
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="text"
                  name="postalCode"
                  value={profileData.postalCode}
                  onChange={handleInputChange}
                  placeholder="Enter Your Code"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6 flex items-end justify-end gap-x-4">
              <button
                onClick={saveProfile}
                className="bg-[#52bbab] px-6 rounded-[14px] text-[#fff] py-3 w-[8rem]"
                disabled={isProfileUpdating}
              >
                {isProfileUpdating ? "Updating..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;