import React, { useEffect, useRef, useState } from "react";
import { Search, ArrowUpDown, ChevronDown, ChevronUp, File, ExternalLink, Filter, PieChart, History, HistoryIcon, DollarSign, Info } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import dayjs from "dayjs";
import formatNumberEuro from "../../../util/FormatNumberEuro";
import { IConversion } from "../Explore.props";
import getHistoricalRate from "../../../util/getHistoricalRates";

export interface DataItem {
  _id: string;
  title: string;
  description: string;
  amount: number;
  timestamp: string;
  category: string;
  subcategory: string;
  receiptUrl: string;
  blockchainUrl: string;
  receipt:string
}

interface CategoryData {
  category: string;
  subcategories: string[];
}

interface SortConfig {
  key: keyof DataItem | null;
  direction: 'asc' | 'desc';
}

const ViewDocumentation: React.FC<{ 
  project: ProjectResponse | null, 
  expandedWithdrawalIndexes?: number[],
  currencyRates: IConversion[],
  withdrawals: DataItem[]  ,
  contractAddress:string
}> = ({ project, expandedWithdrawalIndexes = [], currencyRates, withdrawals: initialWithdrawals , contractAddress }) => {
  const [withdrawals, setWithdrawals] = useState<DataItem[]>([]);
  const [filteredWithdrawals, setFilteredWithdrawals] = useState<DataItem[]>([]);
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState<{ [key: string]: boolean }>({});
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'asc' });
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>("All");
  const [availableSubcategories, setAvailableSubcategories] = useState<string[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);
  
  const navigate = useNavigate();

  const totalAmount = withdrawals.reduce((sum, item) => sum + item.amount, 0);
  const filteredTotalAmount = filteredWithdrawals.reduce((sum, item) => sum + item.amount, 0);

  const getCategoryBreakdown = () => {
    const breakdown: Record<string, number> = {};
    
    filteredWithdrawals.forEach(item => {
      const category = item.category || "N/A";
      if (!breakdown[category]) {
        breakdown[category] = 0;
      }
      breakdown[category] += item.amount;
    });
    
    return breakdown;
  };

  const formatDate = (timestamp: string): string => {
    const date = new Date(parseInt(timestamp) * 1000);
    return dayjs(date).format("YYYY-MM-DD HH:mm");
  };

  // Extract categories and subcategories directly from the data
  const extractCategories = (data: DataItem[]): CategoryData[] => {
    const categoryMap = new Map<string, Set<string>>();
    
    data.forEach(item => {
      const category = item.category || "N/A";
      
      if (!categoryMap.has(category)) {
        categoryMap.set(category, new Set<string>());
      }
      
      const subcategory = item.subcategory || "N/A";
      categoryMap.get(category)?.add(subcategory);
    });
    
    const result: CategoryData[] = [];
    categoryMap.forEach((subcategories, category) => {
      result.push({
        category,
        subcategories: Array.from(subcategories)
      });
    });
    
    return result;
  };

  const Tooltip: React.FC<{ content: string }> = ({ content }) => (
    <div className="group relative inline-flex items-center ml-2">
      <Info className="w-4 h-4 text-black transition-colors cursor-help" />
      <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 absolute z-50 w-64 p-3 text-sm bg-black text-gray-100 rounded-lg shadow-lg -left-20 top-8">
        <div className="absolute w-3 h-3 bg-gray-900 transform rotate-45 -top-1.5 left-1/2 -ml-1.5"></div>
        <div className="relative">{content}</div>
      </div>
    </div>
  );

  const Label: React.FC<{ text: string; tooltip: string }> = ({ text, tooltip }) => (
    <p className="flex items-center font-medium text-gray-900 pb-3">
      {text}
      <Tooltip content={tooltip} />
    </p>
  );

  // Initialize component with props data
  useEffect(() => {
    console.log("expanded", expandedWithdrawalIndexes);
    if (initialWithdrawals?.length > 0) {
      setLoading(true);
      
      // Use existing data without modifying - don't assign dummy categories
      const processedData = initialWithdrawals;
      
      // Extract unique categories and subcategories from actual data
      const extractedCategories = extractCategories(processedData);
      setCategories(extractedCategories);
      
      setWithdrawals(processedData);
      setFilteredWithdrawals(processedData);
      setLoading(false);
    }
  }, [initialWithdrawals]);

  useEffect(() => {
    const initialExpandedState: { [key: string]: boolean } = {};
    const scrollTargetIndex = expandedWithdrawalIndexes.find(index => 
      index >= 0 && index < withdrawals.length
    );

    // Expand specified rows
    expandedWithdrawalIndexes.forEach(index => { 
      if (index >= 0 && index < withdrawals.length && withdrawals[index]) {
        initialExpandedState[index] = true;
      } 
    });

    console.log("initialex", initialExpandedState);
    
    // Set the expanded rows state
    setExpandedRows(initialExpandedState);

    // Scroll to the first valid index if exists
    if (scrollTargetIndex !== undefined && rowRefs.current[scrollTargetIndex]) {
      const targetRow = rowRefs.current[scrollTargetIndex];
      const tableContainer = tableRef.current;

      if (targetRow && tableContainer) {
        // Calculate scroll position
        const rowTop = targetRow.offsetTop;
        const containerTop = tableContainer.scrollTop;
        const rowHeight = targetRow.offsetHeight;
        const containerHeight = tableContainer.clientHeight;

        // Scroll to make the row visible, with some padding
        tableContainer.scrollTop = rowTop - containerHeight / 2 + rowHeight / 2;
      }
    }
  }, [expandedWithdrawalIndexes, filteredWithdrawals]);

  // Update available subcategories when category changes
  useEffect(() => {
    if (selectedCategory === "All") {
      setAvailableSubcategories([]);
      return;
    }
    
    const category = categories.find(c => c.category === selectedCategory);
    if (category) {
      setAvailableSubcategories(category.subcategories);
    } else {
      setAvailableSubcategories([]);
    }
    
    // Reset subcategory selection when category changes
    setSelectedSubcategory("All");
  }, [selectedCategory, categories]);

  // Combined filtering for search, category, and subcategory
  useEffect(() => {
    let filtered = withdrawals;
    
    // Apply category filter
    if (selectedCategory !== "All") {
      filtered = filtered.filter(item => (item.category || "N/A") === selectedCategory);
    }
    
    // Apply subcategory filter
    if (selectedSubcategory !== "All" && selectedCategory !== "All") {
      filtered = filtered.filter(item => (item.subcategory || "N/A") === selectedSubcategory);
    }
    
    // Apply search query
    if (searchQuery) {
      filtered = filtered.filter(item => 
        Object.values(item).some(value => 
          value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
    
    setFilteredWithdrawals(filtered);
  }, [searchQuery, withdrawals, selectedCategory, selectedSubcategory]);

  // Sorting functionality
  const handleSort = (key: keyof DataItem) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return filteredWithdrawals;

    return [...filteredWithdrawals].sort((a, b) => {
      if (sortConfig.key === 'timestamp') {
        const aValue = parseInt(a[sortConfig.key]);
        const bValue = parseInt(b[sortConfig.key]);
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      const aValue = a[sortConfig.key!];
      const bValue = b[sortConfig.key!];
      
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      return sortConfig.direction === 'asc' 
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }); 
  }, [filteredWithdrawals, sortConfig]); 

  const toggleRowExpand = (id: string) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const resetFilters = () => {
    setSelectedCategory("All");
    setSelectedSubcategory("All");
    setSearchQuery("");
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
   
  const SortButton: React.FC<{ field: keyof DataItem; label: string }> = ({ field, label }) => (
    <button
      onClick={() => handleSort(field)}
      className="flex items-center gap-1 hover:text-gray-700 transition-colors"
    >
      {label}
      <ArrowUpDown size={16} className={`
        ${sortConfig.key === field ? 'opacity-100' : 'opacity-50'}
        ${sortConfig.key === field && sortConfig.direction === 'desc' ? 'rotate-180' : ''}
      `} />
    </button>
  );

  return (
    <div className="p-2 max-w-7xl mx-auto space-y-2 border shadow-md rounded-lg border-gray-200 mt-3">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 bg-white px-6 py-2 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold text-gray-800">
          Withdrawals for <span className="text-teal-700">{project?.name}</span>
        </h2>
      
      </div>

      {/* Search and Table Container */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        {/* Search and Filter Row - Fixed horizontal alignment */}
        <div className="px-4 py-4 border-b grid grid-cols-1 md:grid-cols-12 gap-4 items-end">
          {/* Search Bar */}
          <div className="relative md:col-span-6">
            <label htmlFor="search-filter" className="block text-xs font-medium text-gray-500 mb-1 ml-1">
              Search
            </label>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
              <input
                id="search-filter"
                type="text"
                placeholder="Search withdrawals..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border-2 rounded-lg focus:outline-none focus:ring-2 
                        focus:ring-teal-500 focus:border-transparent"
              />
            </div>
          </div>
          
          {/* Category Filter */}
          <div className="md:col-span-2">
            <label htmlFor="category-filter" className="block text-xs font-medium text-gray-500 mb-1 ml-1">
              Category
            </label>
            <select
              id="category-filter"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg 
                      focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent"
            >
              <option value="All">All Categories</option>
              {categories.map((cat, idx) => (
                <option key={idx} value={cat.category}>{cat.category}</option>
              ))}
            </select>
          </div>
          
          {/* Subcategory Filter */}
          <div className="md:col-span-2">
            <label htmlFor="subcategory-filter" className="block text-xs font-medium text-gray-500 mb-1 ml-1">
              Subcategory
            </label>
            <select
              id="subcategory-filter"
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
              disabled={selectedCategory === "All"}
              className={`w-full px-3 py-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg 
                      focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent
                      ${selectedCategory === "All" ? "opacity-60 cursor-not-allowed" : ""}`}
            >
              <option value="All">All Subcategories</option>
              {availableSubcategories.map((subcat, idx) => (
                <option key={idx} value={subcat}>{subcat}</option>
              ))}
            </select>
          </div>
          
          {/* Reset Filters Button */}
          <div className="md:col-span-2 flex justify-end">
            <button
              onClick={resetFilters}
              className="px-3 py-2 text-sm bg-gray-100 text-gray-700 rounded-lg border 
                      border-gray-300 hover:bg-gray-200 transition-colors flex items-center gap-1"
            >
              <Filter size={14} />
              Reset Filters
            </button>
          </div>
        </div>

        {/* Active Filters Display */}
        {(selectedCategory !== "All" || selectedSubcategory !== "All" || searchQuery) && (
          <div className="px-4 py-2 bg-gray-50 flex flex-wrap gap-2 items-center border-b">
            <span className="text-xs text-gray-500">Active filters:</span>
            {selectedCategory !== "All" && (
              <span className="px-2 py-1 bg-teal-50 text-teal-700 text-xs rounded-full border border-teal-200">
                Category: {selectedCategory}
              </span>
            )}
            {selectedSubcategory !== "All" && (
              <span className="px-2 py-1 bg-teal-50 text-teal-700 text-xs rounded-full border border-teal-200">
                Subcategory: {selectedSubcategory}
              </span>
            )}
            {searchQuery && (
              <span className="px-2 py-1 bg-teal-50 text-teal-700 text-xs rounded-full border border-teal-200">
                Search: "{searchQuery}"
              </span>
            )}
          </div>
        )}

        {/* Table Container */}
        <div className="relative">
          <div className="overflow-y-auto h-80 shadow-lg" ref={tableRef}>
            <table className="w-full table-fixed">
              <thead className="bg-gray-200 sticky top-0 z-10 shadow-md">
                <tr className="border-b">
                  <th className="w-8 px-2 py-3 text-center bg-gray-50"></th>
                  <th className="w-44 px-4 py-3 text-left bg-gray-50">
                    <SortButton field="timestamp" label="Date" />
                  </th>
                  <th className="w-32 px-4 py-3 text-left bg-gray-50">
                    <SortButton field="amount" label="Amount" />
                  </th>
                  <th className="w-64 px-4 py-3 text-left bg-gray-50">
                    <SortButton field="title" label="Product/Services" />
                  </th>
                  <th className="px-4 py-3 text-left bg-gray-50">Description</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {loading ? (
                  <tr>
                    <td colSpan={5} className="text-center py-8 text-gray-500">Loading...</td>
                  </tr>
                ) : sortedData.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center py-8">
                      <div className="flex flex-col items-center justify-center space-y-2 p-6 bg-gray-50 rounded-lg">
                        <Filter size={32} className="text-gray-400" />
                        <p className="text-lg font-medium text-gray-500">No withdrawals found</p>
                        <p className="text-sm text-gray-400 max-w-md text-center">
                          {selectedCategory !== "All" || selectedSubcategory !== "All" || searchQuery
                            ? "Try changing your filters or search criteria to see more results."
                            : "There are no withdrawal records available for this project."}
                        </p>
                        {(selectedCategory !== "All" || selectedSubcategory !== "All" || searchQuery) && (
                          <button
                            onClick={resetFilters}
                            className="mt-2 px-4 py-2 bg-teal-50 text-teal-700 rounded-md border border-teal-200 
                                    hover:bg-teal-100 transition-colors text-sm font-medium"
                          >
                            Clear filters
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  sortedData.map((item, index) => (
                    <React.Fragment key={item._id}>
                    <tr 
                      ref={el => rowRefs.current[index] = el}
                      className={`hover:bg-teal-50 group transition-colors cursor-pointer 
                        ${expandedRows[index] ? 'bg-teal-50' : ''}
                        ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                        `}
                      onClick={() => toggleRowExpand(index.toString())}
                    >
                        <td className="w-8 px-2 py-3 text-center">
                          <div className="transform transition-transform duration-200 group-hover:translate-y-[-2px] ">
                            {expandedRows[index] ? 
                              <ChevronUp size={16} className="text-teal-600 mx-auto" /> : 
                              <ChevronDown size={16} className="text-gray-500 mx-auto" />
                            }
                          </div>
                        </td>
                        <td className="w-44 px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                          {formatDate(item.timestamp)}
                        </td>
                        <td className="w-32 px-4 py-3 text-sm text-gray-900 whitespace-nowrap font-medium">
                          {formatNumberEuro(item.amount)}
                        </td>
                        <td className="w-64 px-4 py-3 text-sm text-gray-900">
                          {item.title}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900">
                          <div className="pr-4">
                            {expandedDescriptions[index] 
                              ? item.description
                              : item.description.length > 60
                                ? `${item.description.substring(0, 60)}... `
                                : item.description}
                            {item.description.length > 60 && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setExpandedDescriptions(prev => ({
                                    ...prev,
                                    [index]: !prev[index]
                                  }))
                                }}
                                className="text-teal-600 hover:text-teal-700 ml-1 text-sm font-medium"
                              >
                                {expandedDescriptions[index] ? "See Less" : "See More"}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                      {expandedRows[index] && (
  <tr className="bg-gray-50 border-b">
    <td colSpan={6} className="px-4 py-2">
      <div className="rounded-lg bg-white border border-gray-200 shadow-sm p-5 m-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Left column - Details */}
          <div className="space-y-4">
            <h3 className="font-medium text-gray-900 pb-2 border-b border-gray-200">
              Withdrawal Details
            </h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-xs text-gray-500 mb-1">Category</p>
                <p className="text-sm font-medium text-gray-800 bg-gray-50 px-3 py-2 rounded-md">
                  {item.category || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-xs text-gray-500 mb-1">Subcategory</p>
                <p className="text-sm font-medium text-gray-800 bg-gray-50 px-3 py-2 rounded-md">
                  {item.subcategory || "N/A"}
                </p>
              </div>
            </div>
            
            <div>
              <p className="text-xs text-gray-500 mb-1">Date & Time</p>
              <p className="text-sm font-medium text-gray-800 bg-gray-50 px-3 py-2 rounded-md">
                {formatDate(item.timestamp)}
              </p>
            </div>
            
            <div>
              <p className="text-xs text-gray-500 mb-1">Amount</p>
              <p className="text-sm font-medium text-teal-700 bg-teal-50 px-3 py-2 rounded-md">
                {formatNumberEuro(item.amount)}
              </p>
            </div>
          </div>

          {/* Right column - Full description and actions */}
          <div className="space-y-4">
            <h3 className="font-medium text-gray-900 pb-2 border-b border-gray-200">
              Description
            </h3>
            
            <div className="bg-gray-50 p-3 rounded-md text-sm text-gray-800 min-h-[100px]">
              {item.description || "No description provided."}
            </div>
            
            {/* Action buttons - Always visible as requested */}
            <div className="flex flex-wrap justify-end gap-3 pt-1">
              <a 
                href={item.receipt || "#"}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleButtonClick}
                className="inline-flex items-center px-4 py-2 bg-teal-50 text-teal-800 border border-teal-300 rounded-md 
                        hover:bg-teal-100 transition-colors text-sm font-medium shadow-sm"
              >
                <File size={16} className="mr-2" />
                View Receipt
              </a>


              
              <a 
                href={`https://testnet.snowtrace.io/address/${contractAddress}/contract/43113/readContract?chainid=43113`}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={handleButtonClick}
                className="inline-flex items-center px-4 py-2 bg-teal-50 text-teal-800 border border-teal-300 rounded-md 
                        hover:bg-teal-100 transition-colors text-sm font-medium shadow-sm"
              >
                <ExternalLink size={16} className="mr-2" />
                View on Blockchain
              </a>
            </div>
          </div>
        </div>
        
        {/* Currency Conversion Section - Moved outside of the columns to span full width */}
        <div className="border-t mt-6 pt-4 w-full">
          <Label text="Currency Conversion" tooltip="The rates are synchronized with the rates that were in effect at the time the withdrawal was made." />

  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
    {currencyRates.map((currency, index) => {
      // Get historical rate information once per currency to avoid multiple calculations
      const historicalRate = getHistoricalRate(currency.name, item.timestamp, currencyRates);
      
      // Skip rendering if no rate information is available
      if (!historicalRate) return null;
      
      return (
        <div key={index} className="bg-gray-50 p-4 rounded-lg border border-gray-200 shadow-sm">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-xs text-gray-500 mb-1">{currency.name} Amount</p>
              <p className="text-base font-semibold text-teal-700">
                {currency.symbol}{(item.amount * Number(historicalRate.rate)).toFixed(2)}
              </p>
            </div>
            <div className="text-right">
              <p className="text-xs text-gray-500 mb-1">Rate</p>
              <p className="text-base font-medium">{historicalRate.rate}</p>
            </div>
          </div>
          <p className="text-xs text-gray-500 mt-3">
            Valid: {dayjs(historicalRate.startDate).format("MMM DD, YYYY")} - {
              historicalRate.endDate 
                ? dayjs(historicalRate.endDate).format("MMM DD, YYYY") 
                : "Present"
            }
          </p>
        </div>
      );
    })}
  </div>
</div>
      </div>
    </td>
  </tr>
)}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* New Analytics Section */}
        {(filteredWithdrawals.length !== withdrawals.length) && (
          <div className="p-4 bg-teal-50 border-t border-teal-100">
            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="flex-1">
                <h3 className="text-sm font-medium text-teal-800 mb-2">Filtered Results Analysis</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-white rounded-lg p-3 border border-teal-200 shadow-sm">
                    <div className="text-xs text-gray-500">Filtered Withdrawals</div>
                    <div className="flex items-end justify-between">
                      <div className="text-lg font-semibold text-teal-700">{filteredWithdrawals.length}</div>
                      <div className="text-xs text-gray-400">of {withdrawals.length} total</div>
                    </div>
                    <div className="w-full bg-gray-200 h-1.5 mt-2 rounded-full overflow-hidden">
                      <div 
                        className="bg-teal-500 h-full rounded-full" 
                        style={{ width: `${(filteredWithdrawals.length / withdrawals.length) * 100}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg p-3 border border-teal-200 shadow-sm">
                    <div className="text-xs text-gray-500">Filtered Amount</div>
                    <div className="flex items-end justify-between">
                      <div className="text-lg font-semibold text-teal-700">{formatNumberEuro(filteredTotalAmount)}</div>
                      <div className="text-xs text-gray-400">{((filteredTotalAmount / totalAmount) * 100).toFixed(1)}% of total</div>
                    </div>
                    <div className="w-full bg-gray-200 h-1.5 mt-2 rounded-full overflow-hidden">
                      <div 
                        className="bg-teal-500 h-full rounded-full" 
                        style={{ width: `${(filteredTotalAmount / totalAmount) * 100}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <h3 className="text-sm font-medium text-teal-800 mb-2 flex items-center gap-1">
                  <PieChart size={16} />
                  Category Breakdown
                </h3>
                <div className="bg-white rounded-lg border-teal-200 shadow-sm max-h-32 overflow-y-auto">
                  {Object.entries(getCategoryBreakdown()).map(([category, amount], idx) => (
                    <div key={idx} className="flex justify-between items-center py-1 text-sm">
                      <div className="font-medium text-gray-700">{category}</div>
                      <div className="text-gray-900">{formatNumberEuro(amount)}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Summary Footer */}
          <div className="w-full flex flex-col md:flex-row gap-3 justify-between items-center my-5 px-4 py-3 bg-gray-50 border-t">
          <div className="flex flex-wrap gap-3">
            <div className="px-5 py-1 bg-teal-50 rounded-full shadow-sm">
              <p>Total Withdrawals: <span className="text-teal-700 font-semibold">{filteredWithdrawals.length}</span></p>
            </div>
            <div className="px-5 py-1 bg-teal-50 rounded-full shadow-sm">
              <p>Total Amount: <span className="text-teal-700 font-semibold">{formatNumberEuro(totalAmount)}</span></p>
            </div>
          </div>
          
          {/* Filter summary */}
          {(selectedCategory !== "All" || selectedSubcategory !== "All") && (
            <div className="text-sm text-gray-500">
              Showing {filteredWithdrawals.length} of {withdrawals.length} withdrawals
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewDocumentation; 