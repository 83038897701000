import React from 'react'
import toast from 'react-hot-toast'
import { useStripe } from '@stripe/react-stripe-js'
import { useStripe as useCustomStripe } from './useStripe' // Your custom hook

const BankAccountLink = () => {
  const stripe = useStripe() // Get Stripe instance from <Elements>
  const { getUserSessionId } = useCustomStripe() // Your custom hook function

  async function linkAccountHandler() {
    try {
      if (!stripe) throw new Error('Stripe has not loaded yet')

      const data = await getUserSessionId()
      console.log(data)
      

      if (data) {
        const result = await stripe.collectFinancialConnectionsAccounts({
          clientSecret: data.response
        })

        console.log(result)

        if (result.error) {
          throw new Error(result.error.message)
        }

        toast.success('Bank account linked successfully!')
      } else {
        throw new Error('Invalid session data')
      }
    } catch (error) {
      toast.error('Failed to link bank account. Please try again.')
      console.error('Bank account linking error:', error)
    }
  }

  return (
    <div>
      <button onClick={linkAccountHandler}>Link Your Bank Account</button>
    </div>
  )
}

export default BankAccountLink
