import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setUser } from "./redux/userSlice";
import { RootState } from "./redux/appStore";
import "./css/global.css";
import ApiService from "./service/ApiService";
import { apiPaths } from "./service/apiPaths";
import { MetaMaskProvider } from "@metamask/sdk-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { setIsLoading } from "./redux/loaderSlice";
import toast from "react-hot-toast";

// Authentication Pages
import Login from "./components/Authentication/Login/Login";
import Signup from "./components/Authentication/Signup/Signup";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import VerifyCode from "./components/Authentication/VerifyOtp/VerifyCode";
import NewPassword from "./components/Authentication/NewPassword";

// Layout
import DefaultLayout from "./layout/DefaultLayout";

// Pages
import ExploreIndex from "./page/Explore";
import ExploreDetails from "./page/Explore/Components/ExploreDetails";
import DashboardIndex from "./page/Dashboard";
import MarketOverviewIndex from "./page/MarketOverview";
import TransactionIndex from "./page/Transaction";
import SettingsIndex from "./page/Settings";
import Kyc from "./page/Settings/Components/Kyc";
import Notification from "./page/Settings/Components/Notification";
import ManageWallet from "./page/Settings/Components/ManageWallet";
import Preference from "./page/Settings/Components/Preference";
import HelpIndex from "./page/Help";
import ProfileIndex from "./page/Profile";
import ProjectOverviewIndex from "./page/ProjectOverview";
import ListProjectIndex from "./page/ListProject";
import ActiveInvestorIndex from "./page/ActiveInvestor";
import ProjectManager from "./page/ProjectManager/ProjectManager";
import Withdrawals from "./page/Settings/Components/Withdrawals";
import WithdrawalDetails from "./page/Withdrawal/WithdrawalDetails";
import StripePayment from "./components/Stripe/StripePayment";
import BankAccountLink from "./page/Stripe/BankAccountLink";
import Roadmap from "./page/Withdrawal/Roadmap";
import logo from "../src/images/logo/fulllogo.png";
import ProjectImpactEditPage from "./page/Settings/Components/ProjectImpactEditPage";
import Donations from "./page/Settings/Components/Donations";
import PhaseInformation from "./page/Settings/Components/PhaseInformation";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((store: RootState) => store?.user?.isLoggedIn);
  const isProjectManager = useSelector((store: RootState) => store.user.user?.isProjectManager);
  const isLoading = useSelector((store: RootState) => store.loader.isLoading);

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.checkTokens,
      });
      if (response?.response) {
        dispatch(setIsLoggedIn(true));
        dispatch(setUser(response.response.user));
      } else {
        dispatch(setIsLoggedIn(false));
        dispatch(setUser(null));
      }
    } catch (error) {
      dispatch(setIsLoggedIn(false));
      dispatch(setUser(null));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src="loading_icon.gif" alt="logo" className="" />
      </div>
    );
  }

  // Public routes
  const publicRoutes = [
    { path: "/login", element: <Login /> },
    { path: "/signup", element: <Signup /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "/verify/signup", element: <VerifyCode /> },
    { path: "/verify/newpassword", element: <VerifyCode /> },
    { path: "/newpassword/forgot/:forgotToken", element: <NewPassword /> },
    { path: "/newpassword/create", element: <NewPassword /> },
  ];

  // Common routes (accessible regardless of login state)
  const commonRoutes = [
    { path: "/", element: <ExploreIndex /> },
    { path: "/projectdetails/:id", element: <ExploreDetails investor={true} /> },
    // { path: "/documentation-roadmap/:id", element: <Roadmap /> },
    { path: "/help", element: <HelpIndex /> },
  ];
  
  // Protected routes
  const protectedRoutes = [
    { path: "/exploredetails/:id", element: <ExploreDetails /> },
    { path: "/withdrawaldetails/:id", element: <WithdrawalDetails /> },
    { path: "/dashboard", element: <DashboardIndex /> },
    { path: "/marketoverview", element: <MarketOverviewIndex /> },
    { path: "/transaction", element: <TransactionIndex /> },
    { path: "/settings", element: <SettingsIndex /> },
    { path: "/settings/kyc", element: <Kyc /> },
    { path: "/settings/notification", element: <Notification /> },
    { path: "/settings/managewallet", element: <ManageWallet /> },
    { path: "/settings/preference", element: <Preference /> },
    { path: "/settings/project-manager", element: <ProjectManager /> },
    { path: "/settings/withdrawals", element: <Withdrawals />, isProjectManagerRequired: true },
    { path: "/settings/donations", element: <Donations />, isProjectManagerRequired: true },
    { path: "/profile", element: <ProfileIndex /> },
    { path: "/projectoverview", element: <ProjectOverviewIndex />, isProjectManagerRequired: true },
    { path: "/listproject", element: <ListProjectIndex />, isProjectManagerRequired: true },
    { path: "/activeinvestor", element: <ActiveInvestorIndex />, isProjectManagerRequired: true },
    { path: "/settings/phase-information", element: <PhaseInformation />, isProjectManagerRequired: true },
    { path: "/investortransaction", element: <TransactionIndex /> },
    { path: "/stripe-payment", element: <StripePayment /> },
    { path: "/bank-account-link", element: <BankAccountLink /> },
    { path: "/settings/impact-edit", element: <ProjectImpactEditPage /> },
    
  ];

  return (
    <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          {!isLoggedIn && publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}

          {/* Common Routes (accessible regardless of login state) */}
          {commonRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<DefaultLayout>{route.element}</DefaultLayout>}
            />
          ))}

        <Route path="/documentation-roadmap/:id" element={<Roadmap />} />


          {/* Protected Routes */}
          {protectedRoutes.map((route, index) => {
            const isAllowed = route.isProjectManagerRequired ? isLoggedIn && isProjectManager : isLoggedIn;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  isAllowed ? (
                    <DefaultLayout>{route.element}</DefaultLayout>
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            );
          })}

          {/* Redirect unknown routes */}
          <Route path="*" element={<Navigate to={isLoggedIn ? "/" : "/login"} />}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;