import { apiPaths } from "../../service/apiPaths"
import ApiService from "../../service/ApiService"


export const useStripe = ()=>{


    const getUserSessionId = async()=>{
        try{
            const response = await ApiService({
                method:"GET" ,
                endpoint:apiPaths.getStripeSessionId

            })

            console.log(response)

 

            return response
        }catch(err){
            return null
        }
    }


    return {getUserSessionId}
}