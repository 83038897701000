import React, { useState } from "react";
import UploadIcon from "../../../images/icons/upload.png";
import BackIcon from "../../../images/icons/back.png";
import { useListProject } from "../useListProject";
import { ChangeEvent } from "react";
import axios from "axios";
import { apiPaths } from "../../../service/apiPaths";
import { ListProjectResponse } from "../listProject.props";
import { FormDataInterface } from "../interface";
import toast from "react-hot-toast";
import Successfully from "../../../components/Successfully";
import { useNavigate } from "react-router-dom";
import { useRef, useEffect } from "react";
import { ChevronDown, DollarSign, Euro, FileText, Info, Plus, Trash2 } from 'lucide-react';
import { parsePhoneNumber, isValidPhoneNumber, CountryCode, getCountries, getCountryCallingCode  } from 'libphonenumber-js';
import {validate} from 'postal-codes-js'
import ApiService from "../../../service/ApiService";
import ImpactView from "./ImpactView";
import { impactTypes } from "./ImpactView";

interface Currency {
  _id:string;
  name:string;
  symbol:string;
  code:string;
}

interface Category{
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}


interface RoadMapImages{
  _id:string ,
  imageUrl:string ,
  name:string
}


const MultiStepForm = () => {
  const { listProject, error } = useListProject();
    const [countryCode, setCountryCode] = useState<CountryCode>("DE");
    const [country , setCountry] = useState<String>("")
  

  const sessionStep = sessionStorage.getItem("listProjectStep");
  const [step, setStep] = useState(Number(sessionStep) || 1);
  const [newPhase, setNewPhase] = useState<ProjectPhase>({
    phaseName: '',
    phaseDescription: '',
    documentUrl:''
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [currencies,setCurrencies] = useState<Currency[]>([])
  const [categories,setCategories] = useState<Category[]>([])
  const[images , setImages] = useState<RoadMapImages[]>([])
  const navigate = useNavigate();

  interface FileUploadResponse {
    message: string;
    files: string[];
  }


  interface CountryOption {
    code: CountryCode;
    label: string;
    dialCode: string;
  }

  interface ProjectPhase {
    phaseName: string;
    phaseDescription: string;
    documentUrl:string
  }



  const [formData, setFormData] = useState<FormDataInterface>({
    phoneNumber: "",
    name: "",
    geographicMarket: "",
    postalCode: "",
    symbol: "",
    email: "",
    ticker: "test",
    category: "",
    otherDocument: [],
    description: "",
    images: [],
    requiredInvestment: 0,
    currenciesAccepted: [],
    projectPhases: [],
    auditReports: "test",
    compliance: ["test"],
    lockInPeriod: 0,
    maxSupply: 0,
    website: "",
    owner: "0x001",
    burn: 0,
    minInvestment: 0,
    projectType: "",
    projectStage: "",
    balanceSheet: "",
    logo: "",
    investmentCutoffDuration:'' , 
    isProfitable:false ,
    donations:[
     
    ] ,
    impactData:[] ,
    roadmapImage:""
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
 


const getCountryOptions = (): CountryOption[] => {
      const countries = getCountries();
      return countries.map(country => ({
        code: country as CountryCode,
        dialCode: `+${getCountryCallingCode(country as CountryCode)}`,
        label: new Intl.DisplayNames(['en'], { type: 'region' }).of(country) || country
      })).sort((a, b) => a.label.localeCompare(b.label));
    };

 const countryOptions = getCountryOptions();






  
  const selectedCurrencies = currencies.filter(
    (currency) =>
      Array.isArray(formData.currenciesAccepted) &&
      formData.currenciesAccepted.includes(currency._id),
  );

  interface FilesState {
    projectLogo: File | null;
    balanceSheet: File | null;
    otherDocument: File[];
    additionalImages: File[];
  }

  const [files, setFiles] = useState<FilesState>({
    projectLogo: null,
    balanceSheet: null,
    otherDocument: [],
    additionalImages: [],
  });
 
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNext = () => {
   
    if (step===7) {
        // setStep(step + 1);
        uploadFiles()
    } else {

      if(formData.isProfitable){
      setStep(step+1)
      }else{

        console.log("step" , step)
        if(step === 1 || step===4){
          setStep(step+2)
        }else {
          setStep(step+1)
        }

      }
    }
  };
 
  const closeModal = () => {
    navigate("/projectoverview");
    setIsModalOpen(false);
  };

  const getProjectType = (categoryId: string) => {
    const data = categories.filter((category) => category._id === categoryId);
    return data[0];
  };

  const handleBack = () => {
    if (step > 1) {
      if((step===3|| step===6) && !formData.isProfitable){
        setStep(step-2)
      
      }else{
        setStep(step - 1);
      }
    }
  };

  useEffect(() => {
    const savedData = sessionStorage.getItem("formData");
    // const sessionSavedStep = sessionStorage.get
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // USE EFFECT HOOK TO STORE DATA IN SESSION STORAGE
  useEffect(() => {
    sessionStorage.setItem("formData", JSON.stringify(formData));
    sessionStorage.setItem("listProjectStep", String(step));
  }, [formData, step]);

  //USE EFFECT TO FETCH CURRENCIES AND PROJECT CATEGORIES
  useEffect(()=>{
    const fetchOptions = async()=>{
      try{
        const response = await ApiService({
          method: "GET",
          endpoint : apiPaths.projectOptions,
        })
        console.log("Options : ",response)
        setCurrencies(response.response.currencies)
        setCategories(response.response.categories)
        setImages(response.response.images)

      }catch(err){
        console.log(err)
      }
    }
    fetchOptions()
  },[])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'description') {
      const strVal = String(value);
      if (strVal.length <= 500) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: strVal,
        }));
      } else {
        const truncatedText = strVal.substring(0, 500);
        setFormData((prevData) => ({
          ...prevData,
          [name]: truncatedText,
        }));
      }
    } else {
      setFormData((prevData) => {
        const updatedData: FormDataInterface = {
          ...prevData,
          [name]: name === "isProfitable"
            ? value === "true"
            : name === "investmentCutoffDuration"
              ? value === "" || isNaN(Number(value))
                ? ""
                : Math.max(1, Math.min(12, Number(value))) // Ensuring 1 ≤ value ≤ 12
              : value,
        };
      
        if (name === "projectType") {
          updatedData.category = value;
        }
      
        return updatedData;
      });
      
    }
  };
  
  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof FilesState,
  ) => {
    const filesArray = e.target.files ? Array.from(e.target.files) : [];

    if (field === "additionalImages" || field === "otherDocument") {
      if (field === "additionalImages" && files.additionalImages.length >= 5) {
        setFiles((prevFiles) => ({
          ...prevFiles,
          [field]: [...prevFiles[field].slice(0, 4), ...filesArray],
        }));
        return;
      }

      if (field === "otherDocument" && files.otherDocument.length >= 5) {
        setFiles((prevFiles) => ({
          ...prevFiles,
          [field]: [...prevFiles[field].slice(0, 4), ...filesArray],
        }));
        return;
      }

      // If less than 5 files, simply append new ones
      setFiles((prevFiles) => ({
        ...prevFiles,
        [field]: [...prevFiles[field], ...filesArray],
      }));
    } else {
      // Handle other fields (single file)
      setFiles((prevFiles) => ({
        ...prevFiles,
        [field]: filesArray[0] || null,
      }));
    }
  };

  const handleCurrenciesAcceptedChange = (currencyId: string) => {
    setFormData((prevFormData) => {
      const isSelected = prevFormData.currenciesAccepted.includes(currencyId);
      const updatedSelection = isSelected
        ? prevFormData.currenciesAccepted.filter((id) => id !== currencyId)
        : [...prevFormData.currenciesAccepted, currencyId];
      return { ...prevFormData, currenciesAccepted: updatedSelection };
    });
  };

  const handleSubmit = async (updatedformdata: FormDataInterface) => {
    try {

      updatedformdata.phoneNumber = getCountryCallingCode(countryCode) + updatedformdata.phoneNumber
      if(!updatedformdata.website.startsWith("https")){
      updatedformdata.website = "https://" + updatedformdata.website
      }

      const response = await listProject(updatedformdata);


      if (response && response.success) {
        setIsModalOpen(true);
        setStep(1);

        setFormData({
          phoneNumber: "",
          name: "",
          geographicMarket: "",
          postalCode: "",
          symbol: "",
          email: "",
          ticker: "111",
          category: "",
          description: "",
          otherDocument: [],
          images: [],
          requiredInvestment: 0,
          currenciesAccepted: [],
          projectPhases: [],
          auditReports: "",
          compliance: [],
          lockInPeriod: 0,
          maxSupply: 0,
          website: "",
          owner: "",
          burn: 0,
          minInvestment: 0,
          projectType: "",
          projectStage: "",
          balanceSheet: "",
          logo: "",
          investmentCutoffDuration:'' , 
          isProfitable:false ,
          donations:[
            

          ] ,
          impactData:[] ,
          roadmapImage:"" ,
          customProjectType:""
          
         
          
        });

        setFiles({
          projectLogo: null,
          balanceSheet: null,
          otherDocument: [],
          additionalImages: [],
        });
      } else {
        toast.error("Error Occured");
      }
    } catch (error) {
      toast.error("Error occurred");
    } finally {
      setLoader(false);
    }
  };

  const uploadFiles = async () => {

    if (!validateFormData()) {
      return;
    }
    try {
      setLoader(true);
      const optionalFields: (keyof FormDataInterface)[] = [
        "geographicMarket",
        "symbol",
        "otherDocument",
        "balanceSheet",
      ];
      
      // Create a copy of formData and remove empty optional fields
      let updatedFormData = { ...formData };
      
      optionalFields.forEach((field) => {
        if (!updatedFormData[field]) {
          delete updatedFormData[field]; // Remove empty optional fields
        }
      });    

      if (files.balanceSheet && formData.balanceSheet === "") {
        let formDataInstance = new FormData();
        formDataInstance.append("files", files.balanceSheet);

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          updatedFormData.balanceSheet = response.data.files[0];
       
        } catch (error) {
          console.error("Error uploading balance sheet:", error);
          setLoader(false);
          toast.error("Error Occured");

          throw error;
        }
      }

      if (files.projectLogo && formData.logo === "") {
        let formDataInstance = new FormData();
        formDataInstance.append("files", files.projectLogo);

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          updatedFormData.logo = response.data.files[0];
         
        } catch (error) {
          console.error("Error uploading project logo:", error);
          setLoader(false);
          toast.error("Error Occured");

          throw error;
        }
      }

      if (files.additionalImages.length > 0 && formData.images.length === 0) {
        let formDataInstance = new FormData();
        files.additionalImages.forEach((image) => {
          formDataInstance.append("files", image);
        });

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          updatedFormData.images = response.data.files;

        } catch (error) {
          setLoader(false);
          toast.error("Error Occured");
          throw error;
        }
      }

      if (
        files.otherDocument.length > 0 &&
        formData.otherDocument.length === 0
      ) {
        let formDataInstance = new FormData();
        files.otherDocument.forEach((doc) => {
          formDataInstance.append("files", doc);
        });

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          updatedFormData.otherDocument = response.data.files;

        } catch (error) {
          setLoader(false);
          console.error("Error uploading other documents:", error);
          toast.error("Error Occured");

          throw error;
        }
      }

 
      return handleSubmit(updatedFormData);
    } catch (error) {
      setLoader(false);
      throw error;
    }
  };

  const validateFormData = () => {
    const errors: { [key in keyof FormDataInterface]?: string } = {};
  
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^[0-9]+$/;
    const numberPattern = /^[0-9]+(\.[0-9]+)?$/;
  
    const notProfitableSkipFields: (keyof FormDataInterface)[] = [
      "requiredInvestment",
      "burn",
      "minInvestment",
      "lockInPeriod",
      "maxSupply",
      "currenciesAccepted",
      "investmentCutoffDuration",
    ];
  
    const optionalFields: (keyof FormDataInterface)[] = [
      "geographicMarket",
      "otherDocument",
      "balanceSheet",
    ];
  
    // Create a mutable copy of formData to modify
    const cleanedFormData = { ...formData };
  
    // Project type validation with field handling
    if (!formData.projectType || 
        (formData.projectType === "others" && !formData.customProjectType)) {
      
      // Set fields to undefined instead of deleting them
      if (!formData.projectType) {
        (cleanedFormData as any).projectType = undefined;
      }
      
      if (formData.projectType === "others" && !formData.customProjectType) {
        (cleanedFormData as any).customProjectType = undefined;
      }
      
      errors.projectType = "Project Type is required";
      toast.error(errors.projectType);
      return false;
    }
 
    formData.projectType ? formData.category = formData.projectType : formData.category = formData.customProjectType as string
  
    // Validate required fields
    for (const field in formData) {
      const key = field as keyof FormDataInterface;
  
      // Skip optional fields if they are missing or empty
      if (optionalFields.includes(key) && !formData[key]) {
        (cleanedFormData as any)[key] = undefined; // Set to undefined instead of delete
        continue;
      }
  
      // Skip fields if the project is not profitable
      if (!formData.isProfitable && notProfitableSkipFields.includes(key)) {
        continue;
      }
  
      // Skip specific non-mandatory fields
      if (
        [
          "auditReports",
          "balanceSheet",
          "ticker",
          "owner",
          "compliance",
          "projectPhases",
          "images",
          "otherDocuments",
          "logo",
        ].includes(key.toString())
      ) {
        continue;
      }
  
      // General required field validation
      if (
        formData[key] === "" ||
        formData[key] === null || 
        formData[key] === undefined
      ) {
        console.log(key + "hii");
        errors[key] = `${key} is required`;
        toast.error(errors[key] || 'Required Field');
        
        // Set to undefined instead of delete
        (cleanedFormData as any)[key] = undefined;
        
        return false;
      }
    }
  
    // Validate phone number
    if (formData.phoneNumber && !phonePattern.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number should contain only numbers";
      toast.error(errors.phoneNumber);
      (cleanedFormData as any).phoneNumber = undefined;
      return false;
    }
  
    // Validate postal code
    const validationResult = validate(countryCode, formData.postalCode);
    if (validationResult !== true) {
      errors.postalCode = "Invalid Postal Code";
      toast.error(errors.postalCode);
      (cleanedFormData as any).postalCode = undefined;
      return false;
    }
  
    // Validate email
    if (!emailPattern.test(formData.email)) {
      errors.email = "Enter a valid email address";
      toast.error(errors.email);
      (cleanedFormData as any).email = undefined;
      return false;
    }
  
    // Validate website URL
    if (!formData.website) {
      errors.website = "Please enter a website URL";
      toast.error(errors.website);
      (cleanedFormData as any).website = undefined;
      return false;
    }
  
    // Validate profitable project fields
    if (formData.isProfitable) {
      if (formData.burn && !numberPattern.test(formData.burn.toString())) {
        errors.burn = "Burn should be a valid number";
        toast.error(errors.burn);
        (cleanedFormData as any).burn = undefined;
        return false;
      }
  
      if (
        formData.minInvestment &&
        !numberPattern.test(formData.minInvestment.toString())
      ) {
        errors.minInvestment = "Minimum Investment should be a valid number";
        toast.error(errors.minInvestment);
        (cleanedFormData as any).minInvestment = undefined;
        return false;
      }
  
      if (
        formData.requiredInvestment &&
        !numberPattern.test(formData.requiredInvestment.toString())
      ) {
        errors.requiredInvestment = "Required Investment should be a valid number";
        toast.error(errors.requiredInvestment);
        (cleanedFormData as any).requiredInvestment = undefined;
        return false;
      }
  
      if (formData.lockInPeriod && !numberPattern.test(formData.lockInPeriod.toString())) {
        errors.lockInPeriod = "Enter a valid lock-in period";
        toast.error(errors.lockInPeriod);
        (cleanedFormData as any).lockInPeriod = undefined;
        return false;
      }
  
      if (formData.maxSupply && !numberPattern.test(formData.maxSupply.toString())) {
        errors.maxSupply = "Enter a valid token supply";
        toast.error(errors.maxSupply);
        (cleanedFormData as any).maxSupply = undefined;
        return false;
      }
    }
  
    // Return validation result and cleaned data
    console.log(Object.keys(errors).length);
    
    // Update the original formData with the cleaned values
    Object.assign(formData, cleanedFormData);
    
    return Object.keys(errors).length === 0 ? true : false;
  };
  

  const handleRoadmapImageChange = (e:any) => {
    const selectedId = e.target.value;
    setFormData({
      ...formData,
      roadmapImage: selectedId
    });
  };


  const Label: React.FC<{ text: string; tooltip: string }> = ({ text, tooltip }) => (
    <p className="mb-2 flex items-center">
      {text}
      <Tooltip content={tooltip} />
    </p>
  );

  const [newDonation, setNewDonation] = useState({
    donorName: '',
    amount: ''
  });


  

  const handleAddPhase = () => {
    if (newPhase.phaseName.trim() && newPhase.phaseDescription.trim()) {
      setFormData({
        ...formData,
        projectPhases: [...formData.projectPhases, newPhase],
      });
      setNewPhase({ phaseName: '', phaseDescription: '' , documentUrl:'' }); 
    }
  };

  const handleRemovePhase = (index: number) => {
    const updatedProjectPhases: ProjectPhase[] = [...formData.projectPhases];
    updatedProjectPhases.splice(index, 1);

    setFormData({
      ...formData,
      projectPhases: updatedProjectPhases,
    });
  };
   const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value  , name} = e.target;
        const sanitizedValue = value.replace(/[^\d+]/g, '');
        console.log(sanitizedValue)
       
        setFormData((prev) => ({ ...prev, phoneNumber: sanitizedValue }));
        
        
      };



      const handleAddDonation = () => {
        if (newDonation.donorName.trim() && newDonation.amount) {
          setFormData({
            ...formData,
            donations: [...(formData.donations || []), { ...newDonation }]
          });
          setNewDonation({
            donorName: '',
            amount: ''
          });
        }
      };
    
      const handleRemoveDonation = (index:any) => {
        const updatedDonations = [...(formData.donations || [])];
        updatedDonations.splice(index, 1);
        setFormData({
          ...formData,
          donations: updatedDonations
        });
      };
  


  const  renderForm = (step:number) : any  => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="grid grid-cols-12 gap-x-12 gap-y-4">
              <div className="col-span-6">
              <Label text="Project Name" tooltip={tooltips.name} />

                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Your Project Name"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
              <Label text="Project Symbol" tooltip={tooltips.symbol} />
                <input
                  type="text"
                  name="symbol"
                  value={formData.symbol}
                  onChange={handleChange}
                  placeholder="Enter Your Project Symbol"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
                <p className="mb-2">Email Address</p>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Your Email Address"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
  <p className="mb-2">Phone Number</p>
  <div className="flex items-center gap-2">
    <select
      value={countryCode}
      onChange={(e) => setCountryCode(e.target.value as CountryCode)}
      className="text-[14px] bg-[#eeeeee] rounded-[12px] px-3 py-[12px] border w-[6rem] truncate"
    >
      {countryOptions.map((option) => (
        <option key={option.code} value={option.code}>
          {option.label}{option.dialCode}
        </option>
      ))}
    </select>
    <input
      type="tel"
      name="phoneNumber"
      id="phoneNumber"
      value={formData.phoneNumber}
      onChange={handlePhoneChange}
      className="text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border flex-1 min-w-0"
      placeholder="Phone Number"
      required
    />
  </div>
</div>
              <div className="col-span-6">
                <p className="mb-2">Website URL</p>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="Enter Your Website URL"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
     

<div className="col-span-6">
                <p className="mb-2">Postal Code</p>
                <input
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange} 
                  placeholder="Enter Your Postal Code"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>


              <div className="col-span-12">
                <p className="mb-2">Project Description</p>
                <textarea
  name="description"
  value={formData.description}
  onChange={handleChange}  
  placeholder="Enter Your Project Description"
  className="block border p-2 mb-2 w-full bg-[#eeeeee] rounded-[12px] px-4 py-[12px]"
/>
<p className="text-gray-500 text-sm text-right">{formData.description.length} / 500</p> 

              </div>

              <div className="col-span-6">
            {/* <p className="mb-2">Project Profitable</p> */}
            <Label text="Project Investable" tooltip={tooltips.isProfitable}/>
            <select
              name="isProfitable"
              value={formData.isProfitable.toString()}
              onChange={handleChange}
              className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
            >
              <option value="">Select Profitability</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="col-span-6">
            <p className="mb-2">Investment Cutoff Duration(months)</p>
            {/* <Label text="Investment Cutoff Duration(months)" tooltip ={tooltips.investmentCutoffDuration}/> */}
            <input
              type="number"
              name="investmentCutoffDuration"
              value={String(formData.investmentCutoffDuration)}
              onChange={handleChange}
              placeholder="Maximum 12 months"
              className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
              disabled={!formData.isProfitable}
            />
          </div>
            </div>
          </> 
        );
      case 2:
          return (
        <>
          <div className="grid grid-cols-12 gap-x-12 gap-y-4">
            <div className="col-span-6">
          <p className="mb-2">Required Investment</p>
          <input
            type="text"
            name="requiredInvestment"
            value={formData.requiredInvestment}
            onChange={handleChange}
            placeholder="Enter Required Investment"
            className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
          />
            </div>

            <div className="col-span-6">
          <Label text="Percentage to Burn" tooltip={tooltips.percentageToBurn} />

          <input
            type="text"
            name="burn"
            value={formData.burn}
            onChange={handleChange}
            placeholder="Enter Percentage to Burn"
            className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
          />
            </div>

            <div className="col-span-6">
          <p className="mb-2">Min Investment</p>
          <input
            type="text"
            name="minInvestment"
            value={formData.minInvestment}
            onChange={handleChange}
            placeholder="Enter Minimum Investment"
            className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
          />
            </div>

            <div className="col-span-6">
            <Label text="Lock In Period" tooltip={tooltips.lockInPeriod} />
            <input
            type="text" 
            name="lockInPeriod"
            value={formData.lockInPeriod}
            onChange={handleChange}
            placeholder="Enter Lock In Period"
            className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
          />
            </div>

            <div className="col-span-6">
              <Label text="Max Token Supply" tooltip={tooltips.maxSupply}></Label>
          <input
            type="text"
            name="maxSupply"
            value={formData.maxSupply}
            onChange={handleChange}
            placeholder="Enter Max Token Supply"
            className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
          />
            </div>

            {/* Currencies Accepted Dropdown */}
            <div className="col-span-6 relative">
          <p className="mb-2 ">
            Currencies Accepted
          </p>

          {/* Selected Currencies Display */}
          <div
            onClick={toggleDropdown}
            className="block w-full text-sm bg-[#eeeeee] rounded-[12px] px-4 py-3 border cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#52bbab] focus:border-[#52bbab] relative"
          >
            <p className="text-[#827f7c] ">
              {formData?.currenciesAccepted?.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {formData.currenciesAccepted.map((currencyId) => {
                const currency = currencies.find(
              (curr) => curr._id === currencyId,
                );
                return (
              currency && (
                <span
                  key={currency._id}
                  className="bg-teal-500 text-white text-sm rounded-full px-2 py-1 flex items-center gap-1"
                >
                  {currency.symbol} {currency.name}
                  <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent dropdown from closing
                  handleCurrenciesAcceptedChange(currency._id);
                }}
                className="text-white text-xs bg-transparent border-none cursor-pointer"
                  >
                &times;
                  </button>
                </span>
              )
                );
              })}
            </div>
              ) : (
                <span className="flex gap-2 items-center text-gray-800">
              Select currencies <ChevronDown className={isDropdownOpen ? "rotate-180 animate-bounce" : "animate-bounce"} />
            </span>
              )}
            </p>
          </div>

          {/* Dropdown List */}
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              className="absolute left-0 mt-1 max-h-60 overflow-auto bg-white border rounded-[12px] shadow-lg z-10 w-full sm:w-[300px] max-w-full"
            >
              {currencies.map((currency) => (
            <div
              key={currency._id}
              onClick={() =>
                handleCurrenciesAcceptedChange(currency._id)
              }
              className={`p-2 cursor-pointer hover:bg-[#52bbab]/20 transition-all ${
                formData.currenciesAccepted.includes(currency._id)
              ? "bg-[#52bbab] text-white hover:text-gray-800"
              : "text-[#52504d]"
              }`}
            >
              {currency.name} ({currency.symbol})
            </div>
              ))}
            </div>
          )}
            </div>
          </div>
        </>
          );
      case 3:
        return (
          <>
            <div className="grid grid-cols-12 gap-x-12 gap-y-4">
            <div className="col-span-6">
  <p className="mb-2">Project Type</p>
  <div className="flex gap-x-4 items-start">
    <select
      name="projectType"
      value={formData.projectType || ""}
      onChange={handleChange}
      className="block text-[#827f7c] text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border focus:outline-none focus:ring-2 focus:ring-[#52bbab] focus:border-[#52bbab] w-full cursor-pointer"
    >
      <option value="" className="text-[#827f7c]">
        Select your project type
      </option>
     
      {categories.map((category) => (
        <option
          key={category._id}
          value={category._id}
          className={`text-[#827f7c] text-[14px] ${
            formData.category === category._id
              ? "bg-[#52bbab] text-white"
              : "hover:bg-[#52bbab]/20"
          }`}
        >
          {category.name}
        </option>
      ))}
       <option value="others" className="text-[#827f7c] text-[14px]">
        Others
      </option>
    </select>
    
    {formData.projectType === "others" && (
      <input
        type="text"
        name="customProjectType"
        value={formData.customProjectType || ""}
        onChange={handleChange}
        placeholder="Enter Project Type"
        className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border focus:outline-none focus:ring-2 focus:ring-[#52bbab] focus:border-[#52bbab] w-full"
      />
    )}
  </div>
</div>

{/* Additional input field that appears when "Other" is selected */}

              <div className="col-span-6">
                <p className="mb-2">Project Stage</p>
                <select
                  className="block text-[#827f7c] text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                  onChange={handleChange}
                  name="projectStage"
                  value={formData.projectStage}
                >
                  <option value="" className="text-[#827f7c] text-[14px]">
                    Select your Project Stage
                  </option>
                  <option
                    value="Initial"
                    className="text-[#827f7c] text-[14px]"
                  >
                    Initial
                  </option>
                  <option
                    value="Intermediate"
                    className="text-[#827f7c] text-[14px]"
                  >
                    Intermediate
                  </option>
                  <option value="Ready" className="text-[#827f7c] text-[14px]">
                    Ready
                  </option>
                </select>
              </div>
              <div className="col-span-6">
               <Label text="Geographic Market (Optional)" tooltip={tooltips.geoGraphicMarket} ></Label>
                <input
                  type="text"
                  name="geographicMarket" // Name should match the state property (geographicMarket)
                  placeholder="Enter Your Max Geographic Market"
                  onChange={handleChange}
                  className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                  value={formData.geographicMarket} // This binds the value to the geographicMarket property in the state
                />
              </div>

              <div className="col-span-6">
               

                 <Label text= "Project Logo/Icon" tooltip = {tooltips.logo}/>

                {/* Label for file input */}
                <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFileChange(e, "projectLogo")}
                  />

                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-[14px]">
                      {files.projectLogo ? (
                        <span>{files.projectLogo.name}</span> // Show selected file name
                      ) : (
                        "Upload your project logo"
                      )}
                    </p>

                    {/* Upload Icon */}
                    <img
                      className="w-[23px] h-[15px]"
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </label>

                {/* If a logo is selected, show the logo image */}
                {files.projectLogo && (
                  <div className="mt-4">
                    <img
                      src={URL.createObjectURL(files.projectLogo)} // Show the image
                      alt="Project Logo"
                      className="w-24 h-24 object-cover rounded-full" // Adjust size as needed
                    />
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Label text="Balance Sheet (Optional)" tooltip={tooltips.balanceSheet} />

                <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFileChange(e, "balanceSheet")}
                  />
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-[14px]">
                      {files.balanceSheet
                        ? files.balanceSheet?.name
                        : "Upload your balance sheet"}
                    </p>
                    <img
                      className="w-[16px] h-[16px]"
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </label>
              </div>

              <div className="col-span-6">
              <Label text= "Other Documents (Optional)" tooltip = {tooltips.otherDocuments}/>
              <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
                  <input
                    type="file"
                    className="hidden"
                    multiple
                    onChange={(e) => handleFileChange(e, "otherDocument")}
                  />
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-[14px]">
                      {files.otherDocument.length > 0
                        ? `${files.otherDocument.length} files selected`
                        : "Maximum 5 documents can be allowed"}
                    </p>
                    <img
                      className="w-[23px] h-[15px]"
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </label>
                {/* Display list of uploaded files */}
                <ul className="mt-2 space-y-1">
                  {files.otherDocument.map((file, index) => (
                    <li
                      key={index}
                      className="text-gray-600 text-[12px] truncate"
                    >
                      {file.name}
                    </li>
                  ))}
                </ul>
                {/* {files.otherDocument.length >= 5 && (
          <p className="text-red-500 text-xs mt-2">You can upload up to 5 documents only.</p>
        )} */}
              </div>

              <div className="col-span-12">
                {/* <p className="mb-2">Additional Images</p> */}
                <Label text= "Additional Images" tooltip = {tooltips.additionalImages}/>

                <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
                  <input
                    type="file"
                    className="hidden"
                    multiple
                    onChange={(e) => handleFileChange(e, "additionalImages")} 
                  />
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-[14px]">
                      {files.additionalImages.length > 0
                        ? `${files.additionalImages.length} files selected`
                        : "Maximum 5 images can be selected"}
                    </p>
                    <img
                      className="w-[23px] h-[15px]"
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </label>
                <ul className="mt-2 space-y-1">
                  {files.additionalImages.map((file, index) => (
                    <li
                      key={index}
                      className="text-gray-600 text-[12px] truncate"
                    >
                      {file.name}
                    </li>
                  ))}
                </ul>
                {files.additionalImages.length >= 5 && (
                  <p className="text-red-500 text-xs mt-2">
                    You can upload up to 5 images only.
                  </p>
                )}
              </div>

              <div className="col-span-12">
  <Label text="Select Roadmap Image" tooltip={tooltips.roadmapImage || "Choose a roadmap image for your project"} />
  <select
    name="roadmapImage"
    value={formData.roadmapImage || ""}
    onChange={handleRoadmapImageChange}
    className="block text-[#827f7c] text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border focus:outline-none focus:ring-2 focus:ring-[#52bbab] focus:border-[#52bbab] w-full cursor-pointer mb-4"
  >
    <option value="" className="text-[#827f7c]">
      Select a roadmap image
    </option>
    {images.map((image) => (
      <option
        key={image._id}
        value={image._id}
        className="text-[#827f7c] text-[14px]"
      >
        {image.name}
      </option>
    ))}
  </select>
  
  {/* Preview section for the selected roadmap image */}
  {formData.roadmapImage && (
    <div className="mt-2 mb-4">
      <p className="text-sm font-medium mb-2">Preview:</p>
      <div className="border rounded-lg p-2 bg-gray-50">
        <img
          src={images.find(img => img._id === formData.roadmapImage)?.imageUrl || '/placeholder-image.jpg'}
          alt="Roadmap Preview"
          className="max-w-full h-auto rounded-md"
          style={{ maxHeight: '200px', objectFit: 'contain' }}
        />
        <p className="text-xs text-gray-500 mt-1">
          {images.find(img => img._id === formData.roadmapImage)?.name}
        </p>
      </div>
    </div>
  )}
</div>
            </div>
          </>
        );
      case 4:
                  return  <ImpactView formData={formData} setFormData={setFormData}></ImpactView>

        case 5:
          return (
            <div className="space-y-6">
              {/* Input Form Section */}
              <div className="bg-gray-100 rounded-lg p-6 border">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">
                      <Label 
                        text="Donor Name" 
                        tooltip={tooltips.donorName}
                      />
                    </label>
                    <input
                      type="text"
                      value={newDonation.donorName}
                      onChange={(e) => {
                        if (e.target.value.length <= 50) {
                          setNewDonation({ ...newDonation, donorName: e.target.value });
                        }
                      }}
                      className="w-full px-4 py-2 rounded-lg border border-emerald-300  "
                      placeholder="Who made this donation?"
                    />
                    <p className="text-gray-500 text-xs mt-1 text-right">
                      {newDonation.donorName.length} / 50
                    </p>
                  </div>
        
                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">
                      Amount
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Euro className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="number"
                        value={newDonation.amount}
                        onChange={(e) => setNewDonation({ ...newDonation, amount: e.target.value })}
                        className="w-full pl-10 px-4 py-2 rounded-lg border border-emerald-300 "
                        placeholder="0.00"
                        min="0"
                        step="0.01"
                      />
                    </div>
                  </div>
        
                  <button
                    type="button"
                    onClick={handleAddDonation}
                    className="bg-emerald-500 text-white px-6 py-2 rounded-lg hover:bg-emerald-600 transition-colors"
                  >
                    Add Donation
                  </button>
                </div>
              </div>
        
              {/* Donations Display Section */}
              <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                  {(formData.donations || []).map((donation, idx) => (
                    <div className="flex-none w-80" key={idx}>
                      <div className="relative bg-gray-100 h-40 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                        <div className="flex items-start justify-between h-12 min-h-[3rem]">
                          <h3 className="flex-1 font-semibold text-emerald-600 text-lg break-words mr-2">
                            €{parseFloat(donation.amount).toFixed(2)}
                          </h3>
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={() => handleRemoveDonation(idx)}
                              className="text-gray-500 hover:text-gray-600"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
        
                        <div className="mt-2 flex-1">
                          <p className="text-gray-700 text-sm break-words">
                            {donation.donorName || "Anonymous"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Total Donations Summary */}
              {formData.donations && formData.donations.length > 0 && (
                <div className=" p-4 rounded-lg border ">
                  <div className="flex justify-between items-center">
                    <span className="font-semibold text-emerald-700">Total Donations:</span>
                    <span className="text-emerald-700 font-bold text-lg">
                      €{formData.donations.reduce((sum, donation) => sum + parseFloat(donation.amount || "0"), 0).toFixed(2)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
          case 6:

          return (
            <div className="space-y-6">
            {/* Input Form Section */}
            <div className="bg-gray-100 rounded-lg p-6 border">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                     Title
                  </label>
                  <input
                    type="text"
                    value={newPhase.phaseName}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setNewPhase({ ...newPhase, phaseName: e.target.value });
                      }
                    }}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    placeholder="Enter  title"
                  />
                  <p className="text-gray-500 text-xs mt-1 text-right">{newPhase.phaseName.length} / 50</p>
                </div>
        
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                     Description
                  </label>
                  <textarea
                    value={newPhase.phaseDescription}
                    onChange={(e) => {
                      if (e.target.value.length <= 200) {
                        setNewPhase({ ...newPhase, phaseDescription: e.target.value });
                      }
                    }}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    placeholder="Enter description"
                    rows={3}
                  />
                  <p className="text-gray-500 text-xs mt-1 text-right">{newPhase.phaseDescription.length} / 200 </p>
                </div>
        
                <div>

                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                  <Label text=" Document URL (Optional)" tooltip={tooltips.documentUrl}></Label>
                  </label>
                  <input
                    type="text"
                    value={newPhase.documentUrl}
                    onChange={(e) => setNewPhase({ ...newPhase, documentUrl: e.target.value })}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    placeholder="Enter document URL"
                  />
                </div>
        
                <button
                  type="button"
                  onClick={handleAddPhase}
                  className="bg-emerald-500 text-white px-6 py-2 rounded-lg hover:bg-emerald-600 transition-colors"
                >
                  Add Information
                </button>
              </div>
            </div>
        
            {/* Phases Display Section */}
            <div className="overflow-x-auto">
              <div className="flex space-x-4 pb-4">
                {formData.projectPhases.map((phase, idx) => (
                  <div className="flex-none w-80" key={idx}>
                    <div className="relative bg-gray-100 h-40 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                      <div className="flex items-start h-12 min-h-[3rem]">
                        <h3 className="flex-1 font-semibold text-emerald-600 text-sm break-words mr-2 line-clamp-2">
                          {phase.phaseName || "N/A"}
                        </h3>
                        <div className="flex items-center space-x-2"> {/* Container for icons */}
                          {phase.documentUrl && ( // Conditionally render the icon if URL exists
                            <a href={phase.documentUrl} target="_blank" rel="noopener noreferrer"> {/* Make it a link */}
                              <FileText className="w-4 h-4 text-gray-500 hover:text-gray-600" />
                            </a>
                          )}
                          <button
                            onClick={() => handleRemovePhase(idx)}
                            className="text-gray-500 hover:text-gray-600"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
        
                      <div className="mt-2 flex-1 overflow-y-auto">
                        <p className="text-gray-700 text-xs whitespace-pre-wrap break-words">
                          {phase.phaseDescription || "No description provided"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

 
          </div>
          )
        
      case 7:
        return (
          <div className="max-h-[640px] overflow-y-scroll project-list-scroll">
            <div className="mb-4">
              <h2 className="mb-2 text-[16px]">1. Project Details</h2>
              <div className="bg-[#fafafa] border rounded-[12px] p-4">
                <div className="grid grid-cols-12">
                  <div className="col-span-5">
                    <div className="flex flex-col">
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Project Name
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.name}
                        </p>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Email Address
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.email}
                        </p>
                      </div>
                      <div className="">
                        <h2 className="text-[#979797] text-[14px]">
                          Phone Number
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.phoneNumber ? "+" + getCountryCallingCode(countryCode) + " " + formData.phoneNumber :""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-7 pl-4">
                    <div className="mb-5 flex flex-row gap-[60px]">
                      <div>
                        <h2 className="text-[#979797] text-[14px]">
                          Project Symbol
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.symbol}
                        </p>
                      </div>
                      <div>
                        <h2 className="text-[#979797] text-[14px]">
                          Postal Code
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.postalCode}
                        </p>
                      </div>
                    </div>
                    <div className="mb-5">
                      <h2 className="text-[#979797] text-[14px]">
                        Website Url
                      </h2>
                      <p className="mt-1 text-[14px] truncate text-[#52bbab]">
                        <a
                          href={formData.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[#52bbab] hover:underline"
                        >
                          {formData.website}
                        </a>
                      </p>
                    </div>
                    <div className="">
                      <h2 className="text-[#979797] text-[14px]">
                        Project Description
                      </h2>
                      <p className="mt-1 text-[14px] truncate">
                        {formData.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            { formData.isProfitable && <div className="mb-4">
              <h2 className="mb-2 text-[16px]">2. Financial Details</h2>
              <div className="bg-[#fafafa] border rounded-[12px] p-4">
                <div className="grid grid-cols-12">
                  <div className="col-span-5">
                    <div className="flex flex-col">
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Required Investment
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.requiredInvestment}
                        </p>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Percentage To Burn
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.burn}
                        </p>
                      </div>
                      <div className="">
                        <h2 className="text-[#979797] text-[14px]">
                          Min Investment
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.minInvestment}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-7 pl-4">
                    <div className="mb-5 flex flex-row gap-[60px]">
                      <div>
                        <h2 className="text-[#979797] text-[14px]">
                          Lock In Period
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.lockInPeriod}
                        </p>
                      </div>
                      <div>
                        <h2 className="text-[#979797] text-[14px]">
                          Max Token Supply
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.maxSupply}
                        </p>
                      </div>
                    </div>
                    <div className="mb-5">
                      <h2 className="text-[#979797] text-[14px]">
                        Currencies Accepted
                      </h2>
                      <ul className="mt-1 text-[14px] text-[#52bbab]">
                        {selectedCurrencies.length > 0 ? (
                          selectedCurrencies.map((currency) => (
                            <li key={currency._id}>{currency.name}</li> // Display the name of the selected currencies
                          ))
                        ) : (
                          <li>No currencies selected</li> // Fallback message when no currency is selected
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    } 

            <div className="mb-4">
              <h2 className="mb-2 text-[16px]">{formData.isProfitable ? "3 ." :"2 ."} Visual & Market Details</h2>
              <div className="bg-[#fafafa] border rounded-[12px] p-4">
                <div className="grid grid-cols-12">
                  <div className="col-span-5">
                    <div className="flex flex-col">
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Project Type{" "}
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {getProjectType(formData?.projectType)?.name}
                        </p>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Project Stage
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.projectStage}
                        </p>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Geographic Market
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {formData.geographicMarket}
                        </p>
                      </div>
                      <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">
                          Project Icon
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {files?.projectLogo?.name}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-7 pl-4">
                    <div className="mb-5 flex flex-row gap-[60px]">
                      <div>
                        <h2 className="text-[#979797] text-[14px]">
                          Balance Sheet
                        </h2>
                        <p className="mt-1 text-[14px] truncate">
                          {files.balanceSheet?.name}
                        </p>
                      </div>
                    </div>
                    <div className="mb-5">
                      <h2 className="text-[#979797] text-[14px]">
                        Other Documents
                      </h2>
                      <p className="mt-1 text-[14px] truncate">
                        {files?.otherDocument?.length + " file selected"}
                      </p>
                    </div>
                    <div className="mb-5">
                      <h2 className="text-[#979797] text-[14px]">
                        Additional Images{" "}
                      </h2>
                      <p className="mt-1 text-[14px] truncate">
                        {files?.additionalImages?.length + " file selected"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          {formData.isProfitable &&  <div className="mb-4">
      <h2 className="mb-2 text-[16px]">{formData.isProfitable ? "4. " : "3. "} Donations Received </h2>
      <div className="bg-[#fafafa] border rounded-[12px] p-4">
      <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                  {(formData.donations || []).map((donation, idx) => (
                    <div className="flex-none w-80" key={idx}>
                      <div className="relative bg-gray-100 h-28 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                        <div className="flex items-start justify-between h-12 min-h-[3rem]">
                          <h3 className="flex-1 font-semibold text-emerald-600 text-lg break-words mr-2">
                            €{parseFloat(donation.amount).toFixed(2)}
                          </h3>
                          <div className="flex items-center space-x-2">
                          
                          </div>
                        </div>
        
                        <div className="mt-2 flex-1">
                          <p className="text-gray-700 text-sm break-words">
                            {donation.donorName || "Anonymous"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
                </div>
              </div>

        </div>



    </div>}




            <div className="mb-4">
      <h2 className="mb-2 text-[16px]">{formData.isProfitable ? "5. " : "3. "} Impact View </h2>

      <div className="bg-[#fafafa] border rounded-[12px] p-4">
      <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                {formData.impactData && formData.impactData.length > 0 && (
        <div>
          <h3 className="text-lg font-medium text-gray-700 mb-3">Added Impact Items</h3>
          <div className="flex  pb-3">
            {formData.impactData.map((item, idx) => {
              const impactType = impactTypes.find(type => type.id === item.icon);
              return (
                <div key={idx} className="cursor-pointer flex-shrink-0 flex flex-col items-center justify-center p-2 rounded-lg border mx-1 min-w-[200px] gap-x-4 h-24">
                  <div className="absolute top-2 right-2">
                   
                  </div>
                  
                  <div className="flex items-center">
                    <div className="mr-3">
                      {impactType?.icon}
                    </div>
                    <div>
                      <div className="text-lg font-medium">
                        {item.value}{item.unit}
                      </div>
                      <div className="text-sm text-gray-600">
                        {impactType?.label}
                      </div>
                      <div className="mt-2">
                        <span className={`text-xs px-2 py-1 rounded-full ${
                          item.type === 'expected' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                        }`}>
                          {item.type === 'expected' ? 'Expected' : 'Current'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
                </div>
              </div>
                </div>
              </div>
              </div>
    </div>



            <div className="mb-4">
      <h2 className="mb-2 text-[16px]">{formData.isProfitable ? "6. " : "4. "} Additional Information</h2>
      <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                {formData.projectPhases.map((phase, idx) => (
                  <div className="flex-none w-80" key={idx}>
                    <div className="relative bg-gray-100 h-40 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                      <div className="flex items-start h-12 min-h-[3rem]">
                        <h3 className="flex-1 font-semibold text-emerald-600 text-sm break-words mr-2 line-clamp-2">
                          {phase.phaseName || "N/A"}
                        </h3>
                        <div className="flex items-center space-x-2"> 
                          {phase.documentUrl && (
                            <a href={phase.documentUrl} target="_blank" rel="noopener noreferrer"> 
                              <FileText className="w-4 h-4 text-emerald-600 hover:text-emerald-800" />
                            </a>
                          )}
                          
                        </div>
                      </div>
        
                      <div className="mt-2 flex-1 overflow-y-auto">
                        <p className="text-gray-700 text-xs whitespace-pre-wrap break-words">
                          {phase.phaseDescription || "No description provided"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                </div>
              </div>
    </div>


    


          </div>
        );
      default:
        return null;
    }
  };

  interface TooltipProps {
    content: string;
  }



  const Tooltip: React.FC<{ content: string }> = ({ content }) => (
    <div className="group relative inline-flex items-center ml-2">
      <Info className="w-4 h-4 text-black  transition-colors cursor-help" />
      <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 absolute z-50 w-64 p-3 text-sm bg-black text-gray-100 rounded-lg shadow-lg -left-20 top-8">
        <div className="absolute w-3 h-3 bg-gray-900 transform rotate-45 -top-1.5 left-1/2 -ml-1.5"></div>
        <div className="relative">{content}</div>
      </div>
    </div>
  );
  
  const tooltips = {
    name: "The official name of your project that will be displayed to potential investors",
    symbol: "A unique identifier or ticker symbol for your project",
    description: "Comprehensive overview of your project, its goals, and unique value proposition",
    isProfitable: "Depicting whether your project will be open for investment to the investors.",
    investmentCutoffDuration: "Time period (in months) after which no new investments will be accepted (max:12 months)" ,
    geoGraphicMarket: "The defined territories where your project will concentrate its resources and deliverables to maximize impact and achieve strategic objectives" , 
    logo:"Upload an image representing your project's motive (max size: 5MB)" ,
    otherDocuments:"Upload images and PDF documents that represent your project information (max size: 5MB each)" ,
    additionalImages:"Upload up to 5 images showcasing your project(max size: 5MB per image)."  ,
    lockInPeriod:"Duration for which certain tokens (e.g., team, investors) remain restricted from trading" ,
    maxSupply:"Total number of tokens that will ever exist" ,
    percentageToBurn:"Portion of the max supply to be permanently removed to reduce circulation." ,
    balanceSheet:"Upload a PDF document that represents your project's financial health (max size: 5MB)" ,
    documentUrl:'Provide a URL to a document that provides more information about the phase' ,
    donationDescription:"Provide Information About The provider of the donation" ,
    donorName: "Enter the name of the person or organization making the donation"  ,
    roadmapImage:"Select an image which will be used as background in Roadmap view of withdrawals"

    
  }


  return (
    <>
      <div className="p-6">
        <h1 className="text-[20px] font-semibold mb-3">Add Project</h1>
        <p className="text-[#a4a4a4]">
        Create your project proposal here. Upon submission, it will be sent to the administrator for review and approval.  Please provide detailed information about your project. All fields are required.
        </p>
        <div className="p-6 bg-[#fff] rounded-[16px] mt-4">
  <div className="grid grid-cols-12 gap-8">
    <div className="col-span-4">
      <div className="bg-[#fafafa] p-4 rounded-[16px] border h-full">
        <h3 className="font-semibold mb-4">Create Project</h3>
        <ul className="flex flex-col justify-between gap-10">
          {[
            "Project Details",
            ...(formData.isProfitable ? ["Financial Details"] : []),
            "Visual & Market Details",
            "Impact Details",
            ...(formData.isProfitable ? ["Donations Received"] : []),
            "Additional Information",
            "Submitted",
          ].map((label, index) => {
            // Map each label to its corresponding step number in the workflow
            let stepNumber;
            
            if (formData.isProfitable) {
              // For profitable projects - all steps are sequential
              stepNumber = index + 1;
            } else {
              // For non-profitable projects - we need to map to actual step numbers
              if (label === "Project Details") stepNumber = 1;
              else if (label === "Visual & Market Details") stepNumber = 3;
              else if (label === "Impact Details") stepNumber = 4;
              else if (label === "Additional Information") stepNumber = 6;
              else if (label === "Submitted") stepNumber = 7;
              else stepNumber = index + 1; // Fallback
            }
            
            // Get the display number for the step (what shows in the circle)
            let displayNumber;
            if (formData.isProfitable) {
              displayNumber = index + 1;
            } else {
              if (label === "Project Details") displayNumber = 1;
              else if (label === "Visual & Market Details") displayNumber = 2;
              else if (label === "Impact Details") displayNumber = 3;
              else if (label === "Additional Information") displayNumber = 4;
              else if (label === "Submitted") displayNumber = 5;
              else displayNumber = index + 1;
            }
            
            // Check if this step is completed or active based on the current step
            const isCompleted = step > stepNumber;
            const isCurrent = step === stepNumber;
            const isActive = isCompleted || isCurrent;
            
            return (
              <li key={index}>
                <button
                  className={`w-full flex items-center text-left p-2 rounded-lg`}
                >
                  {isCompleted ? (
                    <span
                      className="mr-2 bg-[#52bbab] text-[#fff] flex items-center justify-center rounded-full h-[30px] w-[30px]"
                    >
                      ✔
                    </span>
                  ) : (
                    <span
                      className={`mr-2 ${
                        isActive
                          ? "bg-[#52bbab] text-[#fff] flex items-center justify-center rounded-full h-[30px] w-[30px]"
                          : "bg-[#a4a4a4] text-[#cfcfcf] flex items-center justify-center rounded-full h-[30px] w-[30px]"
                      }`}
                    >
                      {displayNumber}
                    </span>
                  )}
                  <p
                    className={`${
                      isActive
                        ? "text-[#52bbab]"
                        : "text-[#a4a4a4]"
                    }`}
                  >
                    {label}
                  </p>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
    <div className="col-span-8">
      {renderForm(step)}
      <div className="flex gap-4 mt-4">
        {step > 1 && (
          <button
            onClick={handleBack}
            className="bg-[#fafafa] border text-black rounded-[8px] px-3 py-[7px] flex items-center gap-2"
            disabled={loader}
          >
            <img className="w-[5px] h-[11px] " src={BackIcon} alt="" />
            <p>Back</p>
          </button>
        )}
        <button
          onClick={handleNext}
          className="bg-[#52bbab] text-white text-center rounded-[8px] py-[7px] px-6 w-auto"
          disabled={loader}
        >
          {loader ? "Submitting.." : step === 7  ? "Submit" : "Next"}
        </button>
        {isModalOpen && <Successfully closeModal={closeModal} />}
      </div>
    </div>
  </div>
</div>
      </div>
    </>
  );
};

export default MultiStepForm;