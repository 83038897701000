import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  projectLinksArray,
  investorLinksArray,
  StaticLinksArray,
  UNAUTHORIZED_InvestorLinksArray,
  UNAUTHORIZED_StaticLinksArray,
} from "./MockSidebar";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  setLogOutModal: (arg: boolean) => void;
}

const allowedPageWhenNotLoggedIn = ["", "/", "/help"];

const Sidebar = ({ setLogOutModal }: SidebarProps) => {
  const isLoggedIn = useSelector((store: any) => store?.user?.isLoggedIn);
  const activeView = useSelector((store:any)=>store.toggle.activeView);
  console.log(activeView)
  const [isExpanded, setIsExpanded] = useState(false);
  const path = window.location.pathname;

  const mainInvestorLinks = isLoggedIn ? investorLinksArray : UNAUTHORIZED_InvestorLinksArray;
  const mainStaticLinks = isLoggedIn ? StaticLinksArray : UNAUTHORIZED_StaticLinksArray;

  useEffect(() => {
    localStorage.setItem("activeSidebar", activeView);
  }, [activeView]);

  const clickHandler = (name: string) => {
    if (name.toLocaleLowerCase() === "logout") {
      setLogOutModal(true);
    }
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const renderLinks = (linksArray: any[]) => (
    <>
      {linksArray.map((linkItem, index) => {
        const isAllowed = allowedPageWhenNotLoggedIn.includes(linkItem.link);
        const isLogout = linkItem.name.toLowerCase() === "logout"; // Check if it's logout

        return (
          <NavLink
            key={index}
            to={isAllowed || isLoggedIn ? linkItem.link : "#"}
            className={({ isActive }) =>
              `flex items-center gap-4 px-4 py-3 rounded-lg transition-all 
              ${isLogout ? "hover:bg-[#4aa39a]" : linkItem.link===path ? "bg-[#5dcbba]" : "hover:bg-[#4aa39a]"}`
            }
            onClick={(e) => {
              if (!isAllowed && !isLoggedIn) {
                e.preventDefault();
                toast.error("Please login first.");
              } else {
                clickHandler(linkItem.name);
                e.stopPropagation();
              }
            }}
          >
            {/* SVG icon (always visible) */}
            <div className="w-8 h-8 flex justify-center items-center">{linkItem.img}</div>

            {/* Text (only visible when expanded) */}
            <span className={`text-white text-[15px] transition-opacity ${isExpanded ? "opacity-100" : "opacity-0 hidden"}`}>
              {linkItem.name}
            </span>
          </NavLink>
        );
      })}
    </>
  );




  return (
    <section
      className={`w-full menu-section sticky top-[20px] bg-[#52bbab] fit-to-screen px-4 overflow-hidden rounded-[20px] ${scrollPosition >= 10 ? "fulled-screen" : ""
        }`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <nav className={`main-menu flex flex-col justify-between py-4 fit-to-screen ${scrollPosition >= 10 ? "fulled-screen" : ""}`}>
        <ul>{activeView === "investor" && isLoggedIn ? renderLinks(mainInvestorLinks) : renderLinks(projectLinksArray)}</ul>
        <ul className="mt-6">{renderLinks(mainStaticLinks)}</ul>
      </nav>
    </section>
  );
};

export default Sidebar;
