/**
import { googleAuthValidator } from './../../../e4rth-platform-db/app/validators/auth.validators';
 * API paths for various CRUD operations
 */
export const apiPaths: { [key: string]: string } = {

  //when not logged in 
  allProjects: "api/v1/projects/all-projects",
  getValidProjectById: "api/v1/projects/valid-project",

  //Auth API list
  Login: "login",
  Signup: "signup",
  ForgotPassword: "forgot-password",
  VerifyOtp: "verify-otp",
  CreateProject: "api/v1/projects",
  ResendOtp: "resend-otp",
  Profile: "api/v1/profile",
  ListProject: "api/v1/projects",
  getProjects: "api/v1/projects",
  refreshToken: "refresh-token",
  checkTokens: "check-tokens",
  sendOtp: "send-otp",
  SetPassword: "set-password",
  changePassword: "change-password",

  //donations
  addDonation : "api/v1/projects/add-donation",

  getProjectsName: "api/v1/projects/getprojectnames",
  getActiveInvestors: "api/v1/investors/active",
  getInvestorTransactions: "api/v1/transactions/investor",
  getProjectManagerTransactions: "api/v1/transactions/project-manager",
  submitWithdrawal: "api/v1/withdrawal/create-withdrawal",
  getWithdrawals: "api/v1/withdrawal/get-withdrawal",
  getProjectWithdrawals:"api/v1/withdrawal" ,

  getWithdrawalCategories: "api/v1/withdrawal/get-withdrawal-category",
  createWithdrawalCategories: "api/v1/withdrawal/create-withdrawal-category",
  createWithdrawalSubCategories: "api/v1/withdrawal/create-withdrawal-sub-category",

  getInvestedProjects :"api/v1/projects/invested-projects",

  subscribeNotification: "api/v1/subscribe-notifications",

  timeZonePreferences: "api/v1/profile/preferences",

  getConversion: "api/v1/conversion/get-conversions",

  wallets: "api/v1/wallets",
  imageUpload: "api/v1/uplode",
  becomeProjectManager: "api/v1/project-managers/user/application",

  Logout: "logout",

  paymentIntent: "api/v1/stripe/payment-intent",

  GoogleAuth: "google-auth-validator",
  getInvestMentDetails:"api/v1/transactions/investment-details" ,

  //ledger

  getWithdrawalDocumentation: "api/v1/project-manager/withdrawal",
  deployOnBlockChain: "api/v1/factory/deploy-project-manager",
  confirmDeploy: "api/v1/confirm/deploy",

  getStripeSessionId :"api/v1/stripe/create-financial-session" ,
  extendTimeline:"api/v1/investment-cutoff",

  projectOptions : "api/v1/projects/options" ,
  getConversionRates:"api/v1/conversion" ,


  //
  updateProject:"api/v1/projects/update-impact" ,
  updateProjectPhases:"api/v1/projects/update-phase-information" ,






  //balance sheet
  getProjectInvestments:'api/v1/projects/project-investments' ,
  getProjectDonations:'api/v1/projects/project-donations'
};
