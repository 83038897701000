import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "./ProgressBar";
import downloadImage from "../../../images/icons/download.png";
import RightTick from "../../../images/icons/tick.png";
import { useNavigate, useParams } from "react-router-dom";
import { useExplore } from "../useExplore";
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import toast from "react-hot-toast";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { Loader,Factory, AlarmSmoke, Leaf, X, FileText } from "lucide-react";
import { useManageWallet } from "./../../Settings/Components/useManageWallet";
import { Wallet } from "./../../Settings/Components/Wallet.prop";
import ViewDocumentation, { DataItem } from "./ViewDocumentation"; 
import logo from "../../../images/logo/fulllogo.png"
import ImageCarouselModal from "./ImageCarouselModal";
import formatNumberEuro from "../../../util/FormatNumberEuro";
import ExchangeRatesTable from "./ExchangeRatesTable";
import BalanceSheet from "./BalanceSheet";
import ImpactView from "./ImpactView";
import { ImpactItem } from "./ImpactView";
import { IConversion } from "../Explore.props";
import { ArrowLeft } from 'lucide-react';


declare global {
  interface Window { 
    ethereum: any;
    reload: () => void;
  }
}

const ExploreDetails = ({ investor = false }) => {
  const isLoggedIn = useSelector((store: any) => store?.user?.isLoggedIn);
  const { id } = useParams<{ id: string }>();
  const { getProjectDetailsById  , fetchWithdrawalDocumentation , fetchBalanceSheetDetails} = useExplore();
  const [projectData, setProjectData] = useState<ProjectResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(projectData?.minInvestment);
  const [tokens, setTokens] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showDocModal, setShowDocModal] = useState(false); 
  const [selectedCurrency, setSelectedCurrency] = useState("EURO");
  const user = useSelector((store: any) => store.user.user);
  const navigate = useNavigate();
  const [isDeploying, setIsDeploying] = useState(false); 
  const [deploymentStep, setDeploymentStep] = useState("");
  const { getWallets } = useManageWallet();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [showExtendTimelineModal, setShowExtendTimelineModal] = useState(false);
  const [duration, setDuration] = useState(1);
  const [description, setDescription] = useState("");
  const [showImageModal,setShowImageModal] = useState(false)
  const [selectedImageArray,setSelectedImageArray] = useState<string[]>([""])
  const [currentView, setCurrentView] = useState("tableview");
  const [index , setIndex] = useState<number>(-1) 
  const [currencyRates , setCurrencyRates] = useState<IConversion[]>([])  
    const [withdrawals, setWithdrawals] = useState<DataItem[]>([]);
    const activeView = useSelector((store:any)=>store.toggle.activeView);

  
  



  useEffect(() => {
    const fetchWallets = async () => {
      const walletsData: Wallet[] | null = await getWallets();
      console.log(walletsData);
      if (walletsData) {
        setWallets(walletsData);
      }
    };
    fetchWallets();
  }, []);


  useEffect(()=>{
    

    async function fetchCurrencyRates(){
      try{

        const data = await ApiService({
          method:"GET" ,
          endpoint:apiPaths.getConversionRates ,
        })

        console.log(data)
        setCurrencyRates(data.response)

      }catch(err){
        console.log(err)

      }
    }

    fetchCurrencyRates()


  } ,[])

  useEffect(()=>{

    async function fetchWithdrawals(_id:string){
      const response = await ApiService({
        method: "GET",
        endpoint: `${apiPaths.getProjectWithdrawals}/${_id}`,
       
      });

      const formattedResponse = response.response.map((item:any) => ({
        ...item,
        subcategory: item.subCategory,
        timestamp: Math.floor(new Date(item.date).getTime() / 1000), // Convert date to Unix timestamp
      }));
      
      setWithdrawals(formattedResponse);
    
   }

   if(projectData && projectData?.contractAddress){
    fetchWithdrawals(projectData?._id)
   }

  } ,[projectData])



 



  

  const tokenPrice = 10; // Assume 1 token = $10 (or change as needed)
  interface RawTransaction {
    to: string;
    data: string;
    gas: string;
    gasPrice: string;
    value: string;
    privateKey: string; // Only if backend returns a private key for signing
  }

  const handleInvestClick = () => {
    if(!isLoggedIn){
      toast.error("Please login first to become an investor.")
      return
    }
    if(wallets.length === 0) {
      toast.error("Please Add a Wallet To Continue");
      return
    }


    
    const connectedWallet = wallets.filter((wallet) => wallet.isConnected);

    if (connectedWallet.length === 0) {
      toast.error("Please Connect Your Wallet Continue");
      return;
    }
    
    setShowModal(true); // Open modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close modal
    setAmount(0);
    setTokens(0);
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCurrency(e.target.value);
  };

  async function getProjectDetails() {
    const response = await getProjectDetailsById(String(id));
    if (response) {
      setProjectData(response);
    } else {
      toast.error("Error fetching project details");
      console.error("Error fetching details");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getProjectDetails();
  }, [id]);

  if (!projectData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src="loading_icon.gif" alt='logo' className="" />
      </div>
    );
  }

  const dummyCompliance = [
    "Clear Terms and conditions for investor",
    "Compliance with local and international environmental laws",
    "Adherence to blockchain and cryptocurrency regulations",
  ];

  function projectPerforManceCalculator(investmentRaised: any, requiredInvestment: any): number {
    const raised = Number(investmentRaised);
    const required = Number(requiredInvestment);

    console.log(raised, required);

    if (!required || isNaN(raised) || isNaN(required)) {
      return 0;
    }

    const result = (raised / required) * 100;
    return isNaN(result) ? 0 : result>100 ? 100 : result; 
  }

  async function deployHandler(): Promise<void> {
    console.log(wallets);

    if (wallets.length === 0) {
      toast.error("Please Add a Wallet To Continue");
      return;
    }

    const connectedWallet = wallets.filter((wallet) => wallet.isConnected);

    if (connectedWallet.length === 0) {
      toast.error("Please Connect Your Wallet Continue");
      return;
    }

    setIsDeploying(true);
    setDeploymentStep("Preparing deployment data...");

    try {
      const deploymentData = {
        name: projectData?.name,
        symbol: projectData?.symbol,
        uri: projectData?.website,
        maxSupply: projectData?.maxSupply,
        initialOwner: connectedWallet[0].walletAddress,
        walletAddress: connectedWallet[0].walletAddress,
        lockInPeriod: projectData?.lockInPeriod,
      };

      console.log(deploymentData);

      setDeploymentStep("Requesting transaction data...");
      let rawTransaction: any = await ApiService({
        method: "POST",
        endpoint: apiPaths.deployOnBlockChain,
        ledgerApi: true,
        data: deploymentData,
      });

      rawTransaction = rawTransaction.response;

      if (!window.ethereum) {
        throw new Error("MetaMask is not installed");
      }
      console.log(rawTransaction);

      setDeploymentStep("Connecting to MetaMask...");
      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      console.log(accounts);
      const senderAddress = accounts[0];

      setDeploymentStep("Waiting for transaction confirmation...");
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [rawTransaction],
      });
      console.log(txHash);

      setDeploymentStep("Confirming deployment...");
      const confirmationResponse = await ApiService({
        method: "POST",
        endpoint: apiPaths.confirmDeploy,
        ledgerApi: true,
        data: {
          transactionHash: txHash,
          projectId: id,
          walletAddress: senderAddress,
        },
      });

      console.log(confirmationResponse);

      if (confirmationResponse.success) {
        toast.success("Project Sent For Deployment");
      } else {
        toast.error("Please Try Again");
      }
    } catch (err) {
      console.error("Error in deployment flow:", err);
      toast.error("Deployment failed. Please try again.");
    } finally {
      setIsDeploying(false);
      setDeploymentStep("");
    }
  }

  const handlTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newTokenValue = parseFloat(e.target.value);
    console.log(newTokenValue);
    if (newTokenValue < 1) {
      newTokenValue = 1;
    }
    setTokens(newTokenValue);
    setAmount(newTokenValue * projectData.minInvestment);
  };

  if (projectData.compliance && projectData.compliance?.length <= 1) {
    setProjectData({ ...projectData, compliance: dummyCompliance });
  }

   const handleExtendTimeline = async () => {
    try {
      const extensionData = {
        duration,
        description,
      };

      const response = await ApiService({
        method: "POST",
        endpoint: `${apiPaths.extendTimeline}/${projectData?._id}`,
        data: extensionData,
      });

      if (response.success) {
        toast.success("Timeline extended successfully");
        setShowExtendTimelineModal(false);
        getProjectDetails();
      } else {
        toast.error("Failed to extend timeline");
      }
    } catch (error) {
      console.error("Error extending timeline:", error);
      toast.error("An error occurred while extending the timeline");
    }
  };

  const getCurrentView = () => {
    switch (currentView) {
      case "tableview":
        return <ViewDocumentation project={projectData} expandedWithdrawalIndexes={index>=0 ? [index]:[] } currencyRates={currencyRates} withdrawals={withdrawals} contractAddress={projectData?.contractAddress || ""} />;
      case "balancesheet":
        return <BalanceSheet setCurrentView={setCurrentView} setIndex={setIndex}  withdrawals={withdrawals} projectId={projectData._id}/>;
      default:
        return <ViewDocumentation project={projectData} expandedWithdrawalIndexes={[0 , 1]} currencyRates={currencyRates} withdrawals={withdrawals} contractAddress={projectData?.contractAddress || ""}/>;
    }
  }



  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src="loading_icon.gif" alt='logo' className="" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full bg-white rounded-2xl p-6">
      <div className="flex flex-col flex-1">
        {/* Main Content Grid */}
        <div className="grid grid-cols-2 gap-6">
          {/* Left Column */}
          <div className="flex flex-col">
            {/* Header Section */}

<div className="flex justify-between items-center">
  <div className="flex flex-col">   
    <div className="flex items-center">
      <ArrowLeft 
        className="mr-2 cursor-pointer" 
        size={20} 
        onClick={() => {
             navigate(activeView === "investor" ? "/projectoverview" : "/");
         
        }}
      />
      <img
        src={projectData?.logo}
        onClick={() => {
          setSelectedImageArray([projectData?.logo])
          setShowImageModal(true)
        }}
        className="h-10 w-10 rounded-full mr-3" 
      />
      <h1 className="text-2xl font-semibold">{projectData?.name}</h1>
    </div>
    <h2 className="text-base my-3">{projectData.category?.name}</h2>
  </div>
</div>

            {/* Action Buttons */}
            <div className="flex items-center gap-2 mb-5">
                {projectData?.userId === user?._id && (() => {
                  if (projectData.investmentCutoffStatus === "active") {
                    if (projectData.approvalStatus === "approved") {
                      return (
                        <button
                          className="text-xs py-2 px-3 bg-[#52bbab] text-black rounded-full hover:bg-[#429a91]"
                          onClick={deployHandler}
                        >
                          Deploy on Blockchain
                        </button>
                      );
                    }
                    return null;
                  }

                  switch (projectData.investmentCutoffStatus) {
                    case "extensionWindow":
                      return (
                        <button
                          className="text-xs py-2 px-3 bg-[#52bbab] text-black rounded-full hover:bg-[#429a91]"
                          onClick={() => setShowExtendTimelineModal(true)}
                        >
                          Extend Cutoff
                        </button>
                      );
                    case "extensionRequested":
                      return (
                        <button
                          className="text-xs py-2 px-3 bg-[#52bbab] text-black rounded-full opacity-50 cursor-not-allowed"
                          disabled
                        >
                          Extension Requested
                        </button>
                      );
                    case "expired":
                      return (
                        <button
                          className=" text-xs py-2 px-3 bg-[#ff4d4d] text-white rounded-full cursor-not-allowed"
                          disabled
                        >
                          Expired
                        </button>
                      );
                    default:
                      return null;
                  }
                })()}

<button
onClick={() => navigate(
  `/documentation-roadmap/${projectData?.contractAddress}`, 
  { 
    state: { 
      projectId:projectData?._id,
      roadmapImage: projectData?.roadmapImage,
      projectName:projectData?.name ,
      investmentCutoffDate:projectData?.investmentCutoffDate
      
     
    } 
  }
)}      
className="text-[12px] py-2 px-3 bg-[#eef9f7] text-black border border-[#52bbab] rounded-full hover:bg-[#52bbab] hover:text-white transition-all duration-300"
>
          Roadmap View
        </button>

                {projectData.approvalStatus === "deployed" && (
                  <button
                    onClick={() => {
                      window.open(
                        `https://testnet.snowtrace.io/address/${projectData?.contractAddress}/contract/43113/readContract?chainid=43113`,
                        "_blank"
                      );
                    }}
                    className="text-[12px] py-2 px-3 bg-[#eef9f7] text-black border border-[#52bbab] rounded-full hover:bg-[#52bbab] hover:text-white transition-all duration-300"
                  >
                    View on blockchain explorer
                  </button>
                )}
              </div>

            {/* Progress Section */}
            {projectData.isProfitable ? 
              (<div className="flex flex-col">
              <div className="flex justify-between items-center my-1">
                <p className="text-sm text-[#a4a4a4]">
                  Raised: {formatNumberEuro(projectData.investmentRaised)}
                </p>
                <p className="text-sm text-[#a4a4a4]">
                  {projectPerforManceCalculator(
                  projectData.investmentRaised,
                  projectData.requiredInvestment
                )}%
                </p>
              </div>
              <ProgressBar
                progress={projectPerforManceCalculator(
                  projectData.investmentRaised,
                  projectData.requiredInvestment
                )}
                height={6}
                color="#56BBAC"
              />
              <h5 className="text-xl mt-1"> 
                Goal:{formatNumberEuro(projectData.requiredInvestment)}
              </h5>
            </div>
              )
              :
              (<div className="text-teal-800 font-semibold">This Project is Non-Investable</div>)
            }

            {/* CO2 Impact */}
              <div className=" flex items-center mt-3 relative ">
                {/* <div className="relative group">
                  <Leaf className="text-green-500 font-bold relative group mr-3"/>
                  <span className="absolute w-max bg-black text-white text-xs rounded px-2 py-1 scale-0 group-hover:scale-100 transition-opacity">
                    ESTIMATED CO2 IMPACT
                  </span>
                </div>
                <span className="text-[#6c6c6c]"> : {projectData.burn || "N/A"} TONNES</span> */}
                <ImpactView impactData={projectData.impactData}></ImpactView>
              </div>

            {/* Description */}
            <p className="mt-4 text-sm text-[#6c6c6c] text-justify break-words">
              {projectData.description}
            </p>
          </div>

          {/* Right Column */}
          <div className="flex flex-col px-6">
            <img
              className="w-full h-auto rounded-lg"
              src={projectData?.images?.[0]}
              onClick={()=>{
                setSelectedImageArray(projectData?.images)
                setShowImageModal(true)
              }} 
              alt={projectData.name}
            />
            {projectData.images && (
              <div className="flex items-center justify-center mt-3 gap-3">
                {projectData.images
                  .slice(1)
                  .slice(Number(projectData.images.length >= 5))
                  .map((img, idx) => (
                    <img
                      key={idx}
                      className="w-40 h-[90px] rounded-md object-cover"
                      src={img}
                      onClick={()=>{
                        setSelectedImageArray([img, ...(projectData?.images.filter((i)=>i!==img))])
                        setShowImageModal(true)
                      }} 
                      alt={`Project Image ${idx + 1}`}
                    />
                  ))}
              </div>
            )}
            {investor &&
              projectData.isProfitable &&
              projectData.investmentCutoffStatus === "active" &&
              projectData?.approvalStatus === "deployed" &&
              projectData.userId !== user?._id &&  Number(projectData?.investmentRaised) < Number(projectData.requiredInvestment) && (
                <button
                  className="mx-auto mt-5 w-10/12 py-3 text-xl bg-[#52bbab] text-white rounded-full"
                  onClick={handleInvestClick}
                >
                  Invest
                </button>
              )}
          </div>
        </div>

        {/* Project Phases */}
        <div>
        <div className="w-full items-start mt-10 mb-5">
          <p className="text-2xl font-semibold text-black pl-2">Additional Information</p>
        </div>
        <div className="overflow-x-auto">
            <div className="flex space-x-4 pb-4">
              {projectData?.projectPhases && projectData?.projectPhases.map((phase, idx) => (
                <div className="flex-none w-80" key={idx}>
                <div className="relative bg-gray-100 h-40 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                  <div className="flex items-start h-12 min-h-[3rem]">
                    <h3 className="flex-1 font-semibold text-emerald-600 text-sm break-words mr-2 line-clamp-2">
                      {phase.phaseName || "N/A"}
                    </h3>
                    <div className="flex items-center space-x-2"> 
                      {phase.documentUrl && (
                        <a href={phase.documentUrl.startsWith('http') ? phase.documentUrl : `https://${phase.documentUrl}`} target="_blank" rel="noopener noreferrer"> 
                          <FileText className="w-5 h-5 text-emerald-600 hover:text-emerald-800" />
                        </a>
                      )}
                      
                    </div> 
                  </div>
    
                  <div className="mt-2 flex-1 overflow-y-auto">
                    <p className="text-gray-700 text-xs whitespace-pre-wrap break-words">
                      {phase.phaseDescription || "No description provided"}
                    </p>
                  </div>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
          

        {/* Audit Reports */}
        {projectData.auditReports && (
          <div className="flex items-center justify-between h-16 px-4 mt-4 rounded-xl bg-[#eef9f7] border border-dashed border-[#52bbab]">
            <p className="text-[#52bbab] font-semibold">Audit Reports</p>
            <div className="flex items-center gap-3">
              <img className="w-5" src={downloadImage} alt="Download" />
              <p className="text-[#52bbab]">Download Report</p>
            </div>
          </div>
        )}

        {/* Currency Exchange Table */}
        {/* <div>
          <div className="w-full items-start mt-10">
            <p className="text-2xl font-semibold text-black pl-2">Exchange Rates</p>
            <p className="text-md text-gray-800 pl-2">Exchange Rates for 1 Euro (€)</p>
          </div>
          <ExchangeRatesTable/>
        </div> */}

        {/* Table View */}
        <div>
          <div className="w-full items-start mt-10">
            <div className="flex gap-5 justify-start items-center py-3">
              {['Table View', 'Balance Sheet'].map((view, idx) => (
          <button
            key={idx}
            onClick={() => setCurrentView(view.toLowerCase().replace(' ', ''))}
            className={`text-xl mx-4 py-2 px-4 rounded-lg shadow-md transition-transform transform ${
              currentView === view.toLowerCase().replace(' ', '')
                ? 'bg-teal-700 text-white scale-105 shadow-2xl shadow-teal-600'
                : 'bg-gray-200 text-gray-900 hover:bg-teal-50'
            } font-bold`}
          >
            {view}
          </button>
              ))}
            </div>
          </div>
          {getCurrentView()}
        </div>

        {/* Legal and Regulatory Compliance */}
        {projectData.compliance && (
          <div className="mt-7">
            <h1 className="text-2xl font-semibold">
              Legal and Regulatory Compliance
            </h1>
            <div className="flex flex-col gap-4 mt-6 pb-10">
              {projectData.compliance.map((item, idx) => (
                <div key={idx} className="flex items-center gap-4">
                  <img
                    className="w-8 h-8"
                    src={RightTick}
                    alt="Compliance"
                  />
                  <p className="text-base">{item}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {isDeploying && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[400px]">
            <div className="flex flex-col items-center">
              <Loader className="w-12 h-12 animate-spin text-[#52bbab] mb-4" />
              <h2 className="text-xl font-semibold mb-4">Deploying Project</h2>
              <p className="text-gray-600 text-center mb-2">{deploymentStep}</p>
              <p className="text-sm text-gray-500 text-center">
                Please don't close this window
              </p>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <h2 className="text-xl font-semibold mb-4">Become an Investor</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium">
                Select Currency
              </label>
              <select
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className="mt-1 block w-full border rounded-md p-2"
              >
                <option value="EUR">EUR(€)</option>
                
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Amount</label>
              <input
                type="number"
                value={amount}
                defaultValue={projectData.minInvestment}
                disabled
                className="mt-1 block w-full border rounded-md p-2"
                placeholder="Enter Token To Check Amount"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Tokens</label>
              <input
                type="number"
                value={tokens}
                onChange={handlTokenChange}
                className="mt-1 block w-full border rounded-md p-2 bg-gray-100"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-[#52bbab] text-white py-2 px-4 rounded-lg hover:bg-[#429a91]"
                onClick={() =>
                  navigate("/stripe-payment", {
                    state: {
                      projectId: projectData?._id,
                      projectName: projectData.name,
                      amount: amount || projectData.minInvestment,
                      tokens: tokens,
                    },
                  })
                }
              >
                Confirm
              </button>
              <button
                className="text-red-500 py-2 px-4 rounded-lg hover:bg-gray-100"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showDocModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white max-h-[35rem] p-2 rounded-lg shadow-lg w-[70rem] relative">
            <div className="absolute top-2 right-2 z-50">
              <button 
                onClick={()=>setShowDocModal(false)}
                className="text-red-500 hover:text-red-700 scale-110 p-1 bg-red-50 rounded-lg ">
                  <X className="h-6 w-6"/>
              </button>
            </div>
            <ViewDocumentation project={projectData} currencyRates={currencyRates}  withdrawals={withdrawals} contractAddress={projectData?.contractAddress || ""}/>
          </div>
        </div>
      )}

      {showImageModal && (
        <div 
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
            <div className="bg-transparent backdrop-blur-lg flex items-center justify-center h-[36rem] p-2 rounded-lg shadow-lg w-[60rem] absolute z-50">
            <div className="absolute top-2 right-2 z-50">
              <button 
                onClick={()=>setShowImageModal(false)}
                className="text-teal-700 hover:text-teal-900 bg-teal-50 scale-110 p-1 ">
                  <X className="h-6 w-6"/>
              </button> 
            </div>
            <ImageCarouselModal images={selectedImageArray} />
          </div>
        </div>
      )}

      {showExtendTimelineModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">

          <h2 className="text-xl font-semibold mb-4">Extend Timeline</h2>
          <div className="mb-4">
          <label className="block text-sm font-medium">Project Name</label>
             
              <input
                type="text"
                value={projectData.name}
                
                className="mt-1 block w-full border rounded-md p-2"
                min="1"
                disabled={true}
              /> 
            </div>

            
            <div className="mb-4">
              <label className="block text-sm font-medium">Duration (Months)</label>
              <input
                type="number"
                value={duration}
                onChange={(e) => setDuration(parseInt(e.target.value))}
                className="mt-1 block w-full border rounded-md p-2"
                min="1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-1 block w-full border rounded-md p-2"
                rows={3}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-[#52bbab] text-white py-2 px-4 rounded-lg hover:bg-[#429a91]"
                onClick={handleExtendTimeline}
              >
                OK
              </button>
              <button
                className="text-red-500 py-2 px-4 rounded-lg hover:bg-gray-100"
                onClick={() => setShowExtendTimelineModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExploreDetails;
