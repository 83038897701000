import React, { useState, ReactNode, useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ApiService from "../service/ApiService";
import { apiPaths } from "../service/apiPaths";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/appStore";
import { VerifyRefreshToken } from "../components/Authentication/VerifyRefreshToken";
import { setIsLoggedIn, setUser } from "../redux/userSlice";
import Cookies from "js-cookie";

const DefaultLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [logOutModal, setLogOutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    setLogOutModal(false);
    logOutHandler();
  };

  const cancelLogout = () => {
    setLogOutModal(false);
  };
  const logOutHandler = async () => {
    try {
      const response = await ApiService({
        method: "DELETE",
        endpoint: apiPaths.Logout,
      });
      sessionStorage.clear();
      dispatch(setIsLoggedIn(false));
      dispatch(setUser(null));
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/login");
    }
  };

  return (
    <>
      <section className="layout-section min-h-screen">
        <Header />
        <div className="flex bg-[#e8eef1] h-full relative">
          <div className=" p-6  sticky">
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              setLogOutModal={setLogOutModal}
            />
          </div>
          <div className="flex-1 p-6 pl-0 bg-[#e8eef1] ">
            <main className="h-full">
              <div className="mx-auto h-full sticky">{children}</div>
            </main>
          </div>
        </div>
      </section>
      {logOutModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[400px]">
            <h2 className="text-[20px] font-semibold mb-4">
              Do you wish to logout?
            </h2>
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-300 text-white px-4 py-2 rounded-md"
                onClick={cancelLogout}
              >
                No
              </button>
              <button
                className="bg-[#52bbab] text-white px-4 py-2 rounded-md"
                onClick={handleLogout}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DefaultLayout;
