import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaGraduationCap, FaStar, FaArrowLeft, FaSuitcase } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useGetWithdrawals from "./useGetWithdrawals";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import dayjs from "dayjs";
import bgimg from "../../images/Bg/bge4rth.jpg"
import formatNumberEuro from "../../util/FormatNumberEuro";
import formatMongoDate from "../../util/formatMongodate";
import { ExternalLink, LinkIcon } from "lucide-react";


// Define the type for a timeline item
type TimelineItem = {
  _id: string;
  title: string;
  timestamp: string;
  description: string;
  amount: string ,
  receipt?:string ,
  category?:string ,
  subcategory:string ,
  url?:string ,
  date:string
  subCategory:string
};




const Roadmap: React.FC = () => {
  const navigate = useNavigate();
  const location:any = useLocation()
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [timelineData, setTimelineData] = useState<TimelineItem[]>(
    []
  )



  const { getWithdrawalDetailsById } = useGetWithdrawals();

  const handleBack = () => {
    navigate(-1);
  };


  const formatDate = (timestamp: string): string => {
    const date = new Date(parseInt(timestamp) * 1000);
    return dayjs(date).format("DD MMMM, YYYY");
  };
  const formatDay = (timestamp: string): string => {
    return dayjs(timestamp).format("DD");
  };
  const formatMonth = (timestamp: string): string => {
    return dayjs(timestamp).format("MMMM");
  };
  const formatYear = (timestamp: string): string => {
    return dayjs(timestamp).format("YYYY");
  };



  useEffect(() => {
    const fetchWithdrawalDocumentation = async () => {
      setLoading(true);
      try {
        // const response = await ApiService({
        //   method: "POST",
        //   endpoint: apiPaths.getWithdrawalDocumentation,
        //   data: {
        //     projectManagerAddress: id,
        //   },
        //   ledgerApi: true,
        // });
         const response = await ApiService({
          method: "GET",
          endpoint: `${apiPaths.getProjectWithdrawals}/${location.state.projectId}`,
         
        });

        console.log(response) 


        setTimelineData(response.response);
      } catch (error) {

   
       
      
        console.error("Error fetching withdrawal documentation:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawalDocumentation();
  }, [id]);

  return (
    <div
      style={{
        height: "100vh",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        backgroundImage: location?.state?.roadmapImage
          ? `url(${location.state.roadmapImage})`
          : `url(${bgimg})`,  
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Back Button */}
      <button
        onClick={handleBack}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          marginBottom: "14px",
          fontSize: "16px",
          color: "#ffffff",
        }}
      >
        <FaArrowLeft style={{ marginRight: "8px", marginBottom:"1px"}} />
        <span className="font-semibold text-lg">Back</span>
      </button>

      <h1 style={{ fontSize: 32, textAlign: "center", marginBottom: "10px", color:'#000' }}>
        {location.state.projectName}
      </h1>
      <h2 style={{ textAlign: "center", marginBottom: "10px", color:'#000' }}>
        Roadmap
      </h2>
      <h1 style={{ textAlign: "center", marginBottom: "30px", color:'#000' }}>
        {location?.state?.investmentCutoffDate ? formatMongoDate(location?.state?.investmentCutoffDate) : ""}
      </h1>

      {/* Vertical Timeline */}
      <div style={{ flex: 1, overflowY: "auto" }}>
        <VerticalTimeline>
          {timelineData && timelineData.length > 0 && timelineData.map((item) => (
            <VerticalTimelineElement
              key={item._id}
              className="vertical-timeline-element"
              contentStyle={{
                background: "#ffffff90",
                backdropFilter:'blur(10px)',
                color: "#000",
              }}
              contentArrowStyle={{
                borderRight: "7px solid #fff",
              }}
              iconStyle={{
                background: "#53bbac",
                color: "#fff",
              }}
            >
              <div className="vertical-timeline-element-box">
                <div className="flex justify-between items-center">
                  <p className="mt-0 m-0 text-left top-date" style={{ margin: '0px 0px' }}>
                    {formatMongoDate(item.date)}
                  </p>
                  {item?.url && (


                    <a 
                      href={item.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="ml-2 text-[#53bbac] hover:text-[#40ada0] transition-colors duration-200 cursor:pointer"
                    >
                      <ExternalLink size={20} />
                    </a>
                )} 
                </div>
                <div className="flex items-center gap-x-3">
                  <div className="">
                    <h1 className="text-[24px]">{item.title}</h1>
                    <p className="text-[#6c6c6c] vertical-timeline-element-description">{item.description} </p>
                  </div>
                  <div className="flex flex-col border-l-2 pl-[15px] border-[#000] vertical-side-box">
                    <div className="flex flex-col items-center gap-x-3 ">
                      <h4 className="text-[24px]" style={{ margin: '0px 0px' }}>{formatMongoDate(item.date)}</h4>
                    </div>
                    <h3 className="font-semibold text-[24px] border-t-2 mt-3 text-[#53bbac]">{formatNumberEuro(item.amount.toString())}</h3>
                  </div>
                </div>
                <div className="pt-4 vertical-timeline-element-info">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-[#6c6c6c]" style={{ margin: '0px 0px' }}>Category : {item.category || ""}</p>
                      {/* <p className="text-[#6c6c6c]" style={{ margin: '0px 0px' }}>Sub Category : {item.subcategory }</p> */}
                      <p className="text-[#6c6c6c]" style={{ margin: '0px 0px' }}>Sub Category : {item.subCategory }</p>
                    </div>
                    <h3 className="font-semibold text-[24px]">{formatNumberEuro(item.amount.toString())}</h3>
                  </div>
                  <div className="mt-4 flex items-center justify-between">
                    {/* Receipt Button */}
                    <div className="flex items-center space-x-3">
                      {item?.receipt && (
                        <a 
                          href={item?.receipt} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          <button className="bg-[#53bbac] rounded-[40px] text-white py-2 px-4 hover:bg-[#40ada0] transition-colors duration-200">
                            View Receipt
                          </button>
                        </a>
                      )}
                    </div>
                    <button 
                      onClick={() => {
                        window.open(
                          `https://testnet.snowtrace.io/address/${id}/contract/43113/readContract?chainid=43113`,
                          "_blank"
                        );
                      }}
                      className="bg-[#53bbac] rounded-[40px] text-white py-2 px-4"
                    >
                      View On Blockchain
                    </button>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
          ))}
          <VerticalTimelineElement
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            icon={<FaStar />}
          />
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Roadmap;
