import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Cloudy, Droplet, Handshake, Heart, Info, Leaf, Trash2, TreePine, Wrench, Zap } from 'lucide-react';
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import { apiPaths } from '../../../service/apiPaths';
import { useDashboard } from '../../Dashboard/useDashboard';
import { Link } from 'react-router-dom';
import RightIcon from "../../../images/Settings/right.png";
import toast from 'react-hot-toast';
import { TbBatteryVerticalCharging } from 'react-icons/tb';
import { PiWavesLight } from 'react-icons/pi';
import { LiaShoppingBagSolid } from 'react-icons/lia';


interface ImpactType {
  id: string;
  label: string;
  icon: React.ReactNode;
  description: string;
  unit?: string;
}

interface ImpactItem {
  icon: string;
  value: number;
  type: string;
  unit?: string;
}

interface NewImpact {
  icon: string;
  value: string;
  type: string;
  unit?: string;
} 

const ProjectImpactEditPage: React.FC = () => {
  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<{_id:string, name:string, impactData:any[] }>({ _id: '', name: '', impactData: [] });
  const [newImpact, setNewImpact] = useState<NewImpact>({
    icon: '',
    value: '',
    type: 'expected',
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { getProjectsByUser } = useDashboard();
  
 const impactTypes = [
   {
     id: 'tree',
     icon: <TreePine color="green" size={32} strokeWidth={1.5} />,
     label: 'Trees Planted',
     description: 'Number of clearly documented and verifiably planted trees',
     unit: ''
   },
   {
     id: 'water',
     icon: <Droplet color="#3b82f6" size={32} strokeWidth={1.5} />,
     label: 'Water Saved',
     description: 'Amount of water conserved through sustainable practices',
     unit: 'L'
   },
   {
     id: 'air',
     icon: <Cloudy color="#6b7280" size={32} strokeWidth={1.5} />,
     label: 'CO₂ Reduced',
     description: 'Amount of CO2 emissions clearly documented and verifiably reduced or offset',
     unit: 'kg'
   },
   {
     id: 'social',
     icon: <Handshake color="#8b5cf6" size={32} strokeWidth={1.5} />,
     label: 'People Helped',
     description: 'Number of people clearly supported and positively impacted',
     unit: ''
   },
   {
     id: 'sustainability',
     icon: <Leaf color="#10b981" size={32} strokeWidth={1.5} />,
     label: 'Biodiversity Protected',
     description: 'Area clearly protected through conservation efforts',
     unit: '%'
   },
   {
     id: 'energy-produced',
     icon: <Zap color="#eab308" size={32} strokeWidth={1.5} />,
     label: 'Energy Produced',
     description: 'Amount of energy clearly produced from renewable sources',
     unit: 'kWh'
   },
   {
     id: 'energy-saved',
     icon: <TbBatteryVerticalCharging color='red' size={32} strokeWidth={1.5} />,
     label: 'Energy Saved',
     description: 'Amount of energy clearly saved through efficiency measures',
     unit: 'kWh'
   },
   {
     id: 'plastic',
     icon: <PiWavesLight color='#00b4d8' size={32} strokeWidth={1.5} />,
     label: 'Plastic Collected',
     description: 'Amount of plastic waste clearly removed from nature',
     unit: 'kg'
   },
   { 
     id: "sustainable-products",
     icon: <LiaShoppingBagSolid color='#00b4d8' size={32} strokeWidth={0.5} />,
     label: 'Sustainable Products Produced',
     description: 'Number of eco-friendly products clearly integrated',
     unit: ''
   },
   {
     id: "jobs-produced",
     icon: <Wrench size={32} strokeWidth={1.5} color="black" />,
     label: 'Fair Jobs',
     description: 'Number of sustainable and fair jobs created',
     unit: ''
   },
   {
     id: "clean-water",
     icon: <Heart size={32} strokeWidth={1.5} color="#00b4d8" />,
     label: 'Clean Water',
     description: 'Clean Water Provided',
     unit: 'L'
   } ,
    {
       id: "waste",
       icon: <Trash2 size={32} strokeWidth={1.5} color="black" />,
       label: 'Waste Reduced',
       description: 'Amount of waste clearly avoided or recycled',
       unit: 'kg'
     }
 ];

 
  // Fetch projects
  useEffect(() => {
    async function fetchProjects() {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/${apiPaths.getProjectsName}?purpose=impact`,
            {
              withCredentials: true,
            },
          );
 
  
          const res = response.data.response;
  
          if (res && Array.isArray(res)) {
            setProjects(res);
          } else {
            // If no projects are fetched, fall back to dummy data
            // setAllProjects(dummyData);
            console.error(
              "Error fetching project details or no projects available.",
            );
          }
        
        setIsLoading(false);
      }

    fetchProjects();
  }, []);

  // Load project data when selected
  const handleProjectSelect = async (projectId: string) => {
    const selected = projects.find(project => project._id === projectId);
    if (!selected) return;
    
    setSelectedProject(selected);
    setDropdownOpen(false);
    
    // If the project has impact data, use it; otherwise, initialize with empty array
    if (selected && selected.impactData) {
      setFormData({ ...selected, impactData: [...selected.impactData] });
    } else {
      setFormData({ ...selected, impactData: [] });
    }
    
    // Reset the form state
    setNewImpact({
      icon: '',
      value: '',
      type: 'expected'
    });
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleSelectImpactType = (iconId: string) => {
    const selectedType = impactTypes.find(type => type.id === iconId);
    setNewImpact({
      ...newImpact,
      icon: iconId,
      unit: selectedType?.unit || ''
    });
  };

  const handleAddOrUpdateImpact = () => {
    if (!newImpact.icon || !newImpact.value) return;
    
    const impactItem: ImpactItem = {
      icon: newImpact.icon,
      value: parseFloat(newImpact.value),
      type: newImpact.type,
      unit: impactTypes.find(type => type.id === newImpact.icon)?.unit || ''
    };
    
    if (isEditing && editingIndex !== null) {
      // Update existing impact
      const updatedImpacts = [...formData.impactData];
      updatedImpacts[editingIndex] = impactItem;
      setFormData({ ...formData, impactData: updatedImpacts });
    } else {
      // Add new impact
      setFormData({
        ...formData,
        impactData: [...formData.impactData, impactItem]
      });
    }
    
    // Reset form
    setNewImpact({
      icon: '',
      value: '',
      type: 'expected'
    });
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleEditImpact = (index: number) => {
    const impact = formData.impactData[index];
    setNewImpact({
      icon: impact.icon,
      value: impact.value.toString(),
      type: impact.type,
    });
    setIsEditing(true);
    setEditingIndex(index);
  };

  const handleRemoveImpact = (index: number) => {
    const updatedImpacts = [...formData.impactData];
    updatedImpacts.splice(index, 1);
    setFormData({ ...formData, impactData: updatedImpacts });
    
    // If currently editing this item, reset the form
    if (isEditing && editingIndex === index) {
      setNewImpact({
        icon: '',
        value: '',
        type: 'expected'
      });
      setIsEditing(false);
      setEditingIndex(null);
    }
  };

  const handleSaveProject = async () => {
    if (!selectedProject) return;
    
    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/${apiPaths.updateProject}/${selectedProject._id}`,
        { ...formData },
        { withCredentials: true }
      );
      
      // Update the projects list with the updated project
      const updatedProjects = projects.map(project => 
        project._id === selectedProject._id ? { ...project, ...formData } : project
      );
      setProjects(updatedProjects);
      
      // Show success notification
      const notification = document.getElementById('notification');
      if (notification) {
        notification.classList.remove('opacity-0');
        notification.classList.add('opacity-100');
        
        setTimeout(() => {
          notification.classList.remove('opacity-100');
          notification.classList.add('opacity-0');
        }, 3000);   
      }
      toast.success("Project Updated Successfully")
    } catch (error) {
      console.error("Error updating project:", error);
      alert("Failed to update project impact data.");
    } finally {
      setIsLoading(false);
    }
  };

  // Get all available impact types and merge with existing data
  const getAllImpactItems = (): ImpactItem[] => {
    if (!selectedProject) return [];
    
    // Create a map of existing impact data
    const existingImpacts: Record<string, ImpactItem> = {};
    formData.impactData.forEach(item => {
      existingImpacts[item.icon] = item;
    });
    
    // Return all impact types, using existing data where available
    return impactTypes.map(type => {
      if (existingImpacts[type.id]) {
        return existingImpacts[type.id];
      } else {
        return {
          icon: type.id,
          value: 0,
          type: 'expected',
          unit: type.unit
        };
      }
    });
  };

  const getImpactTypeById = (iconId: string): ImpactType | undefined => {
    return impactTypes.find(type => type.id === iconId);
  };

  // Determine if an impact exists in the current data
  const impactExists = (iconId: string): boolean => {
    return formData.impactData.some(item => item.icon === iconId);
  };

  return (
   


<div className="bg-[#fff] rounded-[16px] min-h-full">
<div className="p-6 border-b-2">
  <div className="flex items-center gap-2 font-semibold">
    <Link to="/settings">
      <h1 className="mb-0 text-[20px]">Settings</h1>
    </Link>
    <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
    <h1 className="mb-0 text-[20px] text-[#52bbab]">Environmental Impact</h1>
  </div>
  <p className="text-[14px] text-[#9e9b9b]">
  Review and update the environmental impact listings for your project. Make changes as needed to reflect the most current and accurate information.
  </p>
</div>
<div className="p-6">

<div className="max-w-6xl mx-auto space-y-6 p-4 md:p-6">
      {/* Success notification */}
     

    
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
            Loading...
        </div>
      ) : (
        <>
          {/* Project Selection */}
          <div className="bg-white rounded-lg p-6 border border-gray-200 shadow-sm relative">
            <h2 className="text-lg font-medium text-emerald-800 mb-4">Select Project</h2>
            
            <div className="relative inline-block w-full md:w-64">
              <div 
                className={`p-2 border border-gray-300 rounded-md bg-white flex items-center justify-between cursor-pointer ${dropdownOpen ? 'border-emerald-400 ring-1 ring-emerald-400' : 'hover:border-gray-400'}`}
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <span className={`truncate ${selectedProject ? 'text-gray-800' : 'text-gray-500'}`}>
                  {selectedProject ? selectedProject.name : "Select a project"}
                </span>
                <svg className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              
              {dropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                  {projects.length === 0 ? (
                    <div className="px-4 py-3 text-sm text-gray-500">No projects available</div>
                  ) : (
                    projects.map(project => (
                      <div
                        key={project._id}
                        className="px-4 py-2 text-sm text-gray-800 hover:bg-emerald-50 cursor-pointer truncate"
                        onClick={() => handleProjectSelect(project._id)}
                      >
                        {project.name || `Project ${project._id}`}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
          
          {!selectedProject ? (
            <div className="bg-gray-50 rounded-lg p-6 border border-gray-200 text-center">
              <div className="text-5xl text-emerald-200 flex justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-700 mb-1">No Project Selected</h3>
              <p className="text-gray-500">Please select a project to edit its impact data</p>
            </div>
          ) : (
            <>
              {/* Impact Form */}
              <div className="bg-white rounded-lg p-6 border border-gray-200 shadow-sm">
                <h2 className="text-lg font-medium text-emerald-800 mb-4">
                  {isEditing ? 'Edit Impact Item' : 'Add New Impact Item'}
                </h2>
                
                <div className="space-y-4">
                  <div className="grid grid-cols-1 gap-4">
                    {/* Impact Type Selection */}
                    {/* Impact Type Selection */}
<div>
  <label className="block text-sm font-medium text-gray-700 mb-2">
    Impact Type
  </label>
  {impactTypes.filter(type => !impactExists(type.id)).length === 0 ? (
    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-center">
      <p className="text-gray-500">All impact types have been added to this project.</p>
      <p className="text-sm text-gray-400 mt-1">
        You can edit existing impacts in the Project Impact Overview section below.
      </p>
    </div>
  ) : (
    <div className="flex overflow-x-auto pb-3 no-scrollbar">
      {impactTypes
        .filter(type => !impactExists(type.id))
        .map((type) => (
          <div
            key={type.id}
            onClick={() => handleSelectImpactType(type.id)}
            className={`cursor-pointer flex-shrink-0 flex flex-col items-center justify-center p-2 rounded-lg border mx-1 min-w-[200px] gap-x-4 h-24 ${
              newImpact.icon === type.id
                ? 'bg-emerald-50 border-emerald-300 shadow-sm'
                : 'border-gray-200 hover:bg-gray-50'
            }`}
          >
            <div className="text-2xl">{type.icon}</div>
            <span className="text-xs mt-1 text-center font-medium">{type.label}</span>
          </div>
        ))}
    </div>
  )}
  
  {/* Show description of selected impact type */}
  {newImpact.icon && (
    <div className="mt-2 text-sm text-gray-600 flex items-start">
      <Info size={16} className="mr-1 mt-0.5 text-emerald-400" />
      <span>{getImpactTypeById(newImpact.icon)?.description}</span>
    </div>
  )}
</div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {/* Value Input */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Value
                        </label>
                        <div className="relative">
                          <input
                            type="number"
                            value={newImpact.value}
                            onChange={(e) => setNewImpact({ ...newImpact, value: e.target.value })}
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                            placeholder="Enter value"
                            min="0"
                            step="0.01"
                          />
                          {newImpact.icon && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span className="text-gray-500">
                                {getImpactTypeById(newImpact.icon)?.unit}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Impact Status */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Status
                        </label>
                        <div className="flex space-x-4">
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              className="form-radio text-emerald-600"
                              name="type"
                              value="expected"
                              checked={newImpact.type === 'expected'}
                              onChange={() => setNewImpact({ ...newImpact, type: 'expected' })}
                            />
                            <span className="ml-2 text-gray-700">Expected Impact</span>
                          </label>
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              className="form-radio text-emerald-600"
                              name="type"
                              value="current"
                              checked={newImpact.type === 'current'}
                              onChange={() => setNewImpact({ ...newImpact, type: 'current' })}
                            />
                            <span className="ml-2 text-gray-700">Current Impact</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex space-x-4">
                      <button
                        type="button"
                        onClick={handleAddOrUpdateImpact}
                        disabled={!newImpact.icon || !newImpact.value}
                        className={`${
                          !newImpact.icon || !newImpact.value 
                            ? 'bg-gray-300 cursor-not-allowed' 
                            : isEditing 
                              ? 'bg-emerald-500 hover:bg-emerald-600' 
                              : 'bg-emerald-500 hover:bg-emerald-600'
                        } text-white px-6 py-2 rounded-lg transition-colors font-medium`}
                      >
                        {isEditing ? 'Update Impact' : 'Add Impact'}
                      </button>
                      
                      {isEditing && (
                        <button
                          type="button"
                          onClick={() => {
                            setNewImpact({
                              icon: '',
                              value: '',
                              type: 'expected'
                            });
                            setIsEditing(false);
                            setEditingIndex(null);
                          }}
                          className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors font-medium"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Display All Impact Items (including those with zero values) */}
              <div className="bg-white rounded-lg p-6 border border-gray-200 shadow-sm">
  <h3 className="text-lg font-medium text-emerald-800 mb-4">Project Impact Overview</h3>
  <div className="relative w-full overflow-hidden">
    <div className="overflow-x-auto pb-4 no-scrollbar" style={{ WebkitOverflowScrolling: 'touch' }}>
      <div className="flex gap-4 min-w-max">
        {getAllImpactItems().map((item, idx) => {
          const impactType = getImpactTypeById(item.icon);
          const exists = impactExists(item.icon);
          
          return (
            <div 
              key={idx} 
              className={`relative bg-white p-4 rounded-lg min-w-[200px] ${
                exists 
                  ? 'border border-gray-200 shadow-sm' 
                  : 'border border-gray-200 border-dashed hover:bg-gray-50'
              }`}
            >
              {exists && (
                <div className="absolute top-2 right-2 flex space-x-1">
                  <button
                    onClick={() => handleEditImpact(formData.impactData.findIndex(i => i.icon === item.icon))}
                    className="text-gray-400 hover:text-emerald-500 p-1 rounded-full hover:bg-emerald-50"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button
                    onClick={() => handleRemoveImpact(formData.impactData.findIndex(i => i.icon === item.icon))}
                    className="text-gray-400 hover:text-red-500 p-1 rounded-full hover:bg-red-50"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              )}
              
              <div className="flex items-center">
                <div className="flex items-center justify-center h-10 w-10 rounded-full bg-emerald-100 mr-3">
                  <div className="text-emerald-600">
                    {impactType?.icon}
                  </div>
                </div>
                <div>
                  <div className="text-lg font-medium">
                    {exists ? item.value : '0'}{item.unit}
                  </div>
                  <div className="text-sm text-gray-600">
                    {impactType?.label}
                  </div>
                  {exists && (
                    <div className="mt-2">
                      <span className={`text-xs px-2 py-1 rounded-full ${
                        item.type === 'expected' 
                          ? 'bg-emerald-100 text-emerald-800' 
                          : 'bg-green-100 text-green-800'
                      }`}>
                        {item.type === 'expected' ? 'Expected' : 'Current'}
                      </span>
                    </div>
                  )}
                  {!exists && (
                    <button
                      onClick={() => {
                        setNewImpact({
                          icon: item.icon,
                          value: '',
                          type: 'expected'
                        });
                      }}
                      className="mt-2 text-xs text-emerald-600 hover:text-emerald-800 font-medium flex items-center"
                    >
                      <svg className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                      Add impact data
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
</div>
              
              {/* Save Button */}
              <div className="flex justify-end">
                <button
                  onClick={handleSaveProject}
                  className="bg-emerald-600 hover:bg-emerald-700 text-white px-6 py-3 rounded-lg transition-colors font-medium shadow-sm"
                >
                  Save Project Impact Data
                </button>
              </div>
            </>
          )}
        </> 
      )}
    </div>

</div>
</div>
  );
};

export default ProjectImpactEditPage;