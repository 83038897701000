import { useState } from "react";
import { apiPaths } from "../../service/apiPaths";
import ApiService from "../../service/ApiService";
import { ProjectResponse } from "./Dashboard.props";
import toast from "react-hot-toast";

export const useDashboard = () => {
  const [loading, setLoading] = useState(false);

  const getProjectsByUser = async (
    page: number = 1,
    limit: number = 4,
    project_name: string = "",
    purpose: string,
    mode:string
  ) => {
    setLoading(true);

    var queryString = "?page=" + page + "&limit=" + limit;

    if (purpose === "investor") {
      queryString += "&purpose=investor" ;
    }

    if (purpose === "creator") { 
      queryString += "&purpose=creator";
    }

    if (project_name) {
      queryString += "&search=" + project_name;
    } 
    if(mode){
      queryString += "&mode=" + mode;

    }

    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.ListProject + queryString,
      });
      if (response) {
        console.log(response);
        return response.response;
        // if(purpose==='investor'){
        //   return response.response;
        // }
        // else{
        //   return response.response.docs
        // }
      } else {
        toast.error("Failed to fetch projects");
        return [];
      }
    } catch (error: any) {
      const errorMessage = error.message || "Error while fetching projects";
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error Occurred');
      }
      return [];
    } finally {
      setLoading(false);
    }
  };



  const getInvestmentDetails = async()=>{
    try{
      const data = await ApiService({
        method:'GET' ,
        endpoint: apiPaths.getInvestMentDetails
      })

      console.log(data)

    return data.response
    }catch(err){
      return null
    }
  }


  const getInvestedProjects = async(page:string , search:string)=>{
    try{
      const investedProjects = await ApiService({
        method:'GET' ,
        endpoint:apiPaths.getInvestedProjects+`?page=${page}&limit=4&search=${search}`
      })

      return investedProjects.response


    }catch(err){
      return null

    }

  }

  return { getProjectsByUser, loading ,getInvestmentDetails , getInvestedProjects };
};
