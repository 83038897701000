import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginLogo from "../../images/login/loginLogo.png";
import NewPasswordImage from "../../images/login/newpassword.png";
import { useDispatch, useSelector } from "react-redux";
import { useSignup } from "./Signup/useSignup";
import { setPassword } from "./VerifyOtp/useVerify";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";

interface FormValues {
  password: string;
  rePassword: string;
}

const NewPassword: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    password: "",
    rePassword: "",
  });
  const [errors, setErrors] = useState<FormValues>({
    password: "",
    rePassword: "",
  });
  const location = useLocation();
  const signupData = useSelector((store: any) => store.signup);
  const navigate = useNavigate();
  const { forgotToken } = useParams();

  const isForgetRoute = location.pathname.includes("/newpassword/forget");

  if (isForgetRoute) {
    if (!forgotToken) {
      navigate("/login");
    }
  }
  const isCreateRoute = location.pathname.includes("/newpassword/create");

  const { signup } = useSignup();

  // Form validation
  const validateForm = (): boolean => {
    let valid = true;
    let tempErrors: FormValues = { password: "", rePassword: "" };
    const digitRegex = /\d/;
    const upperCaseRegex = /[A-Z]/;
    const speacialCharacter = /[^A-Za-z0-9]/;
    if (formValues.password.length < 8) {
      toast.error("Password must of length 8");
      return false;
    }

    // Password validation
    if (!formValues.password) {
      tempErrors.password = "Password is required.";
      toast.error(tempErrors.password);
      valid = false;
      return false;
    } else if (!digitRegex.test(formValues.password)) {
      tempErrors.password = "Include atleast one digit";
      toast.error(tempErrors.password);
      valid = false;
      return false;
    } else if (!upperCaseRegex.test(formValues.password)) {
      tempErrors.password = "Include atleast one uppercase character";
      toast.error(tempErrors.password);
      return false;
    } else if (!speacialCharacter.test(formValues.password)) {
      tempErrors.password = "Include atleast one special character";
      toast.error(tempErrors.password);
      return false;
    }

    if (!formValues.rePassword) {
      tempErrors.rePassword = "Please re-enter your password.";
      valid = false;
    } else if (formValues.rePassword !== formValues.password) {
      tempErrors.rePassword = "Passwords do not match.";
      toast.error(tempErrors.rePassword);
      valid = false;
      return false
    }

    setErrors(errors);

    return valid;
  };

  // Handle form submission
  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      if (isCreateRoute) {
        const data = {
          fullName: signupData?.fullName,
          phoneNumber: signupData?.phoneNumber,
          otp: signupData?.otp,
          password: formValues.password,
          confirmPassword: formValues.password,
          email: signupData?.email,
        };
        console.log(data);
        const response = await signup(data);

        console.log(response);
      } else {
        if (!forgotToken) {
          toast.error("Token Missing");
          return;
        }
        await changePassword(formValues.password, forgotToken);
      }
      setLoading(false);
    } else {
      if (errors.password) {
        toast.error(errors.password);
      } else if (errors.rePassword) {
        toast.error(errors.rePassword);
      }
    }
  };

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changePassword = async (newPassword: string, forgotToken: string) => {
    const data = qs.stringify({
      forgotToken: forgotToken,
      newPassword: newPassword,
    });

    try {
      setLoading(true);
      const response: any = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/set-password`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );
      console.log(response.data.success);
      if (response?.data?.success) {
        toast.success("Password Changed Successfully");
        navigate("/login");
      }

      console.log("Password changed successfully:", response.data);
    } catch (error) {
      toast.error("Please Try Again ");
      console.error("Error changing password:", error || error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative lg:pt-0 pt-25">
      <img
        className="absolute lg:top-7 lg:right-7 top-5 left-auto right-auto w-[210px]"
        src={LoginLogo}
        alt="Login Logo"
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:px-10">
            <img
              className="lg:h-[90vh] max-w-full"
              src={NewPasswordImage}
              alt="New Password"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 flex items-center lg:px-10 lg:mt-0 mt-5">
            <div className="form-box w-full lg:px-10">
              <h3 className="forgot-heading">Set a Password</h3>
              <p className="login-subText mt-5">
                {isForgetRoute
                  ? "Please set a new password for your account."
                  : "Please set a password for your account"}
              </p>

              <form className="mx-auto mt-10" onSubmit={submitHandler}>
                <div className="relative mb-5">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formValues.password}
                    onChange={handleInputChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder=""
                    required
                  />
                  <label
                    htmlFor="password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Create Password
                  </label>
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  >
                    {showPassword ? (
                      <FaEye size={20} />
                    ) : (
                      <FaEyeSlash size={20} />
                    )}
                  </span>
                </div>

                <div className="relative mb-5">
                  <input
                    type={showRePassword ? "text" : "password"}
                    id="re-password"
                    name="rePassword"
                    value={formValues.rePassword}
                    onChange={handleInputChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder=""
                    required
                  />
                  <label
                    htmlFor="re-password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Re-enter Password
                  </label>
                  <span
                    onClick={() => setShowRePassword(!showRePassword)}
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  >
                    {showRePassword ? (
                      <FaEye size={20} />
                    ) : (
                      <FaEyeSlash size={20} />
                    )}
                  </span>
                </div>

                <button
                  type="submit"
                  className="text-white min-w-full bg-[var(--primary-color)] focus:outline-none font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
                  disabled={loading}
                >
                  {loading ? "Hang Up..." : "Set Password"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
