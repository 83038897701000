import dayjs from 'dayjs';

/**
 * Get historical exchange rate for a currency at a specific timestamp
 * @param currencyName - The name of the currency to find
 * @param withdrawalTimestamp - The timestamp (in milliseconds) for which to find the rate
 * @param conversions - Array of currency conversion data
 * @returns Exchange rate information or null if currency not found
 */
const getHistoricalRate = (currencyName: string, withdrawalTimestamp: string | number, conversions: any[]) => {
  // Convert string timestamp to number if needed
  const timestamp = typeof withdrawalTimestamp === 'string' 
    ? Number(withdrawalTimestamp) 
    : withdrawalTimestamp;
  
  // Check if timestamp is in seconds rather than milliseconds (Unix timestamps in seconds have 10 digits or fewer)
  const normalizedTimestamp = timestamp < 10000000000 ? timestamp * 1000 : timestamp;
  
  console.log(`Normalized withdrawal timestamp: ${normalizedTimestamp}, date: ${new Date(normalizedTimestamp).toUTCString()}`);
  
  // Find the requested currency
  const currency = conversions.find(c => c.name === currencyName);
  
  if (!currency) {
    console.log(`Currency "${currencyName}" not found`);
    return null;
  }
  
  // Check if currency has history records
  if (currency.history && currency.history.length > 0) {
    console.log(`Found ${currency.history.length} historical rates for ${currencyName}`);
    
    // Find the applicable historical rate
    const activeRate = currency.history.find((h: { startDate: string | number | Date; endDate: string | number | Date | null; }) => {
      const startTime = new Date(h.startDate).getTime();
      const endTime = h.endDate ? new Date(h.endDate).getTime() : Date.now();
      
      console.log(`Is ${normalizedTimestamp} between ${startTime} and ${endTime}? ${normalizedTimestamp >= startTime && normalizedTimestamp <= endTime}`);
      
      return normalizedTimestamp >= startTime && normalizedTimestamp <= endTime;
    });
    
    // Return the found historical rate
    if (activeRate) {
      console.log(`Found matching historical rate: ${activeRate.rate}`);
      return {
        rate: parseFloat(activeRate.rate),
        startDate: new Date(activeRate.startDate),
        endDate: activeRate.endDate ? new Date(activeRate.endDate) : null,
        symbol: currency.symbol,
        name: currency.name
      };
    }
    
    console.log('No matching historical rate found, using current rate');
  } else {
    console.log(`No history available for ${currencyName}, using current rate`);
  }
  
  // Return current rate if no historical rate matches
  return {
    rate: parseFloat(currency.rate),
    startDate: new Date(currency.startDate || currency.createdAt),
    symbol: currency.symbol,
    name: currency.name
  };
};

export default getHistoricalRate;