import React, { useState, useEffect } from "react";
import RightIcon from "../../../images/Settings/right.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import toast from "react-hot-toast";
import { setUser } from "../../../redux/userSlice";

const Preference = () => {
  const [timezone, setTimezone] = useState<string>("");
  const [timeout, setTimeout] = useState<number>(0); // This will store timeout in minutes
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const user = useSelector((store: any) => store?.user?.user);
  console.log(user?.preferences);

  useEffect(() => {
    if (user?.preferences) {
      console.log(user?.preferences);
      setTimezone(user?.preferences?.timezone || "");
      setTimeout(
        user?.preferences?.logoutTime ? user?.preferences?.logoutTime / 60 : 0,
      );
    }
  }, [user]);

  const handleTimezoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimezone(e.target.value);
    console.log("Selected Timezone:", e.target.value);
  };

  const handleTimeoutChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeout(parseInt(e.target.value));
    console.log("Selected Timeout:", timeout);
  };

  const handleUpdate = async () => {
    const data = { timezone, logoutTime: timeout * 60 }; // Convert minutes to seconds
    try {
      setLoading(true);
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.timeZonePreferences,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      if (response && response.success) {
        // console.log(response,timeout,timezone);
        dispatch(
          setUser({
            ...user,
            preferences: { timezone, logoutTime: timeout * 60 },
          }),
        );
        setTimeout(timeout / 60);
        setTimezone(timezone);
        toast("Preferences Updated");
      } else {
        toast.error("Error Occurred");
      }
    } catch (err) {
      toast.error("Error Occurred");
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src='loading_icon.gif' alt='logo' className="" />
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#fff] rounded-[16px] min-h-full">
        <div className="p-6 border-b-2">
          <div className="flex items-center gap-2 font-semibold">
            <Link to="/settings">
              <h1 className="mb-0 text-[20px]">Settings</h1>
            </Link>
            <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
            <h1 className="mb-0 text-[20px] text-[#52bbab]">Preference</h1>
          </div>
          <p className="text-[14px] text-[#9e9b9b]">
            Customize your platform experience. Adjust notification settings,
            privacy options, and display preferences to suit your personal needs
            and enhance your journey.
          </p>
        </div>
        <div className="p-6 min-h-[500px]">
          <div className="flex gap-6 w-full">
            <div>
              <p>Timezone</p>
              <select
                className="bg-[#eeeeee] w-[448px] h-[64px] px-4 rounded-[14px] mt-3"
                name="timezone"
                id="timezone"
                value={timezone} // Use value instead of defaultValue for controlled component
                onChange={handleTimezoneChange}
              >
                <option value="">Select Timezone</option>
                <option value="UTC">UTC</option>
                <option value="GMT">GMT</option>
                <option value="EST">EST</option>
                <option value="CST">CST</option>
                <option value="MST">MST</option>
                <option value="PST">PST</option>
                <option value="IST">IST</option>
                <option value="CET">CET</option>
                <option value="EET">EET</option>
                <option value="AEST">AEST</option>
              </select>
            </div>
            <div>
              <p>Timeout/Logout</p>
              <select
                className="bg-[#eeeeee] w-[448px] h-[64px] px-4 rounded-[14px] mt-3"
                name="timeout"
                id="timeout"
                value={timeout ? timeout.toString() : ""} // Controlled value for timeout
                onChange={handleTimeoutChange}
              >
                <option value="">Select Timeout</option>
                <option value="5">5 Minutes</option>
                <option value="10">10 Minutes</option>
                <option value="15">15 Minutes</option>
                <option value="30">30 Minutes</option>
                <option value="60">1 Hour</option>
                <option value="120">2 Hours</option>
              </select>
            </div>
          </div>
          <button
            className="text-[#fff] text-[19px] mt-4 bg-[#52bbab] rounded-[12px] px-12 py-3"
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Preference;
