import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { apiPaths } from "../../../service/apiPaths";
import RightIcon from "../../../images/Settings/right.png";
import { PlusIcon, Trash2, Edit, FileText, Info } from "lucide-react";
import ApiService from "../../../service/ApiService";

interface PhaseData {
  _id: string;
  phaseName: string;
  phaseDescription: string;
  documentUrl: string;
}

interface ProjectResponse {
  _id: string;
  name: string;
  projectPhases: PhaseData[];
}

const PhaseInformation: React.FC = () => {
  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [projectDetails, setProjectDetails] = useState<ProjectResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPhases, setIsLoadingPhases] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const user = useSelector((store: any) => store.user.user);

  const [newPhase, setNewPhase] = useState<PhaseData>({
    _id: "",
    phaseName: "",
    phaseDescription: "",
    documentUrl: "",
  });

  const [editMode, setEditMode] = useState(false);
  const [editPhaseId, setEditPhaseId] = useState<string>("");
  const [phases, setPhases] = useState<PhaseData[]>([]);

  const tooltips = {
    documentUrl: "Add a URL to any supporting documentation for this phase",
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (selectedProject) {
      fetchProjectDetails();
    } else {
      setProjectDetails(null);
      setPhases([]);
    }
  }, [selectedProject]);

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      if (user) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/${apiPaths.getProjectsName}?purpose=impact`,
          {
            withCredentials: true,
          }
        );

        const res = response.data.response;

        if (res && Array.isArray(res)) {
          setProjects(res);
        } else {
          console.error("Error fetching projects or no projects available.");
        }
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      toast.error("Failed to fetch projects. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjectDetails = async () => {
    setIsLoadingPhases(true);
    try {
      const response = await ApiService({
        method: "GET",
        endpoint: `${apiPaths.getProjects}/${selectedProject}`,
      });

      if (response?.response) {
        setProjectDetails(response.response);
        setPhases(response.response.projectPhases || []);
      } else {
        console.error("Error fetching project details");
        toast.error("Error fetching project details. Please try again.");
      }
    } catch (err) {
      console.log("Error fetching project details:", err);
      toast.error("Error fetching project details. Please try again.");
    } finally {
      setIsLoadingPhases(false);
    }
  };

  const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProject(e.target.value);
    resetForm();
  };

  const resetForm = () => {
    setNewPhase({
      phaseName: "",
      phaseDescription: "",
      documentUrl: "",
      _id: ""
    });
    setEditMode(false);
    setEditPhaseId("");
  };

  const handleEditPhase = (phase: PhaseData) => {
    setNewPhase({
      phaseName: phase.phaseName,
      phaseDescription: phase.phaseDescription,
      documentUrl: phase.documentUrl || "",
      _id: phase._id
    });
    setEditMode(true);
    setEditPhaseId(phase._id);
  };

  const handleAddPhase = () => {
    if (!newPhase.phaseName) {
      toast.error("Phase name is required");
      return;
    }

    if (editMode && editPhaseId) {
      // Update existing phase in local state
      setPhases(phases.map(phase => 
        phase._id === editPhaseId ? { ...newPhase } : phase
      ));
    } else {
      // Add new phase to local state
      const newPhaseWithId = {
        ...newPhase,
        _id: `temp_${Date.now()}` // Temporary ID for new phases
      };
      setPhases([...phases, newPhaseWithId]);
    }
    
    resetForm();
  };

  const handleRemovePhase = (phaseId: string) => {
    setPhases(phases.filter(phase => phase._id !== phaseId));
  };

  const handleSaveAllPhases = async () => {
    setIsSaving(true);

    console.log(phases)
    try {
      const response = await ApiService({
        method: "POST",
        endpoint: `${apiPaths.updateProjectPhases}/${selectedProject}`,
        data: { projectPhases: phases },
      });

      if (response?.success) {
        toast.success("All phases saved successfully");
        fetchProjectDetails(); // Refresh data
      }
    } catch (error) {
      console.error("Error saving phases:", error);
      toast.error("Failed to save phases. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const Tooltip: React.FC<{ content: string }> = ({ content }) => (
    <div className="group relative inline-flex items-center ml-2">
      <Info className="w-4 h-4 text-black transition-colors cursor-help" />
      <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 absolute z-50 w-64 p-3 text-sm bg-black text-gray-100 rounded-lg shadow-lg -left-20 top-8">
        <div className="absolute w-3 h-3 bg-gray-900 transform rotate-45 -top-1.5 left-1/2 -ml-1.5"></div>
        <div className="relative">{content}</div>
      </div>
    </div>
  );

  const Label: React.FC<{ text: string; tooltip: string }> = ({ text, tooltip }) => (
    <p className="mb-2 flex items-center">
      {text}
      <Tooltip content={tooltip} />
    </p>
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src="loading_icon.gif" alt="logo" />
      </div>
    );
  }

  return (
    <div className="bg-[#fff] rounded-[16px] p-6 min-h-full">
      <div className="flex items-center gap-2 font-semibold h-full">
        <Link to="/settings">
          <h1 className="mb-0 text-[20px]">Settings</h1>
        </Link>
        <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
        <h1 className="mb-0 text-[20px] text-[#52bbab]">Phase Information</h1>
      </div>
      <p className="text-[14px] text-[#9e9b9b] mb-6">
        Manage your project phases by selecting a project and adding, editing, or deleting phases.
      </p>

      {/* Project Selection - Smaller Size */}
      <div className="mb-6">
        <p className="mb-2">Select Project</p>
        <select
          value={selectedProject}
          onChange={handleProjectChange}
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-2 border w-full max-w-xs"
        >
          <option value="">Select a project</option>
          {projects.map((project) => (
            <option key={project._id} value={project._id}>
              {project.name}
            </option>
          ))}
        </select>
      </div>

      {selectedProject && (
        <div className="space-y-6">
          {/* Input Form Section */}
          <div className="bg-gray-100 rounded-lg p-6 border">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Phase Title
                </label>
                <input
                  type="text"
                  value={newPhase.phaseName}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      setNewPhase({ ...newPhase, phaseName: e.target.value });
                    }
                  }}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                  placeholder="Enter phase title"
                />
                <p className="text-gray-500 text-xs mt-1 text-right">{newPhase.phaseName.length} / 50</p>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Phase Description
                </label>
                <textarea
                  value={newPhase.phaseDescription}
                  onChange={(e) => {
                    if (e.target.value.length <= 200) {
                      setNewPhase({ ...newPhase, phaseDescription: e.target.value });
                    }
                  }}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                  placeholder="Enter phase description"
                  rows={3}
                />
                <p className="text-gray-500 text-xs mt-1 text-right">{newPhase.phaseDescription.length} / 200</p>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  <Label text="Document URL (Optional)" tooltip={tooltips.documentUrl}></Label>
                </label>
                <input
                  type="text"
                  value={newPhase.documentUrl}
                  onChange={(e) => setNewPhase({ ...newPhase, documentUrl: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                  placeholder="Enter document URL"
                />
              </div>

              <button
                type="button"
                onClick={handleAddPhase}
                className="bg-emerald-500 text-white px-6 py-2 rounded-lg hover:bg-emerald-600 transition-colors"
              >
                {editMode ? "Update Phase" : "Add Phase"}
              </button>
              {editMode && (
                <button
                  type="button"
                  onClick={resetForm}
                  className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg ml-4 hover:bg-gray-400 transition-colors"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>

          {/* Phases Display Section */}
          <div className="overflow-x-auto">
            {isLoadingPhases ? (
              <div className="flex justify-center p-4">
                <p>Loading phases...</p>
              </div>
            ) : phases.length > 0 ? (
              <>
                <div className="flex space-x-4 pb-4">
                  {phases.map((phase, idx) => (
                    <div className="flex-none w-80" key={idx}>
                      <div className="relative bg-gray-100 h-40 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                        <div className="flex items-start h-12 min-h-[3rem]">
                          <h3 className="flex-1 font-semibold text-emerald-600 text-sm break-words mr-2 line-clamp-2">
                            {phase.phaseName || "N/A"}
                          </h3>
                          <div className="flex items-center space-x-2">
                            {phase.documentUrl && (
                              <a href={phase.documentUrl} target="_blank" rel="noopener noreferrer">
                                <FileText className="w-4 h-4 text-gray-500 hover:text-gray-600" />
                              </a>
                            )}
                            <button
                              onClick={() => handleEditPhase(phase)}
                              className="text-gray-500 hover:text-gray-600"
                            >
                              <Edit className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => handleRemovePhase(phase._id || "")}
                              className="text-gray-500 hover:text-gray-600"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </div>

                        <div className="mt-2 flex-1 overflow-y-auto">
                          <p className="text-gray-700 text-xs whitespace-pre-wrap break-words">
                            {phase.phaseDescription || "No description provided"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
                {/* Save All Button */}
                <div className="flex justify-center mt-6">
                  <button
                    onClick={handleSaveAllPhases}
                    disabled={isSaving}
                    className={`px-8 py-3 bg-emerald-500 text-white font-semibold rounded-lg hover:bg-emerald-600 transition-colors ${isSaving ? "opacity-50 cursor-not-allowed" : ""}`}
                  >
                    {isSaving ? "Saving..." : "Save All Phases"}
                  </button>
                </div>
              </>
            ) : (
              <div className="flex justify-center p-4">
                <p className="text-gray-500">No phases found for this project. Add your first phase above.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhaseInformation;