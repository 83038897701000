import { createSlice } from "@reduxjs/toolkit";

interface currencyData {
    symbol:string;
    name: string;
    rate: string;
}

const initialState: currencyData[] = [];

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrency(state, action) {
        state = action.payload;
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
