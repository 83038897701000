import { Cloudy, Droplet, Handshake, Leaf, TreePine, Info, Zap, Wrench, Heart } from 'lucide-react';
import React, { useState } from 'react';
import { LiaShoppingBagSolid } from 'react-icons/lia';
import { PiWavesLight } from 'react-icons/pi';
import { TbBatteryVerticalCharging } from 'react-icons/tb';
import { Trash2 } from 'lucide-react';

// Define the data structure for each impact item
export type ImpactItem = {
  icon: string;
  value: number;
  description?: string;
  type: string;
  unit?: string;
};

// Props type definition
type ImpactViewProps = {
  impactData: ImpactItem[];
};

const ImpactView = ({ impactData = [] }: ImpactViewProps) => {
  const [hoveredInfo, setHoveredInfo] = useState<number | null>(null);
  console.log(impactData)
  
  // Map icon name to component
  const getIcon = (iconName: string) => {
    const iconProps = { size: 32, strokeWidth: 1.5 }; // Smaller size and thinner stroke
    
    switch (iconName) {
      case 'tree':
        return <TreePine color="green" {...iconProps} />;
      case 'water':
        return <Droplet color="#3b82f6" {...iconProps} />;
      case 'air':
        return <Cloudy color="#6b7280" {...iconProps} />;
      case 'social':
        return <Handshake color="#8b5cf6" {...iconProps} />;
      case 'sustainability':
        return <Leaf color="#10b981" {...iconProps} />;
      case 'energy-produced':
        return <Zap color="#eab308" {...iconProps} />;
      case 'energy-saved':
        return <TbBatteryVerticalCharging color="red" {...iconProps} />;
      case 'plastic':
        return <PiWavesLight color="#00b4d8" {...iconProps} />;
      case 'sustainable-products':
        return <LiaShoppingBagSolid color="#00b4d8" {...iconProps} />;
      case 'jobs-produced':
        return <Wrench color="black" {...iconProps} />;
      case 'clean-water':
        return <Heart color="#00b4d8" {...iconProps} />;
        case 'waste':
          return <Trash2 color="black" {...iconProps} />;
      default:
        return <Zap color="#3b82f6" {...iconProps} />;
    }
  };

  const getImpactDescription = (iconId: string, impactType: string): string => {
    const baseDescription = (() => {
      switch (iconId) {
        case 'tree':
          return 'Number of clearly documented and verifiably planted trees';
        case 'water':
          return 'Amount of water conserved through sustainable practices';
        case 'air':
          return 'Amount of CO2 emissions clearly documented and verifiably reduced or offset';
        case 'social':
          return 'Number of people clearly supported and positively impacted';
        case 'sustainability':
          return 'Area clearly protected through conservation efforts';
        case 'energy-produced':
          return 'Amount of energy clearly produced from renewable sources';
        case 'energy-saved':
          return 'Amount of energy clearly saved through efficiency measures';
        case 'plastic':
          return 'Amount of plastic waste clearly removed from nature';
        case 'sustainable-products':
          return 'Number of eco-friendly products clearly integrated';
        case 'jobs-produced':
          return 'Number of sustainable and fair jobs created';
        case 'clean-water':
          return 'Clean Water Provided';
        case 'waste':
          return 'Amount of waste clearly avoided or recycled'
        default:
          return 'Description not available';
      }
    })();
  
    return `${baseDescription} (${impactType.charAt(0).toUpperCase() + impactType.slice(1)})`;
  };


  const getImpactUnit = (iconId: string): string | undefined => {
    switch (iconId) {
      case 'water':
        return 'L';
      case 'air':
        return 'kg';
      case 'sustainability':
        return '%';
      case 'energy-produced':
      case 'energy-saved':
        return 'kWh';
      case 'plastic':
        return 'kg';
      case 'clean-water':
        return 'L';
      default:
        return undefined; // No unit for other icons
    }
  };
  
  const getImpactShortText = (iconId: string): string => {
    switch (iconId) {
      case 'tree':
        return 'Trees Planted';
      case 'water':
        return 'Water Saved (L)';
      case 'air':
        return 'CO₂ Reduced';
      case 'social':
        return 'People Helped';
      case 'sustainability':
        return 'Biodiversity Protected (%)';
      case 'energy-produced':
        return 'Energy Produced (kWh)';
      case 'energy-saved':
        return 'Energy Saved (kWh)';
      case 'plastic':
        return 'Plastic Collected (kg)';
      case 'sustainable-products':
        return 'Sustainable Products';
      case 'jobs-produced':
        return 'Fair Jobs';
      case 'clean-water':
        return 'Clean Water (L)';
      case 'waste':
        return 'Waste Avoided (Kg)';
      default:
        return 'Impact Data';
    }
  };
  
  const formatNumber = (num: number): string => {
    return num.toLocaleString();
  };

  return (
    <div className="w-full p-4">
    {impactData.length > 0 && (
      <h2 className="text-xl  mb-6 text-gray-800">Environmental Impact</h2>
    )}
      
      <div className="grid grid-cols-3 gap-4">
        {impactData.map((item:any, index:any) => (
          // <div 
          //   key={index}
          //   className="flex flex-col items-center relative bg-[#fafafa] p-4 rounded-lg shadow-sm border border-gray-100 w-40 "
          // >
           
            
          //   <div className="mb-2 mt-2">
          //     {getIcon(item.icon)}
          //   </div>
            
          //   <div className="text-xl  mb-1">
          //   {formatNumber(item.value)}{getImpactUnit(item.icon)}
          //   </div>
            
          //   <div className="text-sm text-gray-600 text-center">
          //     {getImpactShortText(item.icon)}
          //   </div>
            
          //   <div 
          //     className="absolute top-2 left-2 cursor-pointer"
          //     onMouseEnter={() => setHoveredInfo(index)}
          //     onMouseLeave={() => setHoveredInfo(null)}
          //   >
          //     <Info size={16} className="text-gray-400 hover:text-gray-600" />
              
          //     {hoveredInfo === index && (
          //       <div className="absolute left-0 top-6 bg-gray-800 text-white p-2 rounded text-xs z-10 w-32">
          //         {/* {item.tooltip || (item.type === 'expected' ? 
          //           'Projected impact based on current trends' : 
          //           'Verified impact metrics from actual data')} */}
          //           {getImpactDescription(item.icon , item.type)}
          //       </div>
          //     )}
          //   </div>
          // </div>

          <div
          key={index}
          className="flex flex-col items-center relative bg-[#fafafa] p-4 rounded-lg shadow-sm border border-gray-100 w-40"
        >
          {/* Increase icon size using text-2xl class */}
          <div className="mb-2 mt-2 text-4xl">
            {getIcon(item.icon)}
          </div>
          
          {/* Decrease the value text size */}
          <div className="text-md mb-1"> {/* Changed from text-xl to text-lg */}
            {formatNumber(item.value)}{getImpactUnit(item.icon)}
          </div>
          
          <div className="text-sm text-gray-600 text-center">
            {getImpactShortText(item.icon)}
          </div>
          
          <div
            className="absolute top-2 left-2 cursor-pointer"
            onMouseEnter={() => setHoveredInfo(index)}
            onMouseLeave={() => setHoveredInfo(null)}
          >
            <Info size={16} className="text-gray-400 hover:text-gray-600" />
            
            {hoveredInfo === index && (
              <div className="absolute left-0 top-6 bg-gray-800 text-white p-2 rounded text-xs z-10 w-32">
                {getImpactDescription(item.icon, item.type)}
              </div>
            )}
          </div>
        </div>
        ))}
      </div>
    </div>
  );
};

export default ImpactView;