import { useSignup } from "./useSignup";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import signupImage from "../../../images/login/signup.png";
import LoginLogo from "../../../images/login/loginLogo.png";
import { setUserDetails } from "../../../redux/signupSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AuthVerification from "./../AuthVerification";
import { parsePhoneNumber, isValidPhoneNumber, CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';

interface FormData {
  fullName: string;
  email: string;
  phoneNumber: string;
  termsAndConditions: boolean;
}

interface ValidationError {
  field: keyof FormData | "submit";
  message: string;
}

interface CountryOption {
  code: CountryCode;
  label: string;
  dialCode: string;
}

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState<CountryCode>("DE");
  const { sendOtp, loading } = useSignup();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    phoneNumber: "",
    termsAndConditions: false
  });

  // Get all country codes and sort them alphabetically
  const getCountryOptions = (): CountryOption[] => {
    const countries = getCountries();
    return countries.map(country => ({
      code: country as CountryCode,
      dialCode: `+${getCountryCallingCode(country as CountryCode)}`,
      label: new Intl.DisplayNames(['en'], { type: 'region' }).of(country) || country
    })).sort((a, b) => a.label.localeCompare(b.label));
  };

  const countryOptions = getCountryOptions();

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const isValidName = (name: string): boolean => {
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿĀ-žḀ-ỿ][A-Za-zÀ-ÖØ-öø-ÿĀ-žḀ-ỿ\s'’-]{0,48}[A-Za-zÀ-ÖØ-öø-ÿĀ-žḀ-ỿ]$/;
    return nameRegex.test(name);
  };
  
  

  const validatePhoneNumber = (phone: string, country: CountryCode): boolean => {
    try {

      const phoneWithCode = phone.startsWith('+') ? phone : `+${getCountryCallingCode(country)}${phone}`;
      return isValidPhoneNumber(phoneWithCode, country);
    } catch (error) {
      return false;
    }
  };

  const formatPhoneNumber = (phone: string, country: CountryCode): string => {
    try {

      const phoneWithCode = phone.startsWith('+') ? phone : `+${getCountryCallingCode(country)}${phone}`;
      const phoneNumber = parsePhoneNumber(phoneWithCode, country);
      return phoneNumber ? phoneNumber.formatInternational() : phone;
    } catch (error) {
      return phone;
    }
  };

  const validateForm = (
    data: FormData,
  ): { isValid: boolean; errors: ValidationError[] } => {
    const validationErrors: ValidationError[] = [];
    let alertMessage = "";

    if (!data.fullName.trim()) {
      validationErrors.push({
        field: "fullName",
        message: "Full Name is required",
      });
      alertMessage = "Please enter your full name";
    } else if (!isValidName(data.fullName.trim())) {
      validationErrors.push({
        field: "fullName",
        message:
          "Please enter a valid name (letters, spaces, hyphens, and apostrophes only)",
      });
      alertMessage = "Invalid name format";
    }

    if (!data.email.trim()) {
      validationErrors.push({ field: "email", message: "Email is required" });
      alertMessage = alertMessage || "Please enter your email";
    } else if (!isValidEmail(data.email.trim())) {
      validationErrors.push({
        field: "email",
        message: "Please enter a valid email address",
      });
      alertMessage = alertMessage || "Invalid email format";
    }

    if (!data.phoneNumber.trim()) {
      validationErrors.push({
        field: "phoneNumber",
        message: "Phone number is required",
      });
      alertMessage = alertMessage || "Please enter your phone number";
    } else if (!validatePhoneNumber(data.phoneNumber.trim(), countryCode)) {
      validationErrors.push({
        field: "phoneNumber",
        message: "Please enter a valid phone number",
      });
      alertMessage = alertMessage || "Invalid phone number format";
    }

    if (!data.termsAndConditions) {
      validationErrors.push({
        field: "termsAndConditions",
        message: "Please agree to the terms and conditions",
      });
      alertMessage = alertMessage || "Please agree to the terms and conditions";
    }

    if (validationErrors.length > 0) {
      toast(alertMessage);
    }

    return {
      isValid: validationErrors.length === 0,
      errors: validationErrors,
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const trimmedFormData: FormData = {
      fullName: formData.fullName.trim(),
      email: formData.email.trim(),
      phoneNumber: formatPhoneNumber(formData.phoneNumber.trim(), countryCode),
      termsAndConditions: formData.termsAndConditions
    };

    const { isValid, errors: validationErrors } = validateForm(trimmedFormData);

    if (!isValid) {
      setErrors(validationErrors);
      return;
    }

    try {
      dispatch(setUserDetails(trimmedFormData));
      const data = await sendOtp(trimmedFormData.email);
      if (data) {
        toast.success("OTP Sent Successfully");
        navigate("/verify/signup");
      }
    } catch (error) {
      console.log(error);
      toast.error("Couldn't send OTP");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    setErrors((prev) => prev.filter((error) => error.field !== id));
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Remove any non-digit characters except plus sign
    const sanitizedValue = value.replace(/[^\d+]/g, '');
    setFormData((prev) => ({ ...prev, phoneNumber: sanitizedValue }));
    setErrors((prev) => prev.filter((error) => error.field !== 'phoneNumber'));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      termsAndConditions: e.target.checked
    }));
    setErrors(prev => prev.filter(error => error.field !== 'termsAndConditions'));
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative pb-10 pt-20">
      <img
        className="absolute lg:top-7 lg:right-7 top-5 left-auto right-auto w-[210px]"
        src={LoginLogo}
        alt="Logo"
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:px-10">
            <img
              className="lg:h-[100vh] max-w-full"
              src={signupImage}
              alt="Signup"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 flex items-center lg:px-10 lg:mt-0 mt-5">
            <div className="form-box w-full lg:px-10">
              <h3 className="login-heading mb-3">Sign up</h3>
              <p className="login-subText">
                Let's get you all set up so you can access your account.
              </p>

              <form className="mx-auto mt-7" onSubmit={handleSubmit}>
                <div className="relative mb-5">
                  <input
                    type="text"
                    id="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder="Full Name"
                    required
                  />
                  <label
                    htmlFor="fullName"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Full Name
                  </label>
                </div>
                
                <div className="relative mb-5">
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Email"
                    required
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Email
                  </label>
                </div>

                <div className="relative mb-5">
                  <div className="flex border">
                    <select
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value as CountryCode)}
                      className="border rounded-l-[4px] px-2 py-2 text-sm focus:outline-none border-b-black border-t-black border-l-black min-w-[120px]"
                    >
                      {countryOptions.map(option => (
                        <option key={option.code} value={option.code}>
                          {option.label} ({option.dialCode})
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      id="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handlePhoneChange}
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-r-[4px] border border-black appearance-none focus:outline-none"
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                </div>

                <div className="flex items-start mb-5">
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                        required
                        onChange={handleCheckboxChange}
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900">
                        I agree to all the{" "}
                        <span className="text-[var(--primary-color)]">
                          Terms
                        </span>{" "}
                        and{" "}
                        <span className="text-[var(--primary-color)]">
                          Privacy Policies
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="text-white min-w-full bg-[var(--primary-color)] focus:outline-none font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
                  disabled={loading}
                >
                  {loading ? "Submitting.." : "Submit"}
                </button>
              </form>

              <Link to="/login">
                <p className="text-center mt-5">
                  Already have an account?
                  <span className="text-[var(--primary-color)] cursor-pointer">
                    {" "}
                    Login
                  </span>
                </p>
              </Link>

              <p className="two-side-line mt-7">Or login with</p>
              <AuthVerification />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;