import React, { useEffect, useState } from "react";
import DashIcon from "../../../images/Dashboard/dashIcon.png";
import ButtonIcon from "../../../images/Dashboard/buttonIcon.png";
import { GraphData, InvestmentResponse, ProjectResponse } from "../Dashboard.props";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import LineChart from "../../../components/Graph/Line";
import { Link } from "react-router-dom";
import { useDashboard } from "../useDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/appStore";
import { Wallet } from "../../Settings/Components/Wallet.prop";
import { useManageWallet } from "../../Settings/Components/useManageWallet";
import formatNumberEuro from "../../../util/FormatNumberEuro";

interface PaginationState {
  currentPage: number;
  totalPages: number;
  totalDocs: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const Dashboard: React.FC = () => {
  const { getProjectsByUser  , getInvestmentDetails , getInvestedProjects} = useDashboard();
  const [investedProjectsLoader , setInvestedProjectsLoader] = useState<boolean>(false)
  const [allProjects, setAllProjects] = useState<InvestmentResponse[]>([]);

  const[investedProjects , setInvestedprojects] = useState([])
  const user = useSelector((store: RootState) => store?.user?.user);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(4);
  const [search, setSearch] = useState<string>("");
  const [totalPages, setTotalPages] = useState(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { getWallets, addWallet, loading, connectWallet } = useManageWallet();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [investMentDetails , setInvestMentDetails] = useState<any>({
    totalInvestment : 0, 
    totalReturns : 0,
    currentReturns:0 
  })


  const [pagination, setPagination] = useState<PaginationState>({
    currentPage: 1,
    totalPages: 1,
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });

  // useEffect(() => {
  //   async function fetchProjects() {
  //     console.log("USER ID IN DASHBOARD : ", user);

  //     if (user) {
  //       const response = await getProjectsByUser(
  //         String(user?._id),
  //         page,
  //         limit,
  //         search,
  //         "investor",
  //       );
  //       if (response && Array.isArray(response.docs)) {
  //         setAllProjects(response.docs);
  //         setTotalPages(response.totalPages);

  //         // console.log("NUMBER OF PAGES : ",response)
  //       } else {
  //         console.error(
  //           "Error fetching project details or no projects available.",
  //         );
  //       }
  //     }
  //     // setIsLoading(false)
  //   }

  //   fetchProjects();
  // }, [page, search, user]);

  useEffect(() => {
    const fetchWallets = async () => {
      const walletsData: Wallet[] | null = await getWallets();
      if (walletsData) {
        setWallets(walletsData)
      }
    };
    fetchWallets();
  }, []);

 

  useEffect(()=>{ 

    async function fetchInvestmentDetails(){
     const data = await getInvestmentDetails()

     if(data){
     const{totalInvestment} = data

     setInvestMentDetails((prev: any) => ({
       ...prev,
       totalInvestment: totalInvestment
     }))
    }
  }
    fetchInvestmentDetails()
    
  } , [])

  const [graphData, setGraphData] = useState<GraphData>({
    labels: [
      "Jan 2023",
      "Feb 2023",
      "Mar 2023",
      "Apr 2023",
      "May 2023",
      "Jun 2023",
      "Jul 2023",
      "Aug 2023",
      "Sep 2023",
      "Oct 2023",
      "Nov 2023",
      "Dec 2023",
    ],
    datasets: [
      {
        label: "Monthly Patient Count",
        data: [10, 9, 11, 12, 11, 8, 10, 11, 10, 12, 10],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 3,
      },
      {
        label: "Monthly Admission Count",
        data: [6, 8, 7, 7, 6, 5, 8, 9, 6, 7, 6],
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 3,
      },
      {
        label: "Monthly Discharge Count",
        data: [8, 6, 6, 9, 7, 4, 6, 6, 5, 6, 5],
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 3,
      },
    ],
  });

  const chartOptions = {
    scales: {
      x: {
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, 
        },
      },
    },
  };


  useEffect(()=>{

    async function fetchInvestedProjects(){
      setInvestedProjectsLoader(true)
    const response = await  getInvestedProjects(pagination.currentPage+"" , search)
    setInvestedProjectsLoader(false)
    console.log(response)

    if (response) {
      console.log(response.docs);
      setAllProjects(response.docs || []);
      setPagination((prev) => ({
        ...prev,
        totalPages: response.totalPages || 1,
        totalDocs: response.totalDocs || 0,
        hasNextPage: response.hasNextPage || false,
        hasPrevPage: response.hasPrevPage || false,
      }));
    }
    }
    fetchInvestedProjects()
  } ,[pagination.currentPage , search])


  const getPageNumbers = (): number[] => {
    const totalPages = pagination.totalPages;
    const currentPage = pagination.currentPage;
    const pageNumbers: number[] = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 4) {
        pageNumbers.push(1, 2, 3, 4);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pageNumbers.push(
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        );
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
  };

  const handleNextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };






  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src='loading_icon.gif' alt='logo' className="" />
      </div>
    );
  }

  return (
    <div className="">
      <div className="p-5 bg-[#fff] rounded-[16px]">
        <div>
          <h1 className="font-semibold text-[22px]">Dashboard</h1>
          <p>
          Track your invested projects, view your total investment, and monitor your returns.  See detailed performance data for each project and manage your sustainable investments. 
          </p>
        </div>
        <div className="flex bg-[#fff] p-6 rounded-[16px] justify-between items-center mt-5">
          <div className="flex bg-[#f4f4f4] gap-4 w-[280px] items-center p-4 rounded-[16px]">
            <div className="h-[40px] w-[40px] flex items-center justify-center bg-[#fa5a7d] rounded-full">
              <img className="h-[24px] w-[24px]" src={DashIcon} alt="" />
            </div>
            <div>
              <p className="text-[#7b7b7b]">Total Investment</p>
              <h6 className="font-semibold mt-1">
                {formatNumberEuro(investMentDetails?.totalInvestment)
                } </h6>
            </div>
          </div>
          <div className="flex bg-[#f4f4f4] gap-4 w-[280px] items-center p-4 rounded-[16px]">
            <div className="h-[40px] w-[40px] flex items-center justify-center bg-[#ff947a] rounded-full">
              <img className="h-[24px] w-[24px]" src={DashIcon} alt="" />
            </div>
            <div>
              <p className="text-[#7b7b7b]">Current Investment</p>
              <h6 className="font-semibold mt-1">
                {formatNumberEuro(0)}
                </h6>
            </div>
          </div>
          <div className="flex bg-[#f4f4f4] gap-4 w-[280px] items-center p-4 rounded-[16px]">
            <div className="h-[40px] w-[40px] flex items-center justify-center bg-[#3cd856] rounded-full">
              <img className="h-[24px] w-[24px]" src={DashIcon} alt="" />
            </div>
            <div>
              <p className="text-[#7b7b7b]">Total Returns</p>
              <h6 className="font-semibold mt-1">
              {formatNumberEuro(0)}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 mt-4">
        <div className="col-span-6 pr-3">
          {/* <div className="bg-[#52bbab] p-5 rounded-[19px]">
            <h1 className="text-[#fff] mb-3 font-semibold text-[20px]">
              KYC Pending
            </h1>
            <p className="text-[#fff] font-extralight text-[14px]">
              Your KYC verification is pending. Please complete your KYC to
              unlock full platform access and ensure a secure experience for
              transactions and investments.
            </p>
            <Link to="/">
              <div className="flex items-center gap-3 rounded-[100px] py-3 px-4 mt-4 w-[240px] bg-[#7ef4e1]">
                <p className="text-[#238a7a]">Complete your KYC</p>
                <img className="w-[24px] h-[24px]" src={ButtonIcon} alt="" />
              </div>
            </Link>
          </div> */}
        </div>
        {wallets.length === 0 && (
          <div className="col-span-6 ps-3">
            <div className="bg-[#52bbab] p-5 rounded-[19px]">
              <h1 className="text-[#fff] mb-3 font-semibold text-[20px]">
                Wallet Pending
              </h1>
              <p className="text-[#fff] font-extralight text-[14px]">
                Your wallet is not connected. Please connect your wallet to
                manage transactions, track investments, and engage with the
                platform seamlessly.
              </p>
              <Link to="/settings/managewallet">
                <div className="flex items-center gap-3 rounded-[100px] py-3 px-4 mt-4 w-[240px] bg-[#7ef4e1]">
                  <p className="text-[#238a7a]">Connect your wallet</p>
                  <img className="w-[24px] h-[24px]" src={ButtonIcon} alt="" />
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="graph bg-[#fff] mt-4 rounded-[16px] p-4">
        <h1 id="projectDeliveries" className="font-semibold text-[20px] mb-3">
          Project Deliveries
        </h1>
        <LineChart data={graphData} options={chartOptions} />
      </div>
      <div className="p-6 bg-[#fff] mt-4 rounded-[16px]">
        <div className="flex items-center justify-between mb-5">
          <h1 id="investedProjects" className="font-semibold text-[20px]">
            Invested Projects
          </h1>
          {/* Search Field */}
          <div className="flex items-center border border-[#56bbac] rounded-full overflow-hidden">
            <input
              type="text"
              placeholder="Search Projects"
              value={searchInput} 
              onChange={(e) => setSearchInput(e.target.value)}
              className="px-4 py-2 text-[14px] text-[#4a4a4a] outline-none flex-grow"
            />
            <button
              onClick={() => setSearch(searchInput)}
              className="px-4 py-2 bg-[#56bbac] text-white text-[14px] font-semibold hover:bg-[#479d94]"
            >
              Search
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          {allProjects.length > 0 && !investedProjectsLoader ? (
            allProjects.map((project, index) => (
              <div className="col-span-3 " key={index}>
                <div className="card shadow-4 rounded-[12px] h-[22rem] overflow-hidden flex flex-col justify-between">
                  <div>
                    <img
                      src={project.projectLogo}
                      alt={project.projectName}
                      className="w-full h-[200px] object-cover"></img>
                    
                    <p className="text-[10px] bg-[#56bbac] text-[#fff] w-full text-center py-[6px]">
                     
                    </p>
                  </div>
                  <div className="p-3 w-full flex flex-col justify-start ">
                    <div className="w-full ">
                      <h1 className="text-[16px] font-semibold">
                        {project.projectName}
                      </h1>
                      <p className="text-[12px] text-[#9e9b9b]">
                        Tokens Invested {project.totalTokens+""}
                      </p>
                      <h1 className="text-[16px] font-semibold my-1">
                      
                      </h1>
                      <p className="text-[12px] text-[#9e9b9b] text-justify">
                        {project.projectDescription.length > 50
                          ? project.projectDescription.slice(0, 50) + "..."
                          : project.projectDescription}
                      </p>
                    </div>
                    <div className="flex w-full mt-3 items-end justify-between ">
                      <p className="w-[60px] h-[24px] text-[10px] border border-[#ff6600] bg-[#fff0e5] text-[#ff8f45] text-center flex items-center justify-center rounded-[3px]">
                        Trending
                      </p>
                      <Link
                        to={`/exploredetails/${project.projectId}`}
                        className="bg-[#52bbab] text-[#fff] rounded-[4px] px-3 py-[2px]}"
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (

            investedProjectsLoader ? (
              <div className="col-span-12 flex items-center justify-center h-[200px]">
              <p className="text-center text-[18px] text-gray-500 font-semibold">
                Loading.....
             
              </p>
            </div>
            ):(
              <div className="col-span-12 flex items-center justify-center h-[200px]">
              <p className="text-center text-[18px] text-gray-500 font-semibold">
                {search
                  ? "No Projects Found For The Given Search Parameter ! "
                  : "You haven't Invested in any project yet , Invest in a project to get started"}
              </p>
            </div>
            )
          
     
           
          )}
        </div>
        {allProjects.length > 0 && (
              <div className="flex w-full justify-center mx-auto gap-2 mt-6 mb-1">
                <button
                  onClick={handlePrevPage}
                  disabled={!pagination.hasPrevPage}
                  className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasPrevPage ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <img
                    className="h-[10px] w-[7px]"
                    src={prevButton}
                    alt="Previous"
                  />
                </button>

                {getPageNumbers().map((pageNumber, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${pagination.currentPage === pageNumber ? "bg-[#56BBAC] text-white" : ""}`}
                  >
                    {pageNumber}
                  </button>
                ))}

                <button
                  onClick={handleNextPage}
                  disabled={!pagination.hasNextPage}
                  className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasNextPage ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <img
                    className="h-[10px] w-[7px]"
                    src={nextButton}
                    alt="Next"
                  />
                </button>
              </div>
            )}
      </div>
    </div>
  );
};

export default Dashboard;
