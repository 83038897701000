import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import signupSlice from "./signupSlice";
import loaderSlice from "./loaderSlice";
import currencySlice from "./currencySlice";
import  toggleSlice  from "./toggleSlice";


const appStore = configureStore({
  reducer: {
    user: userSlice,
    signup: signupSlice,
    loader: loaderSlice,
    currency:currencySlice,
    toggle:toggleSlice
  },
});

export type RootState = ReturnType<typeof appStore.getState>;
export default appStore;
