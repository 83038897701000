import { createSlice } from "@reduxjs/toolkit";




 const toggleSlice = createSlice({
    name: "toggle",
    initialState: {
        activeView:'project'
    },
    reducers: {
        setActiveView: (state, action) => {
            state.activeView = action.payload;
        },
    },
});


export const {setActiveView} = toggleSlice.actions
export default toggleSlice.reducer