import { Euro, Trash2 } from "lucide-react";
import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import formatNumberEuro from "../../../util/FormatNumberEuro";

interface Donation {
  amount: string;
  donorName: string;
}

interface ProjectResponse {
    _id: string;
    name: string;
    requiredInvestment: number;
    investmentRaised : number;
  }
  
const Donations: React.FC = () => {

//   const [donations, setDonations] = useState<Donation[]>([]);

const [projects, setProjects] = useState<ProjectResponse[]>([]);
const [isLoading, setIsLoading] = useState(true);
const [selectedProject, setSelectedProject] = useState<ProjectResponse>({ _id: '', name: '', requiredInvestment: 0, investmentRaised: 0 });
const [newDonation, setNewDonation] = useState<Donation>({ donorName: '', amount: '' });
const maxInvestment = selectedProject.requiredInvestment - selectedProject.investmentRaised;


useEffect(() => {
    async function fetchProjects() {
        try{
            setIsLoading(true)
            const response = await ApiService({
                method: 'GET',
                endpoint: `${apiPaths.getProjectsName}?purpose=impact`
            })

            console.log("Projects fetched in donations", response)

            const res = response.response;

            if (res && Array.isArray(res)) { 
            setProjects(res);
            } else {
            console.error(
                "Error fetching project details or no projects available.",
            );
            }
        }catch(err){
            toast.error("Error fetching projects.")
            console.log("Error fetching projects in donations", err)
        }
      setIsLoading(false);
    }
    fetchProjects();
  }, []);

const handleAddDonation = async () => {
    if (!selectedProject._id || !newDonation.donorName.trim() || !newDonation.amount) {
        toast.error("Please fill all the required Fields.")
        return
    }

    if(parseFloat(newDonation.amount) > maxInvestment){
        toast.error("Amount should be less than or equal to the remaining investment amount.")
        return
    }

    try{
        const response = await ApiService({
            method: 'POST',
            endpoint: apiPaths.addDonation,
            data: {...newDonation, projectId:selectedProject._id}
        });

        // setDonations([...(donations || []), newDonation]);
        setNewDonation({
            donorName: '',
            amount: ''
        });
        setSelectedProject({ _id: '', name: '', requiredInvestment: 0, investmentRaised: 0 });

        setProjects(projects.map(project => {
          if(project._id === selectedProject._id) {
            return {...project, investmentRaised: project.investmentRaised + parseFloat(newDonation.amount)}
          }
          return project
        }))

        toast.success("Donation added successfully.")

    }catch(err){
        toast.error("Error adding donations.")
        console.log("Error adding donations", err)
    }

    
};

// const handleRemoveDonation = (index: number) => {
//     const updatedDonations = [...(donations || [])];
//     updatedDonations.splice(index, 1);
//     setDonations(updatedDonations);
// };


  return (
    <div className="space-y-6">
      {/* Input Form Section */}
      <div className="bg-gray-100 rounded-lg p-6 border">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">
              <p className="mb-2 flex items-center"> Project </p> 
            </label>
            <select
                name="projectId"
                disabled={isLoading}
                value={selectedProject._id}
                onChange={(e)=>setSelectedProject(projects.find(project => project._id === e.target.value) 
                  ||
                  { _id: '', name: '', requiredInvestment: 0, investmentRaised: 0 })}
                className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
            >
                <option value="">Select a project</option>
                {projects.map((project, index) => (
                <option key={index} value={project._id}>
                    {project.name}
                </option>
                ))}
            </select>
          </div>

          {selectedProject._id && <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">
              <p className="mb-2 flex items-center"> Project Details </p>
            </label>
            <div className="bg-gray-200 rounded-lg p-3 border">
              <div className="flex items-center justify-between">
                <p className="text-gray-700 text-sm">Required Investment</p>
                <p className="text-emerald-600 text-lg font-semibold">
                  {formatNumberEuro(selectedProject.requiredInvestment)}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-gray-700 text-sm">Investment Raised</p>
                <p className="text-emerald-600 text-lg font-semibold">
                  {formatNumberEuro(selectedProject.investmentRaised)}
                </p>  
              </div>
            </div>
          </div>}

          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">
              <p className="mb-2 flex items-center"> Donor Name </p> 
            </label>
            <input
              type="text"
              value={newDonation.donorName}
              onChange={(e) => {
                if (e.target.value.length <= 50) {
                  setNewDonation({ ...newDonation, donorName: e.target.value });
                }
              }}
              className="w-full px-4 py-2 rounded-lg border border-emerald-300  "
              placeholder="Who made this donation?"
            />
            <p className="text-gray-500 text-xs mt-1 text-right">
              {newDonation.donorName.length} / 50
            </p>
          </div>

          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2">
              Amount
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Euro className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={newDonation.amount}
                onChange={(e) => setNewDonation({ ...newDonation, amount: e.target.value })}
                className="w-full pl-10 px-4 py-2 rounded-lg border border-emerald-300 "
                placeholder="0.00"
                min="0"
                max={maxInvestment}
                step="0.01"
              />
            </div>
          </div>

          <button
            type="button"
            onClick={handleAddDonation}
            className="bg-emerald-500 text-white px-6 py-2 rounded-lg hover:bg-emerald-600 transition-colors"
          >
            Add Donation
          </button>
        </div>
      </div>

      {/* Donations Display Section */}
      {/* <div className="overflow-x-auto">
        <div className="flex space-x-4 pb-4">
          {(donations || []).map((donation, idx) => (
            <div className="flex-none w-80" key={idx}>
              <div className="relative bg-gray-100 h-40 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                <div className="flex items-start justify-between h-12 min-h-[3rem]">
                  <h3 className="flex-1 font-semibold text-emerald-600 text-lg break-words mr-2">
                    €{parseFloat(donation.amount).toFixed(2)}
                  </h3>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleRemoveDonation(idx)}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>

                <div className="mt-2 flex-1">
                  <p className="text-gray-700 text-sm break-words">
                    {donation.donorName || "Anonymous"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>      */}


      {/* Total Donations Summary */}
      {/* {donations && donations.length > 0 && (
        <div className=" p-4 rounded-lg border ">
          <div className="flex justify-between items-center">
            <span className="font-semibold text-emerald-700">Total Donations:</span>
            <span className="text-emerald-700 font-bold text-lg">
              €{donations.reduce((sum, donation) => sum + parseFloat(donation.amount || "0"), 0).toFixed(2)}
            </span>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Donations;
