import React, { useState, useRef } from 'react';
import { Cloudy, Droplet, Handshake, Leaf, TreePine, Zap, Info, Heart, ChevronLeft, ChevronRight, Trash2 } from 'lucide-react';
import { FormDataInterface } from '../interface';
import { TbBatteryVerticalCharging } from "react-icons/tb";
import { PiWavesLight } from "react-icons/pi";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { Wrench } from 'lucide-react';

export type ImpactItem = {
  icon: string;
  value: number;
  type: 'expected' | 'current';
  unit?: string;
};

interface Props {
  formData: FormDataInterface;
  setFormData: any;
}

const impactTypes = [
  {
    id: 'tree',
    icon: <TreePine color="green" size={32} strokeWidth={1.5} />,
    label: 'Trees Planted',
    description: 'Number of clearly documented and verifiably planted trees',
    unit: ''
  },
  {
    id: 'water',
    icon: <Droplet color="#3b82f6" size={32} strokeWidth={1.5} />,
    label: 'Water Saved',
    description: 'Amount of water conserved through sustainable practices',
    unit: 'L'
  },
  {
    id: 'air',
    icon: <Cloudy color="#6b7280" size={32} strokeWidth={1.5} />,
    label: 'CO₂ Reduced',
    description: 'Amount of CO2 emissions clearly documented and verifiably reduced or offset',
    unit: 'kg'
  },
  {
    id: 'social',
    icon: <Handshake color="#8b5cf6" size={32} strokeWidth={1.5} />,
    label: 'People Helped',
    description: 'Number of people clearly supported and positively impacted',
    unit: ''
  },
  {
    id: 'sustainability',
    icon: <Leaf color="#10b981" size={32} strokeWidth={1.5} />,
    label: 'Biodiversity Protected',
    description: 'Area clearly protected through conservation efforts',
    unit: '%'
  },
  {
    id: 'energy-produced',
    icon: <Zap color="#eab308" size={32} strokeWidth={1.5} />,
    label: 'Energy Produced',
    description: 'Amount of energy clearly produced from renewable sources',
    unit: 'kWh'
  },
  {
    id: 'energy-saved',
    icon: <TbBatteryVerticalCharging color='red' size={32} strokeWidth={1.5} />,
    label: 'Energy Saved',
    description: 'Amount of energy clearly saved through efficiency measures',
    unit: 'kWh'
  },
  {
    id: 'plastic',
    icon: <PiWavesLight color='#00b4d8' size={32} strokeWidth={1.5} />,
    label: 'Plastic Collected',
    description: 'Amount of plastic waste clearly removed from nature',
    unit: 'kg'
  },
  { 
    id: "sustainable-products",
    icon: <LiaShoppingBagSolid color='#00b4d8' size={32} strokeWidth={0.5} />,
    label: 'Sustainable Products Produced',
    description: 'Number of eco-friendly products clearly integrated',
    unit: ''
  },
  {
    id: "jobs-produced",
    icon: <Wrench size={32} strokeWidth={1.5} color="black" />,
    label: 'Fair Jobs',
    description: 'Number of sustainable and fair jobs created',
    unit: ''
  },
  {
    id: "clean-water",
    icon: <Heart size={32} strokeWidth={1.5} color="#00b4d8" />,
    label: 'Clean Water',
    description: 'Clean Water Provided',
    unit: 'L'
  },
  {
    id: "waste",
    icon: <Trash2 size={32} strokeWidth={1.5} color="black" />,
    label: 'Waste Reduced',
    description: 'Amount of waste clearly avoided or recycled',
    unit: 'kg'
  }
];

// Create a component for the environmental impact form
const EnvironmentalImpactForm = ({ formData, setFormData }: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!formData.impactData) {
      setFormData({
        ...formData,
        impactData: []
      });
    }
  }, []);

  // Initial state for new impact entry
  const initialImpactState = {
    icon: 'tree',
    value: '',
    type: 'expected'
  };

  // State for new impact entry
  const [newImpact, setNewImpact] = useState(initialImpactState);

  // State to track editing mode
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  // Get list of impact types that are not already selected
  const getAvailableImpactTypes = () => {
    // Get all impact ids that are already in formData
    const selectedImpactIds = formData.impactData?.map(item => item.icon) || [];
    
    // If we're in edit mode, we should include the currently editing impact type
    if (isEditing && editIndex >= 0 && formData.impactData?.[editIndex]) {
      const currentEditingId = formData.impactData[editIndex].icon;
      return impactTypes.filter(type => 
        !selectedImpactIds.includes(type.id) || type.id === currentEditingId
      );
    }
    
    // Otherwise, filter out all selected impact types
    return impactTypes.filter(type => !selectedImpactIds.includes(type.id));
  };

  // Handle selecting an impact type
  const handleSelectImpactType = (typeId: any) => {
    setNewImpact({
      ...newImpact,
      icon: typeId,
      value: ''
    });
    setIsEditing(false);
    setEditIndex(-1);
  };

  // Handle edit of an existing impact
  const handleEditImpact = (index: number) => {
    if (formData.impactData && formData.impactData[index]) {
      const itemToEdit = formData.impactData[index];
      setNewImpact({
        icon: itemToEdit.icon,
        value: itemToEdit.value.toString(),
        type: itemToEdit.type
      });
      setIsEditing(true);
      setEditIndex(index);
    }
  };

  // Handle adding or updating an impact item
  const handleAddOrUpdateImpact = () => {
    if (newImpact.icon && newImpact.value) {
      const selectedImpactType = impactTypes.find(type => type.id === newImpact.icon);

      const impactItem: ImpactItem = {
        icon: newImpact.icon,
        value: parseFloat(newImpact.value.toString()),
        type: newImpact.type as 'expected' | 'current',
        unit: selectedImpactType?.unit || ''
      };

      let updatedImpacts;

      if (isEditing && editIndex >= 0) {
        // Update existing impact
        updatedImpacts = [...(formData.impactData || [])];
        updatedImpacts[editIndex] = impactItem;
      } else {
        // Add new impact
        updatedImpacts = [...(formData.impactData || []), impactItem];
      }

      setFormData({
        ...formData,
        impactData: updatedImpacts
      });

      // Reset form
      setNewImpact(initialImpactState);
      setIsEditing(false);
      setEditIndex(-1);
    }
  };

  // Handle removing an impact item
  const handleRemoveImpact = (index: any) => {
    const updatedImpacts = [...(formData.impactData || [])];
    updatedImpacts.splice(index, 1);
    setFormData({
      ...formData,
      impactData: updatedImpacts
    });

    // If removing the item we're currently editing, reset the form
    if (index === editIndex) {
      setNewImpact(initialImpactState);
      setIsEditing(false);
      setEditIndex(-1);
    }
  };

  // Scroll functions
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  // Get the selected impact type details
  const selectedImpactType = impactTypes.find(type => type.id === newImpact.icon);
  
  // Get available impact types that haven't been selected yet
  const availableImpactTypes = getAvailableImpactTypes();

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-medium text-gray-800">Environmental Impact Data</h2>

      {/* Input Form Section */}
      <div className="bg-gray-50 rounded-lg p-6 border border-gray-200 shadow-sm">
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Impact Type
              </label>
              <div className="relative">
                {availableImpactTypes.length > 3 && (
                  <button 
                    onClick={scrollLeft}
                    className="absolute left-0 top-1/2 -translate-y-1/2 -ml-3 bg-white rounded-full shadow-md p-1 z-10"
                  >
                    <ChevronLeft size={20} />
                  </button>
                )}
                
                <div 
                  ref={scrollContainerRef}
                  className="flex overflow-x-auto pb-3 no-scrollbar" 
                  style={{ 
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                  }}
                >
                  {availableImpactTypes.length > 0 ? (
                    availableImpactTypes.map((type) => (
                      <div
                        key={type.id}
                        onClick={() => handleSelectImpactType(type.id)}
                        className={`cursor-pointer flex-shrink-0 flex flex-col items-center justify-center p-2 rounded-lg border mx-1 min-w-[200px] gap-x-4 h-24 ${
                          newImpact.icon === type.id
                            ? 'bg-blue-50 border-blue-300'
                            : 'border-gray-200 hover:bg-gray-50'
                        }`}
                      >
                        <div>{type.icon}</div>
                        <span className="text-sm mt-1 text-center">{type.label}</span>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center w-full p-4 text-gray-500">
                      All impact types have been added. Edit or remove existing impacts to add new ones.
                    </div>
                  )}
                </div>
                
                {availableImpactTypes.length > 3 && (
                  <button 
                    onClick={scrollRight}
                    className="absolute right-0 top-1/2 -translate-y-1/2 -mr-3 bg-white rounded-full shadow-md p-1 z-10"
                  >
                    <ChevronRight size={20} />
                  </button>
                )}
              </div>

              {/* Show description of selected impact type */}
              {selectedImpactType && (
                <div className="mt-4 text-sm text-gray-600 flex items-start">
                  <Info size={16} className="mr-1 mt-0.5 text-gray-400" />
                  <span>{selectedImpactType.description}</span>
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Value Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Value
                </label>
                <div className="relative">
                  <input
                    type="number"
                    value={newImpact.value}
                    onChange={(e) => setNewImpact({ ...newImpact, value: e.target.value })}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    placeholder="Enter value"
                    min="0"
                    step="0.01"
                  />
                  {selectedImpactType?.unit && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">
                        {selectedImpactType.unit}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {/* Impact Status */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Status
                </label>
                <div className="flex space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-blue-600"
                      name="type"
                      value="expected"
                      checked={newImpact.type === 'expected'}
                      onChange={() => setNewImpact({ ...newImpact, type: 'expected' })}
                    />
                    <span className="ml-2 text-gray-700">Expected Impact</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-green-600"
                      name="type"
                      value="current"
                      checked={newImpact.type === 'current'}
                      onChange={() => setNewImpact({ ...newImpact, type: 'current' })}
                    />
                    <span className="ml-2 text-gray-700">Current Impact</span>
                  </label>
                </div>
              </div>
            </div>

            <button
              type="button"
              onClick={handleAddOrUpdateImpact}
              disabled={!newImpact.icon || !newImpact.value}
              className={`${
                'bg-green-500 hover:bg-green-600'
              } text-white px-6 py-2 rounded-lg transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed w-48`}
            >
              {isEditing ? 'Update Impact' : 'Add Impact'}
            </button>
          </div>
        </div>
      </div>

      {/* Display Added Impact Items */}
      {formData.impactData && formData.impactData.length > 0 && (
        <div>
          <h3 className="text-lg font-medium text-gray-700 mb-3">Added Impact Items</h3>
          <div className="flex overflow-x-auto pb-3 no-scrollbar gap-3">
            {formData.impactData.map((item, idx) => {
              const impactType = impactTypes.find(type => type.id === item.icon);
              return (
                <div 
                  key={idx} 
                  className={`cursor-pointer flex-shrink-0 flex flex-col items-center justify-center p-4 rounded-lg border mx-1 min-w-[200px] gap-x-4 h-32 relative ${
                    editIndex === idx ? 'bg-blue-50 border-blue-300' : 'border-gray-200 hover:bg-gray-50'
                  }`}
                  onClick={() => handleEditImpact(idx)}
                >
                  <div className="absolute top-2 right-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImpact(idx);
                      }}
                      className="text-gray-400 hover:text-red-500"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="flex items-center">
                    <div className="mr-3">
                      {impactType?.icon}
                    </div>
                    <div>
                      <div className="text-lg font-medium">
                        {item.value}{item.unit}
                      </div>
                      <div className="text-sm text-gray-600">
                        {impactType?.label}
                      </div>
                      <div className="mt-2">
                        <span className={`text-xs px-2 py-1 rounded-full ${
                          item.type === 'expected' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                        }`}>
                          {item.type === 'expected' ? 'Expected' : 'Current'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default EnvironmentalImpactForm;

export { impactTypes };