import React, { useState } from "react";
import RightIcon from "../../images/Settings/right.png";
import axios from "axios";
import { apiPaths } from "../../service/apiPaths";
import { useProjectManager } from "./useProjectManager";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { parsePhoneNumber, isValidPhoneNumber, CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';


interface FileUploadResponse {
  files: any[]; // Response structure assuming the file URLs or names are returned
  message: string;
}

interface CountryOption {
  code: CountryCode;
  label: string;
  dialCode: string;
}

interface FormData {
  phone: string;
  address?: string;
  experience?: number;
  linkedInProfile: string;
  governmentId: string; // Array of file names for government IDs
  referenceContact: string;
  previousProjects: string[];
  website: string;
  certifications: string[];
}

interface Files {
  certifications: File[];
  governmentId: File[];
}

const ProjectManager: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    phone: "",
    address: "",
    experience: 0,
    linkedInProfile: "",
    referenceContact: "",
    previousProjects: [],
    website: "",
    governmentId: "",
    certifications: [],
  });
    const [phoneCountryCode, setPhoneCountryCode] = useState<CountryCode>("DE");
    const[referenceCountryCode , setReferneceCountryCode]= useState<CountryCode>("DE")

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const user = useSelector((store: any) => store.user.user);

  const {
    becomeProjectManager,
    getProjectManagerApplications,
    loading,
    error,
  } = useProjectManager();

  const [files, setFiles] = useState<Files>({
    certifications: [],
    governmentId: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imageUploadLoader, setImageUploadLoader] = useState(false);
  const [getApplicationLoader, setGetApplicationLoader] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const savedData = sessionStorage.getItem("projectManagerFormdata");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const getCountryOptions = (): CountryOption[] => {
      const countries = getCountries();
      return countries.map(country => ({
        code: country as CountryCode,
        dialCode: `+${getCountryCallingCode(country as CountryCode)}`,
        label: new Intl.DisplayNames(['en'], { type: 'region' }).of(country) || country
      })).sort((a, b) => a.label.localeCompare(b.label));
    };

 const countryOptions = getCountryOptions();



  const validatePhoneNumber = (phone: string, country: CountryCode): boolean => {
    try {

      const phoneWithCode = phone.startsWith('+') ? phone : `+${getCountryCallingCode(country)}${phone}`;
      return isValidPhoneNumber(phoneWithCode, country);
    } catch (error) {
      return false;
    }
  };

  const formatPhoneNumber = (phone: string, country: CountryCode): string => {
    try {

      const phoneWithCode = phone.startsWith('+') ? phone : `+${getCountryCallingCode(country)}${phone}`;
      const phoneNumber = parsePhoneNumber(phoneWithCode, country);
      return phoneNumber ? phoneNumber.formatInternational() : phone;
    } catch (error) {
      return phone;
    }
  };
  useEffect(() => {
    sessionStorage.setItem("projectManagerFormData", JSON.stringify(formData));
  }, [formData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;

    if (name === "previousProjects") {
      const projectsArray = value
        ? value.split(",").map((item) => item.trim())
        : [];

      setFormData((prev) => ({
        ...prev,
        previousProjects: projectsArray,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]:
          name === "phone" || name === "experience" ? Number(value) : value,
      }));
    }
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof Files,
  ) => {
    const fileList = e.target.files;
    if (!fileList) return;

    setFiles((prev) => ({
      ...prev,
      [key]: [...prev[key], ...Array.from(fileList)], // Ensuring previous files aren't overwritten
    }));
  };
  const validateForm = () => {
    let errors: string[] = [];
  
    // Validate Phone Number (Required)
    if (!validatePhoneNumber(formData.phone || '', phoneCountryCode)) {
      toast.error("Enter a valid phone number");
      errors.push("Please enter a valid phone number for your country.");
      return false;
    }
  
    // Validate Reference Contact (Optional)
    if (formData.referenceContact && !validatePhoneNumber(formData.referenceContact, referenceCountryCode)) {
      toast.error("Enter a valid reference contact number");
      errors.push("Please enter a valid phone number for the reference contact.");
      return false;
    }
  
    // Validate LinkedIn Profile (Optional)
   // Validate LinkedIn Profile (Optional)
if (formData.linkedInProfile) {
  if (!formData.linkedInProfile.startsWith("https://")) {
    formData.linkedInProfile = "https://" + formData.linkedInProfile;
  }
}

// Validate Website (Required)
if (!formData.website) {
  toast.error("Website URL is required");
  errors.push("Website URL is required");
  return false;
}

if (!formData.website.startsWith("https://")) {
  formData.website = "https://" + formData.website;
}

  
    // Validate Government ID (Required)
    if (files.governmentId.length === 0) {
      toast.error("Please select Government ID");
      errors.push("Please select Government ID");
      return false;
    }
  
    // Validate Certifications (Optional)
    if (files.certifications.length === 0) {
      console.log("Certifications are optional. Skipping validation.");
    }
  
    setErrorMessages(errors);
    console.log(errors.length);
    return errors.length === 0;
  };
  

  const uploadFiles = async () => {
    if (!validateForm()) {
      return;
    }

    setImageUploadLoader(true);
    try {
      let updatedFormData = { ...formData  };

      if (files.governmentId.length > 0) {
        let formDataInstance = new FormData();
        files.governmentId.forEach((file) =>
          formDataInstance.append("files", file),
        );

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );
          updatedFormData.governmentId = response.data.files[0];
          console.log(response);
        } catch (error) {
          console.error("Error uploading government IDs:", error);
          throw error;
        }
      }

      if (files.certifications.length > 0) {
        let formDataInstance = new FormData();
        files.certifications.forEach((file) =>
          formDataInstance.append("files", file),
        );

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          updatedFormData.certifications = response.data.files;

          console.log(response);
        } catch (error) {
          console.error("Error uploading certifications:", error);
          throw error;
        }
      }

      updatedFormData.phone = formatPhoneNumber(updatedFormData.phone , phoneCountryCode)
      updatedFormData.referenceContact = formatPhoneNumber(updatedFormData.referenceContact , referenceCountryCode)

      

      return handleSubmit(updatedFormData);
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    } finally {
      setImageUploadLoader(false);
    }
  };
  const checkApplicationStatus = async () => {
    try {
      setGetApplicationLoader(true);
      const response = await getProjectManagerApplications();
      console.log(response);
   

      if (response) {
        setErrorMessage("Application Already Exists");
      } else {
        setErrorMessage(null);
      }
    } catch (error) {
      console.error("Error fetching application status", error);
      setErrorMessage(null);
    } finally {
      setGetApplicationLoader(false);
    }
  };

  useEffect(() => {
    checkApplicationStatus();
  }, []);

  const handleSubmit = async (browserFormData: FormData) => {
    console.log(browserFormData);
    setIsLoading(true);
  
    const filteredFormData: Partial<FormData> = {}; // Use Partial to allow missing keys
  
    if (browserFormData.phone) {
      filteredFormData.phone = browserFormData.phone;
    }
    if (browserFormData.address && browserFormData.address.trim() !== "") {
      filteredFormData.address = browserFormData.address.trim();
    }
    if (browserFormData.experience !== undefined) {
      filteredFormData.experience = browserFormData.experience;
    }
    if (browserFormData.linkedInProfile && browserFormData.linkedInProfile.trim() !== "") {
      filteredFormData.linkedInProfile = browserFormData.linkedInProfile.trim();
    }
    if (browserFormData.governmentId) {
      filteredFormData.governmentId = browserFormData.governmentId;
    }
    if (browserFormData.referenceContact) {
      filteredFormData.referenceContact = browserFormData.referenceContact;
    }
    if (browserFormData.previousProjects && browserFormData.previousProjects.filter(item => item && item.trim() !== "").length > 0) {
      filteredFormData.previousProjects = browserFormData.previousProjects.filter(item => item && item.trim() !== "");
    }
    if (browserFormData.website && browserFormData.website.trim() !== "") {
      filteredFormData.website = browserFormData.website.trim();
    }
    if (browserFormData.certifications && browserFormData.certifications.filter(item => item && item.trim() !== "").length > 0) {
      filteredFormData.certifications = browserFormData.certifications.filter(item => item && item.trim() !== "");
    }
  
    try {
      const response = await becomeProjectManager(filteredFormData); // Send the filtered object
      console.log(response);
      if (response) {
        toast.success("Request Sent Successfully");
        checkApplicationStatus();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
   const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value  , name} = e.target;
      const sanitizedValue = value.replace(/[^\d+]/g, '');
      if(name==='phoneNumber'){
     
      setFormData((prev) => ({ ...prev, phone: sanitizedValue }));
      }else{
        setFormData((prev)=>({ ...prev, referenceContact: sanitizedValue }));
      }
      
    };
  return (
    <div className="bg-[#fff] rounded-[16px] p-6 min-h-full">
      <div className="flex items-center gap-2 font-semibold h-full">
        <Link to="/settings">
          <h1 className="mb-0 text-[20px]">Settings</h1>
        </Link>
        <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
        <h1 className="mb-0 text-[20px] text-[#52bbab]">Project Manager</h1>
      </div>
      <p className="text-[14px] text-[#9e9b9b]">
        Manage your projects by filling in the required details and uploading
        necessary documents.
      </p>

      {user && user?.isProjectManager ? (
        <div className="w-full flex justify-center items-center text-xl h-[20rem]">
          <p>You Already Are A Project Manager.</p>
        </div>
      ) : getApplicationLoader ? (
        <>
          <div className="w-full  flex justify-center items-center text-xl ">
            <p>Fetching Any Existing Applications...</p>
          </div>
        </>
      ) : errorMessage ? (
        <>
          <div className="w-full flex justify-center items-center text-xl h-[20rem]">
            <p>
              {" "}
              Your application has been received and is now pending approval
            </p>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-12 gap-x-12 gap-y-4">
          {/* Phone */}
          <div className="col-span-6">
            <p className="mb-2">Phone</p>
            <div className="flex gap-2">
        <select
          value={phoneCountryCode}
          onChange={(e) => setPhoneCountryCode(e.target.value as CountryCode)}
          className="bg-[#eeeeee] w-[8rem] rounded-[12px] px-2 text-[14px] border"
        >
          {countryOptions.map(option => (
            <option key={option.code} value={option.code}>
              {option.label} ({option.dialCode})
            </option>
          ))}
        </select>
        <input
          type="tel"
          name="phoneNumber"
          id="phoneNumber"
          value={formData.phone}
          onChange={handlePhoneChange}
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border flex-1"
          placeholder="Phone Number"
          required
        />
      </div>
          </div>

          <div className="col-span-6">
            <p className="mb-2">Address</p>
            <input
              type="text"
              name="address"
              value={formData.address || ""}
              onChange={handleChange}
              placeholder="Enter Address"
              className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
            />
          </div>

          {/* Experience */}
          <div className="col-span-6">
            <p className="mb-2">Experience (Optional)</p>
            <input
              type="number"
              name="experience"
              value={formData.experience || ""}
              onChange={handleChange}
              placeholder="Enter Experience (Can Leave Empty If Zero)"
              className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
            />
          </div>

          {/* LinkedIn Profile */}
          <div className="col-span-6">
            <p className="mb-2">LinkedIn Profile (Optional)</p>
            <input
              type="text"
              name="linkedInProfile"
              value={formData.linkedInProfile}
              onChange={handleChange}
              placeholder="Enter Your LinkedIn Profile Url"
              className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
            />
          </div>

          {/* Government IDs */}
          <div className="col-span-6">
            <p className="mb-2">Government IDs</p>
            <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
              <input
                type="file"
                multiple
                className="hidden"
                onChange={(e) => handleFileChange(e, "governmentId")}
              />
              <div className="flex justify-between items-center">
                <p className="text-gray-500 text-[14px]">
                  {files.governmentId.length > 0
                    ? `${files.governmentId.length} files selected`
                    : "Upload your government IDs"}
                </p>
              </div>
            </label>
            <ul className="text-gray-600 text-[14px] mt-2">
              {files.governmentId.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>

          {/* Certifications */}
          <div className="col-span-6">
            <p className="mb-2">Certifications (Optional)</p>
            <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
              <input
                type="file"
                multiple
                className="hidden"
                onChange={(e) => handleFileChange(e, "certifications")}
              />
              <div className="flex justify-between items-center">
                <p className="text-gray-500 text-[14px]">
                  {files.certifications.length > 0
                    ? `${files.certifications.length} files selected`
                    : "Upload your certifications"}
                </p>
              </div>
            </label>
            <ul className="text-gray-600 text-[14px] mt-2">
              {files.certifications.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>

          {/* Reference Contact */}
          <div className="col-span-6">
            <p className="mb-2">Reference Contact (Optional)</p>
            <div className="flex gap-2">
        <select
          value={referenceCountryCode}
          onChange={(e) => setReferneceCountryCode(e.target.value as CountryCode)}
          className="bg-[#eeeeee] w-[8rem] rounded-[12px] px-2 text-[14px] border"
        >
          {countryOptions.map(option => (
            <option key={option.code} value={option.code}>
              {option.label} ({option.dialCode})
            </option>
          ))}
        </select>
        <input
          type="tel"
          id="phoneNumber"
          name="referencePhoneNumber"
          value={formData.referenceContact}
          onChange={handlePhoneChange}
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border flex-1"
          placeholder="Enter Reference Phone Number"
          required
        />
      </div>
          </div>

          {/* Previous Projects */}
          <div className="col-span-6">
            <p className="mb-2">Previous Projects (Optional)</p>
            <input
              type="text"
              name="previousProjects"
              // Handle the case where previousProjects might be undefined
              value={
                Array.isArray(formData.previousProjects)
                  ? formData.previousProjects.join(", ")
                  : ""
              }
              onChange={handleChange}
              placeholder="Enter projects separated by commas"
              className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
            />
          </div>

          {/* Website */}
          <div className="col-span-6">
            <p className="mb-2">Website</p>
            <input
              type="text"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
              placeholder="Enter Your Website URL"
            />
          </div>

          <div className="col-span-12 mt-6 flex justify-center">
            <button
              onClick={uploadFiles}
              disabled={isLoading || imageUploadLoader}
              className={`px-6 py-2 rounded-[12px] bg-[#52bbab] text-white font-medium ${isLoading ? "opacity-50" : ""}`}
            >
              {imageUploadLoader || isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectManager;
